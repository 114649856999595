import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Box, Fab, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {CategorisationFooterTitle} from './CategorisationFooterTitle';
import ProgressBar from './ProgressBar';
import {RoutePaths} from '../../../../../../../../lib/router/route-paths';
import ReactRouterButtonLink from '../../../../../../../../v1/components/app/Router/ReactRouterButtonLink';
import {TransactionsActions} from '../../../../store/transactions.action';
import {TransactionsSelector} from '../../../../store/transactions.selector';
import {TRANSACTION_CATEGORIZATION_SCREENS} from '../../../../util/constants';

CategorisationFooterTitle.propTypes = {t: PropTypes.func};

const CategorisationFooter = ({
    details,
    nonCategorized,
    categorized,
    defaultNumberOfUncategorized,
}) => {
    const {t} = useTranslation('bank');
    const dispatch = useDispatch();

    const totalOverview = useSelector(TransactionsSelector.selectTransactionTotalOverview);
    const categorizationListIds = useSelector(TransactionsSelector.selectCategorizationList);
    const hasSpecialTransaction = useSelector(TransactionsSelector.selectHasSpecialInCategorisationList);

    const transactionIds = useSelector(TransactionsSelector.selectTransactionIds);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isFinished = categorized === defaultNumberOfUncategorized && nonCategorized === 0;
    const isOpenedCategorized = nonCategorized === 0 && categorized === 0;

    let isLast;
    let isOnFirst;
    if (isOpenedCategorized) {
        const currentIndex = transactionIds.findIndex(id => id === details?.id);
        isLast = currentIndex === transactionIds?.length - 1;
        isOnFirst = hasSpecialTransaction ? currentIndex === -1 : currentIndex === 0;
    } else {
        const currentIndex = categorizationListIds.findIndex(id => id === details?.id);
        isLast = currentIndex === categorizationListIds?.length - 1;
        isOnFirst = hasSpecialTransaction ? currentIndex === -1 : currentIndex === 0;
    }

    const openPrevious = () => {
        if (isOpenedCategorized) {
            const currentIndex = transactionIds.findIndex(id => id === details?.id);

            if (transactionIds[currentIndex - 1]) {
                // Reset sidebar
                dispatch(TransactionsActions.setCategorizationSidebar(null));
                // Move to previous
                dispatch(TransactionsActions.setCategorizationScreenInfo({
                    screen: TRANSACTION_CATEGORIZATION_SCREENS.CATEGORY,
                    transactionId: transactionIds[currentIndex - 1],
                }));
            }

            return;
        }

        const currentIndex = categorizationListIds.findIndex(id => id === details?.id);
        if (categorizationListIds[currentIndex - 1]) {
            // Reset sidebar
            dispatch(TransactionsActions.setCategorizationSidebar(null));
            // Move to previous
            dispatch(TransactionsActions.setCategorizationScreenInfo({
                screen: TRANSACTION_CATEGORIZATION_SCREENS.CATEGORY,
                transactionId: categorizationListIds[currentIndex - 1],
            }));
        } else if (hasSpecialTransaction) {
            // Reset sidebar
            dispatch(TransactionsActions.setCategorizationSidebar(null));
            // Move to previous
            dispatch(TransactionsActions.setCategorizationScreenInfo({
                screen: TRANSACTION_CATEGORIZATION_SCREENS.CATEGORY,
                transactionId: hasSpecialTransaction,
            }));
        }
    };

    const openNext = () => {
        if (isOpenedCategorized) {
            const currentIndex = transactionIds.findIndex(id => id === details?.id);
            if (transactionIds[currentIndex + 1]) {
                // Reset sidebar
                dispatch(TransactionsActions.setCategorizationSidebar(null));
                // Move to next
                dispatch(TransactionsActions.setCategorizationScreenInfo({
                    screen: TRANSACTION_CATEGORIZATION_SCREENS.CATEGORY,
                    transactionId: transactionIds[currentIndex + 1],
                }));
            }

            return;
        }
        const currentIndex = categorizationListIds.findIndex(id => id === details?.id);
        if (categorizationListIds[currentIndex + 1]) {
            // Reset sidebar
            dispatch(TransactionsActions.setCategorizationSidebar(null));
            // Move to next
            dispatch(TransactionsActions.setCategorizationScreenInfo({
                screen: TRANSACTION_CATEGORIZATION_SCREENS.CATEGORY,
                transactionId: categorizationListIds[currentIndex + 1],
                isOnFirst: false,
            }));
        }

        // If we are on the last element and there are more uncategorized get more of them
        if (currentIndex === categorizationListIds?.length - 1
            && categorizationListIds?.length !== totalOverview?.totalUncategorizedTransactions) {
            const limit = defaultNumberOfUncategorized < 20 ? 20 : 50;
            const offset = Math.floor(categorizationListIds?.length / limit) * limit;

            dispatch(TransactionsActions.getMoreUncategorizedTransactions({
                limit,
                offset,
            }));
        }
    };

    return (
        <Box sx={{
            width: isMobileSize ? '100vw' : (isFinished && !isOpenedCategorized ? '700px' : '600px'),
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
            px: 2,
            py: 1,
            backgroundColor: '#fff',
            alignItems: 'center',
            borderWidth: '1px',
            borderColor: 'v2.blueGray.50',
            borderStyle: 'solid',
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            mb: '-1px',
        }}
        >
            {!isMobileSize && !isFinished && !isOpenedCategorized && (
                <CategorisationFooterTitle
                    t={t}
                    nonCategorized={nonCategorized}
                />
            )}

            {isFinished && !isOpenedCategorized && (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    px: 2,
                    py: 2,
                }}
                >
                    <Typography>
                        {t('categorisation.footer.allCategorised')}
                    </Typography>

                    <ReactRouterButtonLink
                        sx={{
                            color: 'white',
                        }}
                        color="buttonSuccess"
                        variant="contained"
                        to={RoutePaths.DASHBOARD}
                    >
                        {t('categorisation.footer.calculateRemuneration')}
                    </ReactRouterButtonLink>
                </Box>
            )}

            {isOpenedCategorized && (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    px: 2,
                    py: 2,
                }}
                >
                    <Typography sx={{
                        fontWeight: 500,
                    }}
                    >
                        {t('categorisation.footer.allCategorised')} 🎉
                    </Typography>
                </Box>
            )}

            <Box sx={{
                p: 0.5,
                display: 'flex',
                mt: isMobileSize ? 0 : -1,
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                gap: 2,
            }}
            >
                <Fab
                    sx={{
                        'boxShadow': '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12)',
                        '&:hover': {
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 4px 2px rgba(0, 0, 0, 0.12)',
                        },
                    }}
                    disabled={isOnFirst !== false}
                    size={isMobileSize ? 'medium' : 'small'}
                    onClick={openPrevious}
                >
                    <ChevronLeftIcon />
                </Fab>

                <Box sx={{flex: '1 0 0'}}>
                    <ProgressBar
                        total={defaultNumberOfUncategorized}
                        nonCategorized={defaultNumberOfUncategorized - categorized}
                        isOpenedCategorized={isOpenedCategorized}
                    />
                </Box>

                <Fab
                    sx={{
                        'boxShadow': '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12)',
                        '&:hover': {
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 4px 2px rgba(0, 0, 0, 0.12)',
                        },
                    }}
                    disabled={isLast}
                    size={isMobileSize ? 'medium' : 'small'}
                    onClick={openNext}
                >
                    <ChevronRightIcon />
                </Fab>
            </Box>

            {isMobileSize && (
                <CategorisationFooterTitle
                    t={t}
                    nonCategorized={nonCategorized}
                    isMobileSize
                />
            )}
        </Box>
    );
};

CategorisationFooter.propTypes = {
    details: PropTypes.object.isRequired,
    nonCategorized: PropTypes.number,
    categorized: PropTypes.number,
    defaultNumberOfUncategorized: PropTypes.number.isRequired,
};

CategorisationFooter.defaultProps = {
    nonCategorized: 0,
    categorized: 0,
};

export default CategorisationFooter;
