import {generateUtilityClass, generateUtilityClasses} from '@mui/base';

export const getCollapseUtilityClass = slot => {
    return generateUtilityClass('MuiCollapse', slot);
};

const collapseClasses = generateUtilityClasses('MuiCollapse', [
    'root',
    'horizontal',
    'vertical',
    'entered',
    'hidden',
    'wrapper',
    'wrapperInner',
]);

export default collapseClasses;
