import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import {
    Box,
    Button,
    Chip,
    Drawer,
    IconButton,
    Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {BANK_TYPES, BankIntegrationStatus} from '../../../bridge-api/utils/constants';

const MobileTabs = ({
    isOpen,
    setIsOpen,
    fullBankAccountList,
    onChangeTab,
    hasCombined,
    combinedUncategorized,
    combinedTotal,
    hasHiwayAdd,
    onClickHiwayAdd,
    hasAdd,
    hasArchived,
    onClickAdd,
}) => {
    const {t} = useTranslation('bank');

    return (
        <Drawer
            anchor="bottom"
            open={isOpen}
            onClose={() => {
                setIsOpen(false);
            }}
            sx={{
                '.MuiDrawer-paperAnchorBottom': {
                    borderTopRightRadius: '32px',
                    borderTopLeftRadius: '32px',
                },
            }}
        >
            <Box sx={{
                minHeight: '300px',
                maxHeight: 'calc(100vh - 100px)',
                borderTopRightRadius: '32px',
                borderTopLeftRadius: '32px',
                display: 'flex',
                flexDirection: 'column',
                pt: 1,
                pb: 2,
            }}
            >
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '56px',
                    pl: 3,
                    pr: 2,
                }}
                >
                    <Typography sx={{
                        color: 'v2.blueGray.400',
                        fontWeight: 600,
                        fontSize: '18px',
                        lineHeight: '24px',
                    }}
                    >
                        {t('creationProPage.mobileTabsTitle')}
                    </Typography>
                    <IconButton size="small" sx={{backgroundColor: 'blueGray.main'}}>
                        <CloseIcon onClick={() => {
                            setIsOpen(false);
                        }}
                        />
                    </IconButton>
                </Box>

                {hasCombined && (
                    <MobileTab
                        label={t('creationProPage.combined')}
                        value={BANK_TYPES.combined}
                        onChangeTab={onChangeTab}
                        uncategorized={combinedUncategorized}
                        amount={combinedTotal}
                    />
                )}
                {fullBankAccountList.map(account => {
                    const hasError = account?.status === BankIntegrationStatus.COMPANY_VALIDATION_FORM_REQUIRED
                        || account?.status === BankIntegrationStatus.MULTI_FACTOR_AUTHENTICATION_REQUIRED
                        || account?.status === BankIntegrationStatus.USER_ACTION_REQUIRED
                        || account?.status === BankIntegrationStatus.ERROR
                        || account?.status === BankIntegrationStatus.IN_PROGRESS;

                    return (
                        <MobileTab
                            key={account.id}
                            label={account.name}
                            value={account.id}
                            onChangeTab={onChangeTab}
                            uncategorized={account.uncategorized}
                            amount={account.amount}
                            color={account?.color}
                            hasError={hasError}
                        />
                    );
                })}
                {hasArchived && (
                    <MobileTab
                        label={t('creationProPage.archived')}
                        value={BANK_TYPES.archived}
                        onChangeTab={onChangeTab}
                    />
                )}
                {hasAdd && (
                    <Box
                        sx={{
                            'px': 3,
                            'py': 2,
                            'cursor': 'pointer',
                            '&:not(:last-of-type)': {
                                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                            },
                        }}
                        onClick={_ => {
                            onClickAdd();
                        }}
                    >
                        <Button
                            color="primary"
                            startIcon={<AddIcon />}
                            variant="text"
                            sx={{
                                p: 0,
                                alignSelf: 'flex-start',
                            }}
                        >
                            {t(`creationProPage.addButtonTitle`)}
                        </Button>
                    </Box>
                )}
                {hasHiwayAdd && (
                    <Box
                        sx={{
                            'px': 3,
                            'py': 2,
                            'cursor': 'pointer',
                            '&:not(:last-of-type)': {
                                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                            },
                        }}
                        onClick={_ => {
                            onClickHiwayAdd();
                        }}
                    >
                        <Button
                            color="secondary"
                            startIcon={<AddIcon />}
                            variant="text"
                            sx={{
                                p: 0,
                                alignSelf: 'flex-start',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {t(`creationProPage.addHiwayPro`)}
                            <Chip
                                sx={{
                                    textTransform: 'none',
                                    fontSize: '10px',
                                    lineHeight: '10px',
                                    ml: 1,
                                }}
                                size="small"
                                label={t('connectProPage.recommended')}
                                color="secondary"
                            />
                        </Button>
                    </Box>
                )}
            </Box>
        </Drawer>
    );
};

MobileTabs.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    fullBankAccountList: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChangeTab: PropTypes.func.isRequired,
    hasCombined: PropTypes.bool.isRequired,
    combinedUncategorized: PropTypes.number.isRequired,
    combinedTotal: PropTypes.string.isRequired,
    hasHiwayAdd: PropTypes.bool.isRequired,
    onClickHiwayAdd: PropTypes.func.isRequired,
    hasAdd: PropTypes.bool.isRequired,
    hasArchived: PropTypes.bool.isRequired,
    onClickAdd: PropTypes.func.isRequired,
};

const MobileTab = ({
    label,
    value,
    onChangeTab,
    uncategorized,
    amount,
    color,
    hasError,
}) => (
    <Box
        sx={{
            'position': 'relative',
            'px': 3,
            'py': 2,
            'display': 'flex',
            'justifyContent': 'space-between',
            'alignItems': 'center',
            'cursor': 'pointer',
            '&:not(:last-of-type)': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            },
        }}
        onClick={e => {
            onChangeTab(e, value);
        }}
    >
        <Typography sx={{
            color: 'text_v2.secondary',
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '18px',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
        }}
        >
            {hasError && (
                <WarningIcon sx={{
                    color: 'warning_v2.main',
                }}
                />
            )}
            {label}
            {uncategorized !== 0 && (
                <Chip
                    size="small"
                    label={uncategorized}
                    color="primary"
                />
            )}
        </Typography>
        {typeof amount === 'number' && (
            <Typography sx={{
                color: 'text_v2.secondary',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '18px',
                letterSpacing: '0.17px',
            }}
            >
                {amount} €
            </Typography>
        )}
        {color && (
            <Box sx={{
                position: 'absolute',
                width: '3px',
                top: '10px',
                bottom: '10px',
                left: 0,
                backgroundColor: '#F48FB1',
                borderTopRightRadius: '8px',
                borderBottomRightRadius: '8px',
            }}
            />
        )}
    </Box>
);

MobileTab.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChangeTab: PropTypes.func.isRequired,
    uncategorized: PropTypes.number,
    amount: PropTypes.string,
    color: PropTypes.string,
    hasError: PropTypes.bool,
};

MobileTab.defaultProps = {
    uncategorized: 0,
    amount: null,
    color: null,
    hasError: false,
};

export default MobileTabs;
