import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import {LoadingButton} from '@mui/lab';
import {
    Box, Chip, FormControlLabel,
    Radio, Typography, useMediaQuery,
} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {ModalsKeys} from '../../../ui/utils/constants';
import {getIntegrationInfo} from '../../modules/bridge-api/utils/bridge-util';
import {BankSelector} from '../../store/bank.selector';

const modalKey = ModalsKeys.NEW_DEFAULT_ACCOUNT_MODAL;
const NewDefaultAccountModal = () => {
    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const integrations = useSelector(BankSelector.selectIntegrations);

    const {account: chosenAccount} = getIntegrationInfo(integrations?.[0]);

    return (
        <ModalWrapper
            modalKey={modalKey}
            title={(
                <Typography variant="h4">
                    {t('accountArchiving.selectDefaultTitle')}
                </Typography>
            )}
            buttons={({handleClose}) => (
                <LoadingButton
                    fullWidth={isMobileSize}
                    startIcon={<CheckOutlinedIcon />}
                    loadingPosition="start"
                    onClick={() => {
                        handleClose();
                    }}
                    color="secondary"
                    variant="contained"
                >
                    {t('archivingActions.validate')}
                </LoadingButton>
            )}
        >
            <Typography sx={{mb: 1}}>
                {t('accountArchiving.selectDefaultText1')}
            </Typography>

            <Typography sx={{mb: 2}}>
                {t('accountArchiving.selectDefaultText2')}
            </Typography>

            <Box
                sx={{
                    'border': '1px solid rgba(0, 0, 0, 0.12)',
                    'borderRadius': '16px',
                    'pl': 3,
                    'py': 1,
                    'mt': 1,
                    'pr': 0,
                    'width': '100%',
                    'cursor': 'pointer',
                    '& .MuiFormControlLabel-root': {
                        width: '100%',
                        mr: 0,
                    },
                    '& .MuiFormControlLabel-label': {
                        width: '100%',
                    },
                    '& .MuiRadio-root': {
                        display: 'none',
                    },
                }}
            >
                <FormControlLabel
                    value={1}
                    control={(
                        <Radio sx={{
                            alignItems: 'center',
                        }}
                        />
                    )}
                    label={(
                        <Box>
                            <Box sx={{
                                fontWeight: 600,
                                mb: 0.5,
                            }}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                }}
                                >
                                    {chosenAccount?.name}
                                    <Chip
                                        sx={{
                                            textTransform: 'none',
                                            fontSize: '12px',
                                            lineHeight: '12px',
                                            fontWeight: '400',
                                            height: '20px',
                                        }}
                                        size="small"
                                        label={t('accountArchiving.default')}
                                        color="secondary"
                                    />
                                </Box>
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: 1,
                            }}
                            >
                                <Typography
                                    variant="body2"
                                    sx={{
                                        overflowWrap: 'anywhere',
                                    }}
                                >
                                    {chosenAccount?.iban}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: 'v2.light.text.disabled',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {chosenAccount?.availableBalance} €
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    labelPlacement="end"
                />
            </Box>
        </ModalWrapper>
    );
};

export default NewDefaultAccountModal;
