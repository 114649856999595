import {createSelector} from 'reselect';

const selectDeclarations = state => state.vatDeclaration.declarations;
const createDeclarationsSelector = () => createSelector([selectDeclarations], declarations => {
    if (!declarations?.count) {
        return {data: [], count: 0};
    }
    return {data: declarations.items, count: Number(declarations.count)};
});
const selectStatusFilterCount = state => state.vatDeclaration.declarations?.filterCount || {};
const selectIsLoadingDeclarations = state => state.vatDeclaration.isLoadingDeclarations;

export const VatDeclarationSelectors = {
    selectDeclarations,
    createDeclarationsSelector,
    selectStatusFilterCount,
    selectIsLoadingDeclarations,
};

