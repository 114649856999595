import CloseIcon from '@mui/icons-material/Close';
import {Box, Checkbox, FormControlLabel, ListItem, ListItemButton, Popover, Switch, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {DateRangeWithPredefinedOptions} from '../../../../../backoffice/components/DateRange/DateRangeWithPredefinedOptions';
import {FILTER_LIST_MAP} from '../../util/constants';

export const AccountBalancePopovers = ({
    isOpenDrPicker,
    isOpenSupportingDocuments,
    isOpenType,
    anchorEl,
    handleClose,
    filter,
    setFilter,
}) => {
    const {t} = useTranslation('bank');

    const containerRef = useRef();
    const popoverDatepicker = useRef();
    const popoverType = useRef();
    const popoverDocuments = useRef();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const scrollFunction = useCallback(debounce(() => {
        if (popoverDatepicker?.current) {
            popoverDatepicker.current.updatePosition();
        }
        if (popoverType?.current) {
            popoverType.current.updatePosition();
        }
        if (popoverDocuments?.current) {
            popoverDocuments.current.updatePosition();
        }
        // has to be >300 in header (sticky header transition duration)
    }, 400), [popoverDatepicker, popoverType, popoverDocuments]);

    useEffect(() => {
        window.addEventListener('scroll', scrollFunction);
        return () => {
            window.removeEventListener('scroll', scrollFunction);
        };
        // eslint-disable-next-line
    }, []);

    const onStartDateChange = date => {
        setFilter(filter => {
            return {
                ...filter,
                startDate: date,
            };
        });
    };

    const onEndDateChange = date => {
        setFilter(filter => {
            return {
                ...filter,
                endDate: date,
            };
        });
    };

    const onSwitchChange = value => {
        setFilter(filter => {
            return {
                ...filter,
                isNotCategorized: value,
            };
        });
    };

    const onAddPaymentType = value => {
        const transactionTypes = [...filter.transactionTypes];
        transactionTypes.push(value);
        setFilter(filter => {
            return {
                ...filter,
                transactionTypes: transactionTypes,
            };
        });
    };

    const onRemovePaymentType = value => {
        const transactionTypes = [...filter.transactionTypes];
        const index = transactionTypes.indexOf(value);
        transactionTypes.splice(index, 1);
        setFilter(filter => {
            return {
                ...filter,
                transactionTypes: transactionTypes,
            };
        });
    };

    const onClearAllPaymentType = () => {
        setFilter(filter => {
            return {
                ...filter,
                transactionTypes: [],
            };
        });
    };

    return (
        <Box ref={containerRef}>
            <Popover
                action={popoverDatepicker}
                sx={{
                    '.MuiPopover-paper': {
                        borderRadius: '16px',
                    },
                }}
                id="dr-picker"
                open={isOpenDrPicker}
                anchorEl={anchorEl}
                container={containerRef.current}
                onClose={handleClose}
                disableScrollLock={false}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                }}
            >
                <DateRangeWithPredefinedOptions
                    startDate={filter.startDate}
                    endDate={filter.endDate}
                    onStartDateChange={onStartDateChange}
                    onEndDateChange={onEndDateChange}
                    leftMenuAdditionalStyle={{
                        background: 'rgba(2, 136, 209, 0.04)',
                    }}
                />
            </Popover>

            <Popover
                action={popoverType}
                sx={{
                    '.MuiPopover-paper': {
                        borderRadius: '16px',
                    },
                }}
                id="type-picker"
                open={isOpenType}
                anchorEl={anchorEl}
                container={containerRef.current}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                }}
            >
                <Box sx={{width: '213px'}}>
                    <Box p={1}>
                        <Box display="flex" justifyContent="flex-end" mb={1}>
                            <Button
                                sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 500,
                                    fontSize: theme => theme.typography.pxToRem(13),
                                }}
                                startIcon={<CloseIcon />}
                                size="small"
                                disabled={false}
                                onClick={() => {
                                    onClearAllPaymentType();
                                }}
                            >
                                {t('accountBalancePopover.reinitialize')}
                            </Button>
                        </Box>
                    </Box>

                    <List sx={{width: '100%', backgroundColor: 'background.paper', pt: 0, maxHeight: '60vh', overflow: 'auto'}}>
                        {FILTER_LIST_MAP.map(status => (
                            <ListItem key={status} disablePadding>
                                <ListItemButton
                                    sx={{
                                        fontSize: theme => theme.typography.pxToRem(14),
                                    }}
                                    onClick={() => {
                                        if (filter.transactionTypes.indexOf(status) !== -1) {
                                            onRemovePaymentType(status);
                                        } else {
                                            onAddPaymentType(status);
                                        }
                                    }}
                                    dense
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={filter.transactionTypes.indexOf(status) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                        />
                                    </ListItemIcon>
                                    {t(`paymentType.${status}`)}
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Popover>

            <Popover
                action={popoverDocuments}
                sx={{
                    '.MuiPopover-paper': {
                        borderRadius: '16px',
                    },
                }}
                id="supportingDocuments-picker"
                open={isOpenSupportingDocuments}
                anchorEl={anchorEl}
                container={containerRef.current}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                }}
            >
                <Box sx={{
                    width: '367px',
                    borderRadius: '16px',
                    py: 1,
                    px: 2,
                }}
                >
                    <FormControlLabel
                        control={(
                            <Switch
                                checked={filter.isNotCategorized}
                                onChange={(e, value) => {
                                    onSwitchChange(value);
                                }
                        }
                            />
                      )}
                        label={t('accountBalanceFilter.supportingDocumentsNotUploaded')}
                    />
                    <Typography sx={{
                        ml: 6,
                        color: 'rgba(0, 0, 0, 0.38)',
                        fontSize: theme => theme.typography.pxToRem(12),
                    }}
                    >
                        {t('accountBalancePopover.showOnlySupportingDocuments')}
                    </Typography>
                </Box>
            </Popover>
        </Box>
    );
};

AccountBalancePopovers.propTypes = {
    isOpenDrPicker: PropTypes.bool.isRequired,
    isOpenSupportingDocuments: PropTypes.bool.isRequired,
    isOpenType: PropTypes.bool.isRequired,
    anchorEl: PropTypes.object,
    handleClose: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
};

AccountBalancePopovers.defaultProps = {
    anchorEl: {},
};
