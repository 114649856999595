import debounce from 'lodash.debounce';
import {useCallback, useEffect, useRef, useState} from 'react';
import {CONFIG} from '../../config';

const loadScript = (src, position, id) => {
    if (!position) {
        return;
    }

    const script = document.createElement('script');

    script.setAttribute('async', '');
    script.setAttribute('id', id);

    script.src = src;

    position.appendChild(script);
};

const autocompleteService = {current: null};
const placesService = {current: null};

const useAddressPrediction = (term, componentRestrictions = {country: 'fr'}) => {
    const [predictions, setPredictions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const loaded = useRef(false);
    const sessionToken = useRef();

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${CONFIG.GOOGLE_MAPS_API_KEY}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    // const autocompleteService = useMemo(() => {
    //     return new window.google.maps.places.AutocompleteService();
    // }, []);

    const getPredictions = useCallback(term => {
        if (!window.google) {
            return;
        }

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }

        if (!autocompleteService.current) {
            return undefined;
        }

        sessionToken.current = new window.google.maps.places.AutocompleteSessionToken();

        autocompleteService.current.getPlacePredictions({
            input: term,
            types: ['address'],
            componentRestrictions: componentRestrictions,
            sessionToken: sessionToken.current,
        }, predictions => {
            setIsLoading(false);
            let newPredictions = [];

            if (predictions) {
                newPredictions = predictions;
            }

            setPredictions(newPredictions);
        });
        // eslint-disable-next-line
    }, [autocompleteService]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedGetPredictions = useCallback(debounce(getPredictions, 300), []);

    useEffect(() => {
        setPredictions([]);

        if (!term) {
            debouncedGetPredictions.cancel();

            setIsLoading(false);

            return;
        }

        setIsLoading(true);

        debouncedGetPredictions(term);
    }, [debouncedGetPredictions, term]);


    const getPlaceDetails = useCallback(((placeId, onPlaceDetailsChange) => {
        if (!placesService.current) {
            if (!placesService.current) {
                placesService.current = new window.google.maps.places.PlacesService(document.createElement('span'));
            }
        }

        return placesService.current.getDetails({
            placeId,
            fields: ['address_components', 'formatted_address'],
        }, place => {
            if (!place) {
                return {};
            }

            const {address_components: addressComponents, formatted_address: formattedAddress} = place;

            if (!addressComponents) {
                return {};
            }

            let city = '';
            let zipCode = '';
            let streetNumber = '';
            let street = '';
            let country = '';
            let region = '';

            addressComponents.forEach(component => {
                if (component.types.includes('locality')) {
                    city = component.long_name;
                } else if (component.types.includes('route')) {
                    street = component.long_name;
                } else if (component.types.includes('postal_code')) {
                    zipCode = component.long_name;
                } else if (component.types.includes('street_number')) {
                    streetNumber = component.long_name;
                } else if (component.types.includes('country')) {
                    country = component.long_name;
                } else if (component.types.includes('administrative_area_level_1')) {
                    region = component.long_name;
                }
            });

            onPlaceDetailsChange({
                city,
                zipCode,
                streetNumber,
                street,
                country,
                region,
                formattedAddress,
            });
        });
    }), []);

    return {predictions, isLoading, getPlaceDetails};
};

export default useAddressPrediction;
