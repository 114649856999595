import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {LoadingButton} from '@mui/lab';
import {Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography, useMediaQuery} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ModalWrapper} from '../../../../../components/modal-wrapper/ModalWrapper';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import {ModalsKeys} from '../../../../ui/utils/constants';
import {BankSelector} from '../../../store/bank.selector';
import {BridgeActions} from '../store/bridge-api.action';

const modalKey = ModalsKeys.PICK_INTEGRATION_ACCOUNT_MODAL;
const PickIntegrationAccountModal = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.ONBOARDING),
    );

    const integrations = useSelector(BankSelector.selectIntegrations);
    const integration = integrations.find(integration => integration?.integrationAccounts?.length > 0
        && integration?.bankAccountHolders?.length === 0);

    const hasDefaultValue = integration?.integrationAccounts.find(integration => integration.isSuggested);

    const [value, setValue] = React.useState(hasDefaultValue?.iban);
    const handleChange = event => {
        setValue(event.target.value);
    };

    return (
        <ModalWrapper
            modalKey={modalKey}
            title={(
                <Typography variant="h4">
                    {t('bridgeAPI.pickIntegrationAccountModal.title')}
                </Typography>
            )}
            buttons={({handleClose}) => (
                <>
                    <Button
                        disableElevation
                        fullWidth
                        startIcon={<CloseIcon />}
                        onClick={() => {
                            handleClose();
                        }}
                        variant="outlined"
                        color="primary"
                    >
                        {t('bridgeAPI.pickIntegrationAccountModal.cancel')}
                    </Button>
                    <LoadingButton
                        disabled={!value}
                        fullWidth
                        loading={isLoading}
                        color="error"
                        variant="contained"
                        startIcon={<CheckIcon />}
                        loadingPosition="start"
                        onClick={() => {
                            dispatch(BridgeActions.onboardAccount(
                                {
                                    integrationId: integration.id,
                                    iban: value,
                                },
                            ));
                        }}
                    >
                        {t('bridgeAPI.pickIntegrationAccountModal.confirm')}
                    </LoadingButton>
                </>
            )}
            hasShowClose={false}
        >
            <FormControl sx={{width: '100%'}}>
                <RadioGroup
                    name="account-integration"
                    value={value}
                    onChange={handleChange}
                >
                    {integration?.integrationAccounts.map(account => {
                        return (
                            <FormControlLabel
                                key={account.iban}
                                sx={{
                                    'border': '1px solid rgba(0, 0, 0, 0.12)',
                                    'borderRadius': '16px',
                                    'my': 1,
                                    'mx': 0,
                                    'p': 2,
                                    '& .MuiFormControlLabel-label': {
                                        flex: '1 0 0',
                                    },
                                }}
                                value={account.iban}
                                control={(
                                    <Radio sx={{
                                        mt: -1,
                                        ml: -1,
                                        p: 1,
                                        alignSelf: 'flex-start',
                                    }}
                                    />
                                )}
                                label={(
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                    >
                                        <Typography sx={{
                                            fontWeight: '500',
                                        }}
                                        >
                                            {account.name}
                                        </Typography>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: isMobileSize ? 'flex-start' : 'center',
                                            fontSize: theme => theme.typography.pxToRem(14),
                                            flexDirection: isMobileSize ? 'column' : 'row',
                                        }}
                                        >
                                            {account.iban}
                                            <Typography sx={{
                                                fontSize: theme => theme.typography.pxToRem(14),
                                                color: 'v2.light.text.disabled',
                                            }}
                                            >
                                                {account.balance} €
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                            />
                        );
                    })}
                </RadioGroup>
            </FormControl>
        </ModalWrapper>
    );
};

export default PickIntegrationAccountModal;
