import CancelIcon from '@mui/icons-material/Cancel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {LoadingButton} from '@mui/lab';
import {Alert, Box, Button, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {UiActions} from '../../../ui/store/ui.action';
import {ModalsKeys} from '../../../ui/utils/constants';
import {TransactionsSelector} from '../../modules/account-balance/store/transactions.selector';
import {getIntegrationInfo} from '../../modules/bridge-api/utils/bridge-util';

const ArchivingSidebarActions = ({isHiway, integration}) => {
    const {bankAccountHolder, account} = getIntegrationInfo(integration);

    const hasTransactions = useSelector(TransactionsSelector.selectHasTransactions);

    const isCloseAndArchiveDisabled = parseFloat(account?.availableBalance) !== 0
        || integration?.uncategorized !== 0;

    if (isHiway) {
        return (
            <Box>
                <Close
                    isDisabled={isCloseAndArchiveDisabled}
                    integration={integration}
                />
            </Box>
        );
    }

    // Bridge archive id disabled if there are uncategorized transactions,
    // or if account or bankAccountHolder are missing
    // or if it does not have transactions
    const isCloseAndArchiveBridgeDisabled = integration?.uncategorized !== 0
        || !bankAccountHolder?.id
        || !account?.id
        || !hasTransactions;

    return (
        <Box>
            <Archive
                isDisabled={isCloseAndArchiveBridgeDisabled}
                integration={integration}
            />
            <Delete
                integration={integration}
            />
        </Box>
    );
};

ArchivingSidebarActions.propTypes = {
    isHiway: PropTypes.bool.isRequired,
    integration: PropTypes.object.isRequired,
};

const Archive = ({
    isDisabled,
    integration,
}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('bank');

    const handleArchive = () => {
        dispatch(UiActions.setModalData(ModalsKeys.ARCHIVE_INTEGRATION_MODAL, {integration}));
        dispatch(UiActions.setActiveModal(ModalsKeys.ARCHIVE_INTEGRATION_MODAL, true));
    };

    return (
        <Box
            sx={{
                px: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
            }}
        >
            <Typography
                variant="body2"
                sx={{
                    textAlign: 'center',
                    fontSize: '14px',
                    lineHeight: '20px',
                    letterSpacing: '0.17px',
                }}
            >
                {t('accountSettingsSidebar.closeText')}
            </Typography>
            <LoadingButton
                disabled={isDisabled}
                fullWidth
                startIcon={<CancelIcon />}
                onClick={handleArchive}
                color="secondary"
                variant="outlined"
                sx={{
                    fontWeight: 500,
                    fontSoze: '15px',
                    lineHeight: '26px',
                    letterSpacing: '0.46px',
                }}
            >
                {t('archivingActions.archive')}
            </LoadingButton>
        </Box>
    );
};

Archive.propTypes = {
    isDisabled: PropTypes.bool.isRequired,
    integration: PropTypes.object.isRequired,
};

const Delete = ({integration}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('bank');

    const handleDelete = () => {
        dispatch(UiActions.setModalData(ModalsKeys.DELETE_INTEGRATION_MODAL, {integration}));
        dispatch(UiActions.setActiveModal(ModalsKeys.DELETE_INTEGRATION_MODAL, true));
    };

    return (
        <Box
            sx={{
                p: 2,
                pb: 3,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
            }}
        >
            <Alert
                color="info"
                severity="info"
                sx={{
                    'marginTop': '8px',
                    'borderRadius': '12px',
                    '.MuiAlert-icon': {
                        display: 'flex',
                        alignItems: 'center',
                    },
                    '.MuiAlert-action': {
                        display: 'flex',
                        alignItems: 'center',
                    },
                }}
                icon={(<HelpOutlineIcon sx={{fontSize: 'inherit', color: 'v2.light.info.main'}} />)}
                action={(
                    <Button
                        color="inherit"
                        size="small"
                        sx={{height: '30px', px: 2}}
                        onClick={handleDelete}
                    >
                        {t('archivingActions.deleteConfirm')}
                    </Button>
                )}
            >
                {t('archivingActions.deleteQuestion')}
            </Alert>
        </Box>
    );
};

Delete.propTypes = {
    integration: PropTypes.object.isRequired,
};

const Close = ({
    isDisabled,
    integration,
}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('bank');

    const handleClose = () => {
        if (isDisabled) {
            dispatch(UiActions.setActiveModal(ModalsKeys.CLOSE_HIWAY_INTEGRATION_UNAVAILABLE_MODAL, true));
        } else {
            dispatch(UiActions.setModalData(ModalsKeys.CLOSE_HIWAY_INTEGRATION_MODAL, {integration}));
            dispatch(UiActions.setActiveModal(ModalsKeys.CLOSE_HIWAY_INTEGRATION_MODAL, true));
        }
    };

    return (
        <Box
            sx={{
                p: 2,
                pb: 3,
            }}
        >
            <LoadingButton
                loadingPosition="start"
                fullWidth
                startIcon={<CancelIcon />}
                onClick={handleClose}
                color="secondary"
                variant="outlined"
                sx={{
                    fontWeight: 500,
                    fontSoze: '15px',
                    lineHeight: '26px',
                    letterSpacing: '0.46px',
                }}
            >
                {t('archivingActions.close')}
            </LoadingButton>
        </Box>
    );
};

Close.propTypes = {
    isDisabled: PropTypes.bool.isRequired,
    integration: PropTypes.object.isRequired,
};

export default ArchivingSidebarActions;
