import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {Box} from '@mui/material';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';

export const AccountBalanceMobileSearch = ({filter, setFilter}) => {
    const onSearchTextChange = text => {
        setFilter(filter => {
            return {
                ...filter,
                search: text,
            };
        });
    };

    return (
        <Box sx={{px: 2}}>
            <TextField
                value={filter.search}
                fullWidth
                onChange={event => {
                    onSearchTextChange(event.target.value);
                }}
                InputProps={{
                    startAdornment: <SearchIcon />,
                    endAdornment: (
                        <CloseIcon
                            onClick={() => onSearchTextChange('')}
                        />),
                }}
                sx={{mb: 2}}
            />
        </Box>
    );
};

AccountBalanceMobileSearch.propTypes = {
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
};
