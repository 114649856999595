import {faHourglassHalf} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, ToggleButtonGroup} from '@mui/joy';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Outlet} from 'react-router-dom';
import JoyUIThemeProvider from '../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {FORMALITIES_TABS} from '../../features/company-profile/modules/formalities/utils/constants';
import {importLocaleBundle} from '../../lib/i18next';
import {replace, selectRouterLocation} from '../../lib/router/connected-router-saga';
import {RoutePaths} from '../../lib/router/route-paths';
import PageHeader from '../../v1/components/app/PageHeader/PageHeader';

// TODO: HPD-5014 - uncomment after BE be ready
// importLocaleBundle('vatDeclaration');
importLocaleBundle('financialStatements');

const tabStyles = {
    'color': 'neutral.plainColor',
    '&[aria-pressed="true"]': {
        backgroundColor: 'primary.solidBg',
        color: 'background.body',
    },
    '--variant-outlinedHoverBg': 'var(--joy-palette-background-surface)',
};

export const FormalitiesScreen = () => {
    const {t: tSidebar} = useTranslation('sidebar');
    // const {t: tVatDeclaration} = useTranslation('vatDeclaration');
    const {t: tFinancialStatements} = useTranslation('financialStatements');

    const location = useSelector(selectRouterLocation);
    const [activeTab, setActiveTab] = useState(location.pathname);
    const dispatch = useDispatch();

    const handleChange = (_, newValue) => {
        setActiveTab(newValue);
        if (activeTab !== newValue) {
            dispatch(replace(newValue));
        }
    };

    useEffect(() => {
        setActiveTab(location.pathname);
    }, [location.pathname]);

    return (
        <>
            <PageHeader title={tSidebar('administratorSidebar.formalities')} />
            <JoyUIThemeProvider>
                <ToggleButtonGroup value={activeTab} onChange={handleChange} variant="outlined" color="primary" buttonFlex={1}>
                    <Button sx={tabStyles} value={RoutePaths.VAT_DECLARATION}>
                        {tFinancialStatements(`companies:formalities.tabs.${FORMALITIES_TABS.VAT_DECLARATION}`)}
                    </Button>
                    <Button sx={tabStyles} value={RoutePaths.FINANCIAL_STATEMENTS}>
                        {tFinancialStatements('title')}
                    </Button>
                    <Button sx={tabStyles} disabled startDecorator={<FontAwesomeIcon icon={faHourglassHalf} />}>
                        {tFinancialStatements(`companies:formalities.tabs.${FORMALITIES_TABS.OFFICE_CHANGE}`)}
                    </Button>
                </ToggleButtonGroup>
            </JoyUIThemeProvider>
            <Outlet />
        </>
    );
};
