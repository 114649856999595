import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit';
import {LoadingButton} from '@mui/lab';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {resolveRoute} from '../../../../../v1/navigation/resolveRoute';
import {DOCUMENT_SIGNING_TYPES} from '../utils/constants';

const DocumentSigningToolbar = ({
    MainActionComponent,
    onNextClick,
    type,
    areAllDocumentsSigned,
    allDocumentsSignedCallbacks,
    isSigning,
    isLoadingNextStep,
    documentId,
    isSigned,
    isSigningAdditionalDocuments,
}) => {
    const {t} = useTranslation('document');

    if (MainActionComponent) {
        return React.createElement(MainActionComponent);
    }

    // All documents signed
    if (areAllDocumentsSigned && isSigningAdditionalDocuments) {
        // Accounting documents (depot des comptes)
        if (type === DOCUMENT_SIGNING_TYPES.ACCOUNTING) {
            return (
                <LoadingButton
                    sx={{py: 0.75, borderRadius: 2, mt: 1, color: '#fff'}}
                    variant="contained"
                    color="info"
                    size="small"
                    loading={isSigning || isLoadingNextStep || !documentId}
                    loadingPosition="start"
                    onClick={allDocumentsSignedCallbacks[type]}
                    fullWidth
                >
                    {t('companies:formalities.depositOfAccounts.payButton')}
                </LoadingButton>
            );
        }

        // Default signing flow
        return (
            <Link
                to={resolveRoute(RoutePaths.DASHBOARD)}
                style={{textDecoration: 'none', color: 'inherit', alignSelf: 'end'}}
            >
                <LoadingButton
                    sx={{py: 0.75, borderRadius: 2, mt: 1, color: '#fff'}}
                    variant="contained"
                    color="info"
                    size="small"
                    loading={isSigning || isLoadingNextStep || !documentId}
                    loadingPosition="start"
                    fullWidth
                >
                    {t('returnToDashboard')}
                </LoadingButton>
            </Link>
        );
    }

    // Signing
    return (
        <LoadingButton
            sx={{py: 0.75, borderRadius: 2, mt: 1, color: '#fff'}}
            startIcon={isSigned ? null : <EditIcon />}
            endIcon={isSigned ? <ArrowForwardIcon /> : null}
            variant="contained"
            color={isSigned ? 'buttonSuccess' : 'secondary'}
            size="small"
            loading={isSigning || isLoadingNextStep || !documentId}
            loadingPosition="start"
            onClick={onNextClick}
            fullWidth
        >
            {isSigned ? t('signing.next') : t('signing.signButton')}
        </LoadingButton>

    );
};

DocumentSigningToolbar.defaultProps = {
    MainActionComponent: null,
};

DocumentSigningToolbar.propTypes = {
    MainActionComponent: PropTypes.any,
    type: PropTypes.oneOf(Object.values(DOCUMENT_SIGNING_TYPES)).isRequired,
    onNextClick: PropTypes.func.isRequired,
    areAllDocumentsSigned: PropTypes.bool.isRequired,
    allDocumentsSignedCallbacks: PropTypes.objectOf(PropTypes.func).isRequired,
    isSigning: PropTypes.bool.isRequired,
    isLoadingNextStep: PropTypes.bool.isRequired,
    documentId: PropTypes.string.isRequired,
    isSigned: PropTypes.bool.isRequired,
    isSigningAdditionalDocuments: PropTypes.bool.isRequired,
};

export default DocumentSigningToolbar;
