import {
    faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Box, Container, IconButton, Modal, Sheet, Stack} from '@mui/joy';
import {
    Box as MuiBox,
    Container as MuiContainer,
    Modal as MuiModal,
    Paper,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {UiActions} from '../../features/ui/store/ui.action';
import {UiSelectors} from '../../features/ui/store/ui.selector';
import {useMemoizedCreateSelector} from '../../hooks/use-memoized-create-selector';
import {ButtonIcon} from '../buttons/ButtonIcon';
import CloseButton from '../buttons/CloseButton';
import useMediaQuery from '../joy-ui/mui-legacy/useMediaQuery';

export const ModalWrapper = ({
    modalKey,
    handleBack,
    handleOpenCB,
    handleCloseCB,
    handleCloseFnc,
    hasShowClose,
    containerSx,
    bodySx,
    titleSx,
    buttonsSx,
    title,
    buttons,
    isForceOpen,
    borderRadius,
    isV3,
    children,
}) => {
    const dispatch = useDispatch();
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isOpen = useMemoizedCreateSelector(
        UiSelectors.createIsModalActiveSelector, modalKey,
    ) || isForceOpen;

    const handleClose = () => {
        if (handleCloseFnc) {
            const closeFnc = () => dispatch(UiActions.setActiveModal(modalKey, false));
            handleCloseFnc(closeFnc);
        } else {
            dispatch(UiActions.setActiveModal(modalKey, false));
        }

        if (handleCloseCB) {
            handleCloseCB();
        }
    };

    const init = useCallback(() => {
        if (handleOpenCB && isOpen) {
            handleOpenCB();
        }
    }, [handleOpenCB, isOpen]);

    useEffect(init, [isOpen]); // eslint-disable-line

    if (!isOpen) {
        return null;
    }

    const isChildrenFunction = typeof children === 'function';

    if (isV3) {
        return (
            <Modal
                aria-labelledby="close-modal-title"
                open={isOpen}
                onClose={hasShowClose ? handleClose : null}
                sx={!isMobileSize ? {overflowY: 'scroll'} : {}}
            >
                <Stack
                    minHeight="100vh"
                    direction="row"
                    alignItems="center"
                >
                    {!isMobileSize ? (
                        <Container sx={containerSx}>
                            <Sheet
                                sx={{
                                    boxShadow: 'lg',
                                    borderRadius: 'md',
                                }}
                            >
                                <Box sx={{position: 'relative'}}>
                                    {hasShowClose && (
                                        <CloseButton
                                            onClose={handleClose}
                                            buttonStyles={{
                                                position: 'absolute',
                                                top: '8px',
                                                right: '8px',
                                                zIndex: 1,
                                            }}
                                            isV3
                                        />
                                    )}
                                    {handleBack && (
                                        <Box sx={{
                                            position: 'absolute',
                                            left: '8px',
                                            top: '8px',
                                        }}
                                        >
                                            <IconButton
                                                onClick={handleBack}
                                                color="neutral"
                                                variant="plain"
                                                size="sm"
                                            >
                                                <FontAwesomeIcon size="lg" icon={faArrowLeft} />
                                            </IconButton>
                                        </Box>
                                    )}
                                    <Box sx={{
                                        p: 5,
                                        my: 3,
                                        ...bodySx,
                                    }}
                                    >
                                        {title && (
                                            <Box sx={{mb: 4, pr: 3, ...titleSx}}>
                                                {title}
                                            </Box>
                                        )}
                                        {isChildrenFunction ? children({handleClose}) : children}
                                        {buttons && (
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="flex-end"
                                                spacing={2}
                                                sx={{
                                                    pt: 4,
                                                    ...buttonsSx,
                                                }}
                                            >
                                                {buttons({handleClose})}
                                            </Stack>
                                        )}
                                    </Box>
                                </Box>
                            </Sheet>
                        </Container>
                    ) : (
                        <Box
                            sx={{
                                ...containerSx,
                                width: '100vw',
                                height: '100vh',
                                backgroundColor: '#fff',
                                position: 'relative',
                            }}
                        >
                            {hasShowClose && (
                                <CloseButton
                                    onClose={handleClose}
                                    buttonStyles={{
                                        position: 'absolute',
                                        top: '8px',
                                        right: '8px',
                                    }}
                                    isV3
                                />
                            )}
                            {handleBack && (
                                <IconButton
                                    onClick={handleBack}
                                    color="neutral"
                                    variant="plain"
                                    size="sm"
                                    sx={{
                                        position: 'absolute',
                                        left: '8px',
                                    }}
                                >
                                    <FontAwesomeIcon size="lg" icon={faArrowLeft} />
                                </IconButton>
                            )}
                            <Stack
                                height="100vh"
                                direction="column"
                                sx={{
                                    overflowY: 'auto',
                                    p: 3,
                                    pt: 2,
                                }}
                            >
                                {title && (
                                    <Box sx={{mb: 2, pr: 3, ...titleSx}}>
                                        {title}
                                    </Box>
                                )}
                                {isChildrenFunction ? children({handleClose}) : children}
                                {buttons && (
                                    <Stack
                                        direction="column"
                                        alignItems="center"
                                        spacing={2}
                                        sx={{
                                            mt: 'auto',
                                            pt: 4,
                                            ...buttonsSx,
                                        }}
                                    >
                                        {buttons({handleClose})}
                                    </Stack>
                                )}
                            </Stack>
                        </Box>
                    )}
                </Stack>
            </Modal>
        );
    }

    return (
        <MuiModal
            open={isOpen}
            onClose={hasShowClose ? handleClose : null}
            sx={!isMobileSize ? {overflowY: 'scroll'} : {}}
            BackdropProps={{
                sx: {backgroundColor: 'rgba(241, 244, 246, 0.1)'},
            }}
        >
            <MuiBox sx={{
                display: 'flex',
                minHeight: '100vh',
                alignItems: 'center',
            }}
            >
                {!isMobileSize ? (
                    <MuiContainer sx={containerSx}>
                        <Paper
                            radius={borderRadius}
                            elevation={24}
                        >
                            <MuiBox sx={{position: 'relative'}}>
                                {hasShowClose && (
                                    <CloseButton
                                        onClose={handleClose}
                                        buttonStyles={{
                                            position: 'absolute',
                                            top: '8px',
                                            right: '8px',
                                            zIndex: 1,
                                        }}
                                    />
                                )}
                                {handleBack && (
                                    <MuiBox sx={{
                                        position: 'absolute',
                                        left: '8px',
                                        top: '8px',
                                    }}
                                    >
                                        <ButtonIcon
                                            icon={<ArrowBackIcon fontSize="large" />}
                                            onClick={handleBack}
                                            sx={{color: 'text_v2.primary'}}
                                        />
                                    </MuiBox>
                                )}
                                <MuiBox sx={{
                                    p: 5,
                                    my: 3,
                                    ...bodySx,
                                }}
                                >
                                    {title && (
                                        <MuiBox sx={{mb: 2, pr: 3, ...titleSx}}>
                                            {title}
                                        </MuiBox>
                                    )}
                                    {isChildrenFunction ? children({handleClose}) : children}
                                    {buttons && (
                                        <MuiBox sx={{
                                            pt: 5,
                                            gap: 3,
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                            ...buttonsSx,
                                        }}
                                        >
                                            {buttons({handleClose})}
                                        </MuiBox>
                                    )}
                                </MuiBox>
                            </MuiBox>
                        </Paper>
                    </MuiContainer>
                ) : (
                    <MuiBox
                        sx={{
                            ...containerSx,
                            width: '100vw',
                            height: '100vh',
                            backgroundColor: '#fff',
                            position: 'relative',
                        }}
                    >
                        {hasShowClose && (
                            <CloseButton
                                onClose={handleClose}
                                buttonStyles={{
                                    position: 'absolute',
                                    top: '8px',
                                    right: '8px',
                                }}
                            />
                        )}
                        {handleBack && (
                            <ButtonIcon
                                icon={<ArrowBackIcon fontSize="large" />}
                                onClick={handleBack}
                                sx={{
                                    color: 'text_v2.primary',
                                    position: 'absolute',
                                    left: '8px',
                                }}
                            />
                        )}
                        <MuiBox sx={{
                            height: '100vh',
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            p: 3,
                            pt: 2,
                        }}
                        >
                            {title && (
                                <MuiBox sx={{mb: 2, pr: 3, ...titleSx}}>
                                    {title}
                                </MuiBox>
                            )}
                            {isChildrenFunction ? children({handleClose}) : children}
                            {buttons && (
                                <MuiBox sx={{
                                    mt: 'auto',
                                    pt: 5,
                                    gap: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    ...buttonsSx,
                                }}
                                >
                                    {buttons({handleClose})}
                                </MuiBox>
                            )}
                        </MuiBox>
                    </MuiBox>
                )}
            </MuiBox>
        </MuiModal>
    );
};

ModalWrapper.propTypes = {
    modalKey: PropTypes.string.isRequired,
    handleOpenCB: PropTypes.func,
    handleCloseCB: PropTypes.func,
    handleCloseFnc: PropTypes.func,
    handleBack: PropTypes.func,
    containerSx: PropTypes.object,
    bodySx: PropTypes.object,
    titleSx: PropTypes.object,
    buttonsSx: PropTypes.object,
    hasShowClose: PropTypes.bool,
    title: PropTypes.node,
    buttons: PropTypes.func,
    isForceOpen: PropTypes.bool,
    borderRadius: PropTypes.number, // TODO: remove after deleting MUI version
    isV3: PropTypes.bool, // TODO: make true by default and delete the code, related to false value
};

ModalWrapper.defaultProps = {
    handleOpenCB: null,
    handleCloseCB: null,
    handleCloseFnc: null,
    containerSx: {width: '552px'}, // TODO: set 600px after deleting MUI version
    bodySx: {},
    titleSx: {},
    buttonsSx: {},
    hasShowClose: true,
    handleBack: null,
    title: null,
    buttons: null,
    isForceOpen: false,
    borderRadius: 16,
    isV3: false,
};
