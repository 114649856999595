import {Checkbox as JoyCheckbox} from '@mui/joy';
import JoyUIThemeProvider from '../joyui-theme-provider/JoyUiThemeProvider';

export const Checkbox = props => (
    <JoyUIThemeProvider>
        <JoyCheckbox
            variant="soft"
            color="neutral"
            sx={{
                'p': 1.25,
                '.MuiCheckbox-checkbox': {
                    bgcolor: 'neutral.softActiveBg',
                },
            }}
            {...props}
        />
    </JoyUIThemeProvider>
);
