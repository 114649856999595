import CloseIcon from '@mui/icons-material/Close';
import {
    Box, Checkbox,
    FormControlLabel,
    IconButton,
    ListItem,
    ListItemButton,
    Modal,
    Paper,
    Switch,
    Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DateRangePicker} from '../../../../../backoffice/components/DateRange/DateRange';
import {FILTER_LIST_MAP} from '../../util/constants';

export const AccountBalanceMobileFilter = ({
    isOpen,
    setIsOpen,
    filter,
    setFilter,
}) => {
    const [isDROpen, setIsDROpen] = useState(false);
    const {t} = useTranslation('bank');

    const [startDate, setStartDate] = useState(filter.startDate);
    const [endDate, setEndDate] = useState(filter.endDate);

    const [types, setTypes] = useState(filter.transactionTypes);
    const [hasStatusFilterActive, setHasStatusFilterActive] = useState(filter.isNotCategorized ?? false);

    const onAddPaymentType = value => {
        const transactionTypes = [...types];
        transactionTypes.push(value);
        setTypes(transactionTypes);
    };

    const onRemovePaymentType = value => {
        const transactionTypes = [...types];
        const index = transactionTypes.indexOf(value);
        transactionTypes.splice(index, 1);
        setTypes(transactionTypes);
    };

    const onFilterApply = () => {
        setFilter(filter => {
            return {
                ...filter,
                startDate: startDate,
                endDate: endDate,
                isNotCategorized: hasStatusFilterActive,
                transactionTypes: types,
            };
        });
        setIsOpen(false);
    };

    const onFilterReset = () => {
        setStartDate('');
        setEndDate('');
        setTypes([]);
        setHasStatusFilterActive(false);
    };

    const filterResetToPrevious = () => {
        setStartDate(filter.startDate);
        setEndDate(filter.endDate);
        setTypes(filter.transactionTypes);
        setHasStatusFilterActive(filter.isNotCategorized);
    };

    useEffect(() => {
        if (filter.isNotCategorized) {
            setStartDate('');
            setEndDate('');
            setTypes([]);
            setHasStatusFilterActive(true);
        }
    }, [filter?.isNotCategorized]);

    if (isDROpen) {
        return (
            <Modal
                open={true}
                onClose={() => {
                    onFilterReset();
                    setIsDROpen(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
                >
                    <Paper sx={{
                        width: '320px',
                        height: '539px',
                        borderRadius: '16px',
                        pt: 2,
                        pb: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                    >
                        <Box>
                            <Typography variant="h5" sx={{textAlign: 'center'}}>
                                {t('accountBalanceFilter.definedDates')}
                            </Typography>

                            <Box>
                                <DateRangePicker
                                    startDate={startDate}
                                    endDate={endDate}
                                    onStartDateChange={setStartDate}
                                    onEndDateChange={setEndDate}
                                    isSingleDate={true}
                                />
                            </Box>

                        </Box>
                        <Box sx={{mt: 4, mb: 3, px: 3}}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                <Button
                                    sx={{mr: 0.5}}
                                    variant="contained"
                                    fullWidth
                                    onClick={() => {
                                        setStartDate(null);
                                        setEndDate(null);
                                    }}
                                >
                                    {t('accountBalanceFilter.cancel')}
                                </Button>
                                <Button
                                    sx={{ml: 0.5}}
                                    variant="contained"
                                    fullWidth
                                    color="secondary"
                                    onClick={() => {
                                        setIsDROpen(false);
                                    }}
                                >
                                    {t('accountBalanceFilter.apply')}
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
            </Modal>
        );
    }

    return (
        <Drawer
            anchor="bottom"
            open={isOpen}
            onClose={() => {
                filterResetToPrevious();
                setIsOpen(false);
            }}
            sx={{
                '.MuiDrawer-paperAnchorBottom': {
                    borderTopRightRadius: '32px',
                    borderTopLeftRadius: '32px',
                },
            }}
        >
            <Box sx={{
                minHeight: '300px',
                borderTopRightRadius: '32px',
                borderTopLeftRadius: '32px',
                display: 'flex',
                flexDirection: 'column',
                px: 3,
            }}
            >
                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <IconButton size="small" sx={{mt: 3, backgroundColor: 'blueGray.main'}}>
                        <CloseIcon onClick={
                            () => {
                                filterResetToPrevious();
                                setIsOpen(false);
                            }}
                        />
                    </IconButton>
                </Box>

                <Box sx={{mt: 1, mb: 2}}>
                    <Typography sx={{fontWeight: 600}}>
                        {t('accountBalanceFilter.datesMobile')}
                    </Typography>
                </Box>
                <Box>
                    <Box
                        onClick={() => setIsDROpen(true)}
                        sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                    >
                        <Box>
                            <TextField
                                label={t('accountBalanceFilter.from')}
                                value={startDate && startDate.format('DD/MM/YYYY')}
                            />
                        </Box>
                        <Box sx={{mx: 2}}>-</Box>
                        <Box>
                            <TextField
                                label={t('accountBalanceFilter.to')}
                                value={endDate && endDate.format('DD/MM/YYYY')}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box sx={{mt: 3, mb: 2}}>
                    <Typography sx={{fontWeight: 600}}>
                        {t('accountBalanceFilter.documentsMobile')}
                    </Typography>
                </Box>
                <Box>
                    <FormControlLabel
                        sx={{mr: 0}}
                        control={(
                            <Switch
                                checked={hasStatusFilterActive}
                                onChange={(e, value) => {
                                    setHasStatusFilterActive(value);
                                }}
                            />
                        )}
                        label={t('accountBalanceFilter.supportingDocumentsNotUploaded')}
                    />
                </Box>

                <Box sx={{mt: 3, mb: 2}}>
                    <Typography sx={{fontWeight: 600}}>
                        {t('accountBalanceFilter.types')}
                    </Typography>
                </Box>
                <Box>
                    <List sx={{width: '100%', backgroundColor: 'background.paper', pt: 0, maxHeight: '60vh', overflow: 'auto'}}>
                        {FILTER_LIST_MAP.map(status => (
                            <ListItem key={status} disablePadding>
                                <ListItemButton
                                    sx={{
                                        fontSize: theme => theme.typography.pxToRem(14),
                                        py: 0,
                                    }}
                                    onClick={() => {
                                        if (types.indexOf(status) !== -1) {
                                            onRemovePaymentType(status);
                                        } else {
                                            onAddPaymentType(status);
                                        }
                                    }}
                                    dense
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            tabIndex={-1}
                                            disableRipple
                                            checked={types.indexOf(status) !== -1}
                                        />
                                    </ListItemIcon>
                                    {t(`paymentType.${status}`)}
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>

                <Box sx={{my: 3}}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', gap: 2}}>
                        <Button
                            variant="contained"
                            color="grey"
                            fullWidth
                            onClick={onFilterReset}
                        >
                            {t('accountBalanceFilter.reset')}
                        </Button>
                        <Button
                            variant="contained"
                            fullWidth
                            color="secondary"
                            onClick={onFilterApply}
                        >
                            {t('accountBalanceFilter.apply')}
                        </Button>
                    </Box>
                </Box>

            </Box>
        </Drawer>
    );
};

AccountBalanceMobileFilter.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
};
