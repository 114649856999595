import {Box, Paper, Skeleton} from '@mui/material';
import React from 'react';

const AccountBalanceSkeletonMobile = () => {
    return (
        <Box>
            <Box sx={{
                mt: 3,
                display: 'flex',
                flexDirection: 'column',
            }}
            >
                <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 2, px: 2}}>
                    <Skeleton variant="rectangular" width={140} height={32} />
                    <Box sx={{display: 'flex'}}>
                        <Skeleton sx={{mr: 2}} variant="rectangular" width={32} height={32} />
                        <Skeleton variant="rectangular" width={32} height={32} />
                    </Box>
                </Box>

                <Paper sx={{pt: 2, px: 2}}>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Skeleton variant="rectangular" width={80} height={16} />

                        <AccountBalanceSkeletonMobileRow />
                        <AccountBalanceSkeletonMobileRow />
                        <AccountBalanceSkeletonMobileRow />

                        <Skeleton sx={{mt: 3}} variant="rectangular" width={80} height={16} />

                        <AccountBalanceSkeletonMobileRow />
                        <AccountBalanceSkeletonMobileRow />

                        <Skeleton sx={{mt: 3}} variant="rectangular" width={80} height={16} />

                        <AccountBalanceSkeletonMobileRow />
                        <AccountBalanceSkeletonMobileRow />

                        <Skeleton sx={{mt: 3}} variant="rectangular" width={80} height={16} />

                        <AccountBalanceSkeletonMobileRow />
                        <AccountBalanceSkeletonMobileRow />

                        <Skeleton sx={{mt: 3}} variant="rectangular" width={80} height={16} />

                        <AccountBalanceSkeletonMobileRow />
                        <AccountBalanceSkeletonMobileRow />
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
};

export default AccountBalanceSkeletonMobile;

const AccountBalanceSkeletonMobileRow = () => {
    return (
        <Box sx={{
            mt: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Skeleton sx={{mr: 1}} variant="rectangular" width={38} height={30} />

                <Skeleton sx={{mr: 1}} variant="circular" width={48} height={48} />

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
                >
                    <Skeleton sx={{mb: 1}} variant="rectangular" width={140} height={16} />
                    <Skeleton variant="rectangular" width={40} height={16} />
                </Box>
            </Box>

            <Skeleton sx={{pb: 1}} variant="rectangular" width={70} height={24} />
        </Box>
    );
};
