import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AttachmentIcon from '@mui/icons-material/Attachment';
import {Box, Collapse, Divider, Typography} from '@mui/material';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Category from './account-balance-data-row/AccountBalanceTableDataRowCategory';
import ChipSection from './account-balance-data-row/AccountBalanceTableDataRowChip';
import AccountBalanceTableDataRowDate from './account-balance-data-row/AccountBalanceTableDataRowDate';
import Vat from './account-balance-data-row/AccountBalanceTableDataRowVat';
import AccountBalanceIcon from './AccountBalanceIcon';
import {ConditionalTooltip} from '../../../../../../components/tooltip/ConditionalTooltip';
import {currencyToSign, getNumberSegments, numberWithSpaces} from '../../../../../../v1/app/app.helpers';
import {TransactionsActions} from '../../store/transactions.action';
import {TransactionsSelector} from '../../store/transactions.selector';
import {
    MAX_TRANSACTIONS_ANIMATION, TRANSACTION_CATEGORIZATION_SCREENS,
    TransactionSide,
} from '../../util/constants';
import {AccountBalanceHelperFunctions} from '../../util/functions';

const currentYear = moment().format('YYYY');
const AccountBalanceTableDataRow = ({
    id,
    prevId,
    isSidebarOpened,
    tableSize,
    isMobileSize,
    filter,
    onOpenClick,
    language,
    selectCategoriesObject,
    selectSourcesObject,
    t,
    areFieldsCollapsed,
    totalLoadedIds,
    isUserHasInvoiceAccess,
    combinedTabIcons,
}) => {
    const dispatch = useDispatch();

    const data = useSelector(TransactionsSelector.createSelectTransactionById(id));
    const prevData = useSelector(TransactionsSelector.createSelectTransactionById(prevId));

    if (!data) {
        return null;
    }

    // Do not display if filter for not categorized is active, but it has no chip flag
    if (!data.hasChip && filter.isNotCategorized) {
        return null;
    }

    const isDebit = data.side === 'DEBIT';
    let AdditionalContent = null;
    let isEditable = true;

    const {
        isRejected,
        isCancelled,
        isOnHold,
        isUpcoming,
        isNotCategorized,
        isCategorized,
        isAutoCategorized,
    } = AccountBalanceHelperFunctions.getCategorizationState(data);

    const isRejectedOrCanceled = isCancelled || isRejected;

    const isChipVisible = isUserHasInvoiceAccess || data?.side !== TransactionSide.CREDIT;

    const hasChips = isChipVisible
        && (
            isNotCategorized
            || isCategorized
            || isRejected
            || isCancelled
        );

    if (currentYear !== data.date.format('YYYY')) {
        isEditable = false;
    } else if (isRejectedOrCanceled) {
        isEditable = false;
    }

    let isNewDate = false;

    if (!prevData || (!prevData.date.isSame(data.date, 'day'))) {
        isNewDate = true;
    }

    if (prevData && (prevData.date.format('M') !== data.date.format('M'))) {
        let displayYear = '';
        if (currentYear !== data.date.format('YYYY')) {
            displayYear = data.date.format('YYYY');
        }

        AdditionalContent = (
            <Box sx={{
                // position: 'relative',
                // left: '20px',
                display: 'flex',
                alignItems: 'flex-end',
                color: 'blueGray.400',
                fontSize: theme => theme.typography.pxToRem(24),
                fontWeight: 500,
                mt: '-2px',
                mb: 0,
                textTransform: 'capitalize',
                px: 3,
            }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    alignItems: 'center',
                    minWidth: '19px',
                    height: '100%',
                    minHeight: '60px',
                    position: 'relative',
                }}
                >
                    <Box sx={{
                        borderRight: '1px solid #333',
                        borderColor: 'v2.blueGray.200',
                        display: 'flex',
                        flexGrow: 1,
                        zIndex: 4,
                    }}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    ml: 'calc(5% - 40px)',
                    position: 'relative',
                    top: '4px',
                }}
                >
                    { data.date.format('MMMM') } { displayYear }
                </Box>
            </Box>

        );
    }

    const numberSegments = getNumberSegments(numberWithSpaces(data.amount));


    if (isMobileSize) {
        return (
            <Box
                key={data.id}
                onClick={() => onOpenClick(data.id, data.hasChip && isChipVisible, data.status)}
                sx={{
                    px: 1,
                }}
            >

                <Box sx={{
                    display: 'flex',
                }}
                >
                    <Box sx={{
                        width: '50px',
                    }}
                    >
                        <AccountBalanceTableDataRowDate
                            data={data}
                            isNewDate={isNewDate}
                        />

                    </Box>

                    <Box sx={{
                        width: 'calc(100vw - 50px)',
                    }}
                    >
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                        >
                            <Box sx={{
                                width: '50%',
                            }}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                >
                                    <AccountBalanceIcon
                                        paymentType={data.type}
                                        category={data.category}
                                        sourceOfRevenue={data.sourceOfRevenue}
                                        hasBackground={false}
                                        iconColor={isRejected ? 'v2.light.text.disabled' : 'v2.blueGray.400'}
                                        size="20px"
                                    />

                                    {isChipVisible && isOnHold && (
                                    <ConditionalTooltip tooltip={t('accountBalanceTableHeader.onHold')}>
                                        <AccessTimeFilledIcon
                                            fontSize="small"
                                            sx={{
                                                color: 'warning_v2.main',
                                                ml: -1,
                                                mr: 1,
                                            }}
                                        />
                                    </ConditionalTooltip>
                                    )}

                                    <Typography sx={{
                                        fontWeight: 600,
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        color: isRejected ? 'v2.light.text.disabled' : 'inherit',
                                    }}
                                    >
                                        {AccountBalanceHelperFunctions.getTransactionLabel(data)}
                                    </Typography>
                                    {AccountBalanceHelperFunctions.showLabelIcon(data, combinedTabIcons)}
                                </Box>
                            </Box>

                            <Box sx={{
                                width: '50%',
                            }}
                            >
                                <Box sx={{
                                    fontWeight: 600,
                                    color: isRejected ? 'v2.light.text.disabled' : !isDebit ? 'success_v2.light' : 'lightTextSecondary.main',
                                    textAlign: 'right',
                                    whiteSpace: 'nowrap',
                                    fontSize: theme => theme.typography.pxToRem(12),
                                }}
                                >
                                    {parseFloat(data.amount) !== 0 && (isDebit ? '-' : '+')}
                                    <Typography
                                        component="span"
                                        sx={{fontSize: theme => theme.typography.pxToRem(16)}}
                                    >
                                        {numberSegments?.integer},
                                    </Typography>
                                    <Typography
                                        component="span"
                                        sx={{fontSize: theme => theme.typography.pxToRem(12)}}
                                    >
                                        {numberSegments?.decimal}
                                    </Typography>
                                    {currencyToSign(data.currency)}
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                        >
                            <Box sx={{
                                width: '50%',
                            }}
                            >
                                <Category
                                    side={data.side}
                                    category={data.category}
                                    sourceOfRevenue={data.sourceOfRevenue}
                                    language={language}
                                    selectSourcesObject={selectSourcesObject}
                                    selectCategoriesObject={selectCategoriesObject}
                                    isEditable={isEditable}
                                />
                            </Box>
                            <Box sx={{
                                width: '50%',
                            }}
                            >
                                {hasChips && (
                                <ChipSection
                                    isNotCategorized={isNotCategorized}
                                    isRejected={isRejected}
                                    isCancelled={isCancelled}
                                    isCategorized={isCategorized}
                                    isAutoCategorized={isAutoCategorized}
                                    isUpcoming={isUpcoming}
                                    t={t}
                                />
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }

    const CollapseElement = totalLoadedIds > MAX_TRANSACTIONS_ANIMATION ? Box : Collapse;

    return (
        <Box
            key={data.id}
            onClick={() => onOpenClick(data.id, data.hasChip && isChipVisible, data.status)}
        >
            {AdditionalContent}
            <Box sx={{
                'display': 'flex',
                'alignItems': 'center',
                'overFlowX': 'hidden',
                'pr': 2,
                '&:hover': {
                    'cursor': 'pointer',
                    'background': 'rgba(25, 118, 210, 0.04)',
                    '& .vat-box': {
                        visibility: 'visible',
                    },
                },
            }}
            >
                <Box sx={{
                    flex: tableSize.firstSegment,
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                }}
                >
                    <AccountBalanceTableDataRowDate
                        data={data}
                        isNewDate={isNewDate}
                    />

                </Box>

                <Box sx={{
                    flex: tableSize.secondSegment,
                }}
                >
                    {hasChips && (
                        <ChipSection
                            isNotCategorized={isNotCategorized}
                            isRejected={isRejected}
                            isCancelled={isCancelled}
                            isCategorized={isCategorized}
                            isAutoCategorized={isAutoCategorized}
                            isUpcoming={isUpcoming}
                            t={t}
                        />
                    )}
                </Box>

                <Box sx={{
                    flex: tableSize.thirdSegment,
                    maxWidth: '35%',
                }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    >
                        <AccountBalanceIcon
                            paymentType={data.type}
                            category={data.category}
                            sourceOfRevenue={data.sourceOfRevenue}
                            hasBackground={false}
                            iconColor={isRejected ? 'v2.light.text.disabled' : 'v2.blueGray.400'}
                            size="20px"
                        />

                        {isChipVisible && isOnHold && (
                            <ConditionalTooltip
                                sx={{
                                    textTransform: 'uppercase',
                                }}
                                tooltip={t('accountBalanceTableHeader.onHoldAlertHeader')}
                            >
                                <AccessTimeFilledIcon
                                    fontSize="small"
                                    sx={{
                                        color: 'warning_v2.main',
                                        ml: -1,
                                        mr: 1,
                                    }}
                                />
                            </ConditionalTooltip>
                        )}

                        <Typography sx={{
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: '24px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            color: isRejected ? 'v2.light.text.disabled' : 'inherit',
                        }}
                        >
                            {AccountBalanceHelperFunctions.getTransactionLabel(data)}
                        </Typography>
                        {AccountBalanceHelperFunctions.showLabelIcon(data, combinedTabIcons)}
                    </Box>
                </Box>

                <CollapseElement
                    in={!isSidebarOpened || !areFieldsCollapsed}
                    sx={{
                        flex: tableSize.fourthSegment,
                        display: 'flex',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <Category
                            side={data.side}
                            category={data.category}
                            sourceOfRevenue={data.sourceOfRevenue}
                            language={language}
                            selectSourcesObject={selectSourcesObject}
                            selectCategoriesObject={selectCategoriesObject}
                            isEditable={isEditable}
                        />

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 2,
                                visibility: 'hidden',
                            }}
                            className="vat-box"
                        >
                            {data.category && data?.side === TransactionSide.DEBIT && (
                                <Vat data={data} isEditable={isEditable} />
                            )}

                            {data?.documents && data?.side === TransactionSide.DEBIT && (
                            <ConditionalTooltip tooltip={`${data.documents.length} document`}>
                                <AttachmentIcon
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        dispatch(TransactionsActions.setCategorizationScreenInfo({
                                            screen: TRANSACTION_CATEGORIZATION_SCREENS.DOCUMENT,
                                            transactionId: data?.id,
                                        }));

                                        dispatch(TransactionsActions.addToCategorisationList({
                                            transactionId: data?.id,
                                            hasSpecialHandlingIfCategorised: true,
                                        }));
                                    }}
                                    sx={{
                                        '&:hover': {
                                            color: 'primary_v2.main',
                                        },
                                    }}
                                />
                            </ConditionalTooltip>
                            )}
                        </Box>
                    </Box>
                </CollapseElement>

                <Box sx={{
                    flex: tableSize.fifthSegment,
                    pr: 1.2,
                }}
                >
                    <Box sx={{
                        fontWeight: 600,
                        color: isRejected ? 'v2.light.text.disabled' : !isDebit ? 'success_v2.light' : 'lightTextSecondary.main',
                        textAlign: 'right',
                        whiteSpace: 'nowrap',
                        fontSize: theme => theme.typography.pxToRem(12),
                    }}
                    >
                        <Typography
                            component="span"
                            sx={{
                                fontSize: theme => theme.typography.pxToRem(16),
                                fontWeight: 600,
                            }}
                        >
                            {parseFloat(data.amount) !== 0 && (isDebit ? '-' : '+')} {numberSegments?.integer},
                        </Typography>
                        <Typography
                            component="span"
                            sx={{fontSize: theme => theme.typography.pxToRem(12)}}
                        >
                            {numberSegments?.decimal}
                        </Typography>
                        {currencyToSign(data.currency)}
                    </Box>
                </Box>

            </Box>

            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                <Divider sx={{
                    borderColor: 'rgba(0, 0, 0, 0.12)',
                    width: 'calc(100% - 35px - 24px - 5px)',
                }}
                />
            </Box>
        </Box>
    );
};

AccountBalanceTableDataRow.propTypes = {
    id: PropTypes.string.isRequired,
    prevId: PropTypes.string,
    isSidebarOpened: PropTypes.bool.isRequired,
    tableSize: PropTypes.object.isRequired,
    isMobileSize: PropTypes.bool.isRequired,
    filter: PropTypes.object.isRequired,
    onOpenClick: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    selectCategoriesObject: PropTypes.object.isRequired,
    selectSourcesObject: PropTypes.object.isRequired,
    areFieldsCollapsed: PropTypes.bool,
    t: PropTypes.func.isRequired,
    totalLoadedIds: PropTypes.number,
    combinedTabIcons: PropTypes.object,
    isUserHasInvoiceAccess: PropTypes.bool.isRequired,
};

AccountBalanceTableDataRow.defaultProps = {
    areFieldsCollapsed: false,
    prevId: '',
    totalLoadedIds: 0,
    combinedTabIcons: null,
};

export default AccountBalanceTableDataRow;
