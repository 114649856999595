import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import {Badge, Box, IconButton, Paper, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ButtonIcon} from '../../../../../../components/buttons/ButtonIcon';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {LoggedInUserSelectors} from '../../../../../user/modules/logged-in-user';
import {TransactionsActions} from '../../store/transactions.action';
import {TransactionsSelector} from '../../store/transactions.selector';
import {MAX_TRANSACTIONS_ANIMATION, TRANSACTION_CATEGORIZATION_SCREENS} from '../../util/constants';
import {AccountBalanceHelperFunctions} from '../../util/functions';
import {AccountBalanceAlert} from '../account-balance/AccountBalanceAlert';
import {AccountBalanceFilter} from '../account-balance/AccountBalanceFilter';
import {AccountBalanceMobileFilter} from '../account-balance/AccountBalanceMobileFilter';
import {AccountBalanceMobileSearch} from '../account-balance/AccountBalanceMobileSearch';
import {AccountBalancePopovers} from '../account-balance/AccountBalancePopovers';
import {AccountBalanceScrollHeader} from '../account-balance/AccountBalanceScrollHeader';
import AccountBalanceSkeleton from '../account-balance/AccountBalanceSkeleton';
import AccountBalanceSkeletonMobile from '../account-balance/AccountBalanceSkeletonMobile';
import {AccountBalanceTable} from '../account-balance/AccountBalanceTable';
import {AccountBalanceTotal} from '../account-balance/AccountBalanceTotal';
import {useUncategorizedFilterFromURL} from '../hooks/use-uncategorized-filter-from-url';

// TODO: move the same code from this component and from the CombinedTransactionList
// to separate components or combine them to one component cause these components contain a lot of copy-paste code

const CombinedTransactionList = ({
    topOffset,
    isAdminInterface,
    balance,
    isOpened,
    user,
    hasBankSidebarOpen,
    combinedTabIcons,
    isArchived,
    isTabValueAuto,
}) => {
    const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);
    const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);

    const dispatch = useDispatch();
    const {t} = useTranslation('bank');

    const [anchorEl, setAnchorEl] = useState(null);
    const [filter, setFilter] = useState({
        startDate: null,
        endDate: null,
        transactionTypes: [],
        isNotCategorized: false,
        search: '',
    });

    // TODO: Consider moving the whole filter state to URL params
    // This sets initial value of `isNotCategorized` on page load
    useUncategorizedFilterFromURL(setFilter);

    const transactionIds = useSelector(TransactionsSelector.selectTransactionIds);

    const transactionNonCategorizedCount = useSelector(TransactionsSelector.selectTransactionNotCategorizedCount);

    const onSwitchChange = value => {
        setFilter({
            startDate: null,
            endDate: null,
            search: '',
            transactionTypes: [],
            isNotCategorized: value,
        });
    };

    const loadNextPage = useCallback(() => {
        dispatch(TransactionsActions.loadNextCombinedPage(
            isAdminInterface && user?.id
                ? {...filter, userId: user.id, archivedOnly: isArchived}
                : {...filter, archivedOnly: isArchived},
        ));
        // eslint-disable-next-line
    }, [filter]);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isLoading = useSelector(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.BANK_TRANSACTIONS,
    ));

    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const isUserHasInvoiceAccess = loggedInUser?.hasInvoiceAccess;

    const isSidebarOpened = !!hasBankSidebarOpen;

    useEffect(() => {
        // Load on isOpened/filter change is not loading already
        // ignore is loading param on isArchived due to the case when archived is the only tab
        // TODO It could be done more elegant with different loaders for archive, but that required refactor
        if (
            isOpened
            && !isTabValueAuto
            && (isArchived || !isLoading)
        ) {
            dispatch(TransactionsActions.getCombinedTransactionList(
                isAdminInterface && user?.id
                    ? {...filter, userId: user.id, archivedOnly: isArchived, onFilterChanged: true}
                    : {...filter, archivedOnly: isArchived, onFilterChanged: true},
            ));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filter, isOpened]);

    const onOpenClick = id => {
        dispatch(TransactionsActions.setCategorizationScreenInfo({
            screen: TRANSACTION_CATEGORIZATION_SCREENS.CATEGORY,
            transactionId: id,
        }));

        dispatch(TransactionsActions.addToCategorisationList({
            transactionId: id,
            hasSpecialHandlingIfCategorised: true,
        }));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const scrollFunction = useCallback(debounce(() => {
        if (anchorEl && anchorEl.id) {
            let anchorId = null;
            let nextAnchor = null;

            if (anchorEl.id.includes('dr')) {
                anchorId = 'dr';
            } else if (anchorEl.id.includes('supportingDocuments')) {
                anchorId = 'supportingDocuments';
            } else if (anchorEl.id.includes('type')) {
                anchorId = 'type';
            }

            // TODO: use Refs
            if (document.body.scrollTop > topOffset || document.documentElement.scrollTop > topOffset) {
                // sticky header
                if (!anchorEl.id.includes('inHeader')) {
                    nextAnchor = document.querySelector(`#${anchorId}-inHeader`);
                }
            } else if (anchorEl.id.includes('inHeader')) {
                nextAnchor = document.querySelector(`#${anchorId}`);
            }

            if (nextAnchor) {
                setAnchorEl(nextAnchor);
            }
        }
    }, 100), [anchorEl, topOffset]);

    useEffect(() => {
        window.addEventListener('scroll', scrollFunction);
        return () => {
            window.removeEventListener('scroll', scrollFunction);
        };
        // eslint-disable-next-line
    }, [anchorEl]);

    if (isLoading) {
        if (isMobileSize) {
            return (
                <AccountBalanceSkeletonMobile />
            );
        }
        return (
            <AccountBalanceSkeleton />
        );
    }

    const accountBalanceFilter = isInHeader => (
        <AccountBalanceFilter
            filter={filter}
            setFilter={setFilter}
            isSidebarOpened={isSidebarOpened}
            totalLoadedIds={transactionIds?.length}
            setAnchorEl={setAnchorEl}
            isInHeader={isInHeader}
        />
    );

    let isOpenDrPicker = false;
    let isOpenSupportingDocuments = false;
    let isOpenType = false;

    if (anchorEl && anchorEl.id) {
        if (anchorEl.id.includes('dr')) {
            isOpenDrPicker = true;
        } else if (anchorEl.id.includes('supportingDocuments')) {
            isOpenSupportingDocuments = true;
        } else if (anchorEl.id.includes('type')) {
            isOpenType = true;
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <AccountBalancePopovers
                isOpenDrPicker={isOpenDrPicker}
                anchorEl={anchorEl}
                handleClose={handleClose}
                isOpenSupportingDocuments={isOpenSupportingDocuments}
                isOpenType={isOpenType}
                filter={filter}
                setFilter={setFilter}
            />

            {!isMobileSize && (
                <AccountBalanceScrollHeader
                    filter={filter}
                    setFilter={setFilter}
                    isSidebarOpened={isSidebarOpened}
                    balance={balance}
                    totalLoadedIds={transactionIds?.length}
                    topOffset={topOffset}
                    accountBalanceFilter={accountBalanceFilter(true)}
                />
            )}

            <Box sx={{display: 'flex'}}>
                <Box sx={{
                    width: !isSidebarOpened
                        ? '100%'
                        : AccountBalanceHelperFunctions.calculateMainContainerOpenWidth(),
                    transition: transactionIds?.length > MAX_TRANSACTIONS_ANIMATION ? 'none' : 'width 0.4s ease-in-out',
                }}
                >
                    {isMobileSize && (
                        <Box sx={{
                            mt: 0,
                            mx: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                        >
                            <AccountBalanceTotal amount={balance} />

                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            >
                                <Badge
                                    color="secondary"
                                    variant="dot"
                                    invisible={
                                        (filter?.transactionTypes?.length === 0)
                                        && !(filter.isNotCategorized)
                                        && !(filter.startDate || filter.endDate)
                                    }
                                    sx={{
                                        '.MuiBadge-overlapRectangular': {
                                            transform: 'scale(1) translate(-2px, 2px)',
                                        },
                                    }}
                                >
                                    <ButtonIcon
                                        sx={{
                                            mb: 1.3,
                                            color: 'v2.blueGray.900',
                                        }}
                                        icon={<FilterAltIcon />}
                                        onClick={() => setIsMobileFilterOpen(prevValue => !prevValue)}
                                    />
                                </Badge>
                                <IconButton
                                    sx={{
                                        mb: 1.3,
                                        ml: 1.5,
                                        color: isMobileSearchOpen ? 'primary_v2.main' : 'v2.blueGray.900',
                                    }}
                                    onClick={() => { setIsMobileSearchOpen(prevValue => !prevValue); }}
                                >
                                    <SearchIcon />
                                </IconButton>
                                <AccountBalanceMobileFilter
                                    isOpen={isMobileFilterOpen}
                                    setIsOpen={setIsMobileFilterOpen}
                                    filter={filter}
                                    setFilter={setFilter}
                                />
                            </Box>
                        </Box>
                    )}

                    <Paper sx={{
                        pt: 2,
                        px: isMobileSize ? 0 : 3,
                        mx: isMobileSize ? -2 : 'inherit',
                    }}
                    >
                        <Box>
                            {!isMobileSize && (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    overflowY: 'auto',
                                }}
                                >
                                    {isArchived ? (
                                        <Box sx={{mb: isMobileSize ? 1 : 2, position: 'relative', textWrap: 'nowrap'}}>
                                            <Typography
                                                sx={{
                                                    fontSize: theme => theme.typography.pxToRem(21),
                                                    fontWeight: 700,
                                                    display: 'inline',
                                                    textWrap: 'nowrap',
                                                }}
                                            >
                                                {t('accountArchiving.tabTitle')}
                                            </Typography>
                                            <Typography sx={{
                                                fontSize: theme => theme.typography.pxToRem(12),
                                                color: 'text_v2.disabled',
                                            }}
                                            >
                                                {t('accountArchiving.tabSubtitle')}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <AccountBalanceTotal amount={balance} />
                                    )}
                                    {accountBalanceFilter(false)}
                                </Box>
                            )}

                            {isMobileSearchOpen && (
                                <AccountBalanceMobileSearch
                                    filter={filter}
                                    setFilter={setFilter}
                                />
                            )}

                            <AccountBalanceAlert
                                count={transactionNonCategorizedCount}
                                onSwitchChange={onSwitchChange}
                                transactionNonCategorizedCount={transactionNonCategorizedCount}
                            />

                            <Box sx={{
                                mx: isMobileSize ? 0 : -3,
                            }}
                            >
                                <AccountBalanceTable
                                    filter={filter}
                                    transactionIds={transactionIds}
                                    loadNextPage={loadNextPage}
                                    isUserHasInvoiceAccess={isUserHasInvoiceAccess}
                                    hasAlert={transactionNonCategorizedCount !== 0}
                                    onOpenClick={onOpenClick}
                                    totalLoadedIds={transactionIds?.length}
                                    combinedTabIcons={combinedTabIcons}
                                    isSidebarOpened={isSidebarOpened}
                                />
                            </Box>
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </>
    );
};

CombinedTransactionList.propTypes = {
    topOffset: PropTypes.number.isRequired,
    isAdminInterface: PropTypes.bool.isRequired,
    balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isOpened: PropTypes.bool.isRequired,
    user: PropTypes.object,
    combinedTabIcons: PropTypes.object.isRequired,
    hasBankSidebarOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
    isArchived: PropTypes.bool,
    isTabValueAuto: PropTypes.bool.isRequired,
};

CombinedTransactionList.defaultProps = {
    user: {},
    isArchived: false,
};

export default CombinedTransactionList;
