import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {Box, Chip} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {ConditionalTooltip} from '../../../../../../../components/tooltip/ConditionalTooltip';

const ChipSection = ({
    t,
    isRejected,
    isNotCategorized,
    isCancelled,
    isCategorized,
    isAutoCategorized,
}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Box sx={isMobileSize ? {
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
            gap: 1,
        } : {
            display: 'flex',
            justifyContent: 'flex-start',
            gap: 1,
        }}
        >
            {isNotCategorized && !isRejected && !isCancelled && (
                <Chip
                    sx={{
                        fontSize: theme => theme.typography.pxToRem(12),
                        lineHeight: '14px',
                        borderRadius: '4px',
                        fontWeight: 400,
                        pl: 0.5,
                        borderColor: 'v2.light.secondary.shades12',
                    }}
                    size="small"
                    variant="outlined"
                    color="secondary"
                    label={t('accountBalanceTableHeader.toCategorize')}
                    icon={(
                        <Box sx={{
                            width: '6px',
                            height: '6px',
                            borderRadius: '50%',
                            backgroundColor: 'red',
                        }}
                        />
                    )}
                />
            )}

            {isCategorized && !isRejected && !isCancelled && (
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
                >
                    <Chip
                        sx={{
                            fontSize: theme => theme.typography.pxToRem(12),
                            lineHeight: '14px',
                            borderRadius: '4px',
                            fontWeight: 400,
                            pl: 0.5,
                            color: 'success_v2.main',
                            backgroundColor: 'v2.light.success.shades4',
                            borderColor: 'v2.light.success.shades12',
                        }}
                        size="small"
                        variant="outlined"
                        color="success"
                        label={t('accountBalanceTableHeader.categorized')}
                    />

                    {isAutoCategorized && (
                        <ConditionalTooltip tooltip="Auto catégorisée">
                            <AutoFixHighIcon sx={{
                                color: 'warning_v2.main',
                                ml: 1,
                            }}
                            />
                        </ConditionalTooltip>
                    )}
                </Box>
            )}

            {isRejected && (
                <Chip
                    sx={{
                        fontSize: theme => theme.typography.pxToRem(12),
                        lineHeight: '14px',
                        borderRadius: '4px',
                        fontWeight: 400,
                        borderColor: 'v2.light.secondary.shades12',
                    }}
                    size="small"
                    variant="outlined"
                    color="error"
                    label={t('accountBalanceTableHeader.rejected')}
                    icon={<ErrorIcon />}
                />
            )}

            {isCancelled && (
                <Chip
                    sx={{
                        fontSize: theme => theme.typography.pxToRem(12),
                        lineHeight: '14px',
                        borderRadius: '4px',
                        fontWeight: 400,
                        backgroundColor: 'white',
                        color: 'v2.blueGray.300',
                        border: '1px solid gray',
                        borderColor: 'v2.light.secondary.shades12',
                    }}
                    size="small"
                    variant="contained"
                    color="error"
                    label={t('accountBalanceTableHeader.cancelled')}
                    icon={<ErrorOutlineIcon />}
                />
            )}
        </Box>
    );
};

ChipSection.propTypes = {
    t: PropTypes.func.isRequired,
    isRejected: PropTypes.bool.isRequired,
    isNotCategorized: PropTypes.bool.isRequired,
    isCancelled: PropTypes.bool.isRequired,
    isCategorized: PropTypes.bool.isRequired,
    isAutoCategorized: PropTypes.bool.isRequired,
};

export default ChipSection;
