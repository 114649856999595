import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import {Button, Grid, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Toast} from '../../../../../lib/toast';
import {copyToClipboard} from '../../../../../utils/copy-to-clipboard';
import {
    createEnterpirseAddress,
} from '../../../../../v1/components/company/company-profile/EnterpriseInformationForm/util';
import {FreelancerSelectors} from '../../../../freelancer';
import {CompaniesSelectors} from '../../../../freelancer/modules/companies';
import {BankSelector} from '../../../store/bank.selector';
import {getIntegrationInfo} from '../../bridge-api/utils/bridge-util';
import {getActiveHiwayIntegration} from '../utils/ribAndDocuments.util';

const DetailsSection = () => {
    const {t} = useTranslation('bankRibAndDocuments');
    const integrations = useSelector(BankSelector.selectIntegrations);
    const freelancer = useSelector(FreelancerSelectors.selectAccount);
    const company = useSelector(CompaniesSelectors.createCompanyByIdSelector(freelancer.defaultCompanyId));
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const hiwayIntegration = getActiveHiwayIntegration(integrations);
    const {account} = getIntegrationInfo(hiwayIntegration);
    const {iban, bic} = account || {};

    const data = [
        {key: t('detailsSection.IBAN'), value: iban},
        {key: t('detailsSection.BIC'), value: bic},
        {key: t('detailsSection.companyName'), value: company?.name},
        {key: t('detailsSection.companyAddress'), value: createEnterpirseAddress(company?.enterpriseInformation)},
    ];

    const onCopyAllClick = () => {
        const transformedData = data.map(item => `${item.key} : ${item.value}`).join('\n');
        copyToClipboard(transformedData);
        Toast.success('copiedToClipboard');
    };

    return (
        <Box
            sx={{
                height: isMobileSize ? 'fit-content' : '300px',
                bgcolor: 'whitePallete.default',
                borderRadius: 4,
                overflow: 'hidden',
                flex: '1 1 auto',
            }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: isMobileSize ? 'column' : 'row',
                justifyContent: 'space-between',
                alignItems: isMobileSize ? 'flex-start' : 'center',
                gap: 2,
                pt: 2,
                pr: 2,
                pb: 3,
                pl: 3,
            }}
            >
                <Typography variant="h5">
                    {t('detailsSection.title')}
                </Typography>
                <Button
                    variant="outlined"
                    size="meium"
                    sx={{
                        py: 0.75,
                        pr: 2,
                        pl: 1.5,
                        lineHeight: theme => theme.typography.pxToRem(24),
                    }}
                    startIcon={
                        <CopyAllIcon sx={{fontSize: theme => theme.typography.pxToRem(20)}} />}
                    onClick={onCopyAllClick}
                >
                    {t('detailsSection.copyAll')}
                </Button>
            </Box>
            <Box>
                <Grid
                    container
                    sx={{
                        '& > :not(:last-child)': {
                            borderBottom: 1,
                            borderColor: 'v2.gray.300',
                        },
                        'color': 'text_v2.primary',
                    }}
                >
                    {data.map((item, index) => (
                        <Grid
                            key={index}
                            container
                            item
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'v2.light.primary.shades4',
                                    ...(!isMobileSize && {
                                        '& .CopyIcon': {visibility: 'visible'},
                                    }),
                                },
                                'py': 2,
                                'px': 3,
                                'height': isMobileSize ? 'fit-content' : 56,
                                'alignContent': 'center',
                                'alignItems': 'center',
                            }}
                        >
                            <Grid item xs={12} md={5} alignItems="center">
                                <Typography
                                    variant={isMobileSize ? 'body2' : 'body1'}
                                    color={isMobileSize ? 'v2.light.text.disabled' : 'v2.light.text.secondary'}
                                >
                                    {item.key}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        directions: 'row',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            py: 0.5,
                                        }}
                                    >
                                        {item.value}
                                    </Typography>
                                    <IconButton
                                        aria-label="copy"
                                        className="CopyIcon"
                                        sx={{
                                            color: 'v2.light.action.active',
                                            p: 0.5,
                                            ...(!isMobileSize && {
                                                visibility: 'hidden',
                                            }),
                                        }}
                                        size="small"
                                        onClick={() => {
                                            copyToClipboard(item.value);
                                            Toast.success('copiedToClipboard');
                                        }}
                                    >
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default DetailsSection;
