import {extendTheme} from '@mui/joy/styles';

const JoyUiTheme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                mode: 'light',
                primary: {
                    50: '#F9E8E9',
                    100: '#F0C5C8',
                    200: '#E7A3A7',
                    300: '#DE8185',
                    400: '#D55F64',
                    500: '#CB3C43',
                    600: '#B1343B',
                    700: '#962B32',
                    800: '#7B2329',
                    900: '#502131',
                    plainColor: 'var(--joy-palette-primary-500, #CB3C43)',
                    plainHoverBg: 'var(--joy-palette-primary-100, #F0C5C8)',
                    plainActiveBg: 'var(--joy-palette-primary-200, #E7A3A7)',
                    // plainDisabledColor
                    outlinedColor: 'var(--joy-palette-primary-500, #CB3C43)',
                    outlinedBorder: 'var(--joy-palette-primary-300, #DE8185)',
                    outlinedHoverBg: 'var(--joy-palette-primary-100, #F0C5C8)',
                    outlinedActiveBg: 'var(--joy-palette-primary-200, #E7A3A7)',
                    // outlinedDisabledColor
                    // outlinedDisabledBorder
                    softColor: 'var(--joy-palette-primary-700, #962B32)',
                    softBg: 'var(--joy-palette-primary-50, #F9E8E9)',
                    softHoverBg: 'var(--joy-palette-primary-100, #F0C5C8)',
                    softActiveColor: 'var(--joy-palette-primary-800, #7B2329)',
                    softActiveBg: 'var(--joy-palette-primary-200, #E7A3A7)',
                    softDisabledColor: 'var(--joy-palette-neutral-400, #A5AEB1)',
                    softDisabledBg: 'var(--joy-palette-neutral-50, #F7F8F8)',
                    solidColor: 'var(--joy-palette-linen-200, #FBF3EA)',
                    solidBg: 'var(--joy-palette-secondary-500, #E7604D)',
                    solidHoverBg: 'var(--joy-palette-secondary-600, #CF5435)',
                    solidActiveBg: 'var(--joy-palette-secondary-700, #B5482E)',
                    solidDisabledColor: 'var(--joy-palette-neutral-400, #A5AEB1)',
                    // solidDisabledBg
                    // mainChannel: "11 107 203",
                    // lightChannel: "199 223 247",
                    // darkChannel: "18 70 123",
                },
                secondary: {
                    50: '#FCE8E4',
                    100: '#F9C7BB',
                    200: '#F4A592',
                    300: '#F08368',
                    400: '#EC6240',
                    500: '#E7604D',
                    600: '#CF5435',
                    700: '#B5482E',
                    800: '#9A3C26',
                    900: '#80301F',
                },
                tertiary: {
                    50: '#F4EFFE',
                    100: '#E6DFFB',
                    200: '#D9CFFD',
                    300: '#CBBFFF',
                    400: '#3F4F56',
                    500: '#C9B2F8',
                    600: '#B09ADA',
                    700: '#977FBF',
                    800: '#7D63A3',
                    900: '#634788',
                },
                neutral: {
                    50: '#F7F8F8',
                    100: '#EBEDEE',
                    200: '#DFE4E5',
                    300: '#D2DADC',
                    400: '#A5AEB1',
                    500: '#6C838D',
                    600: '#566971',
                    700: '#3F4F56',
                    800: '#29353B',
                    900: '#1C2729',
                    outlinedBorder: 'var(--joy-palette-neutral-100, #EBEDEE)',
                    solidColor: 'var(--joy-palette-common-white, #FFF)',
                    solidDisabledColor: 'var(--joy-palette-neutral-400, #A5AEB1)',
                    solidBg: 'var(--joy-palette-neutral-500, #6C838D)',
                    solidHoverBg: 'var(--joy-palette-neutral-600, #566971)',
                    solidActiveBg: 'var(--joy-palette-neutral-700, #3F4F56)',
                    softColor: 'var(--joy-palette-primary-900, #502131)',
                    softDisabledColor: 'var(--joy-palette-neutral-400, #A5AEB1)',
                    plainColor: 'var(--joy-palette-neutral-900, #1C2729)',
                    plainHoverBg: 'var(--joy-palette-neutral-100, #EBEDEE)',
                    plainActiveBg: 'var(--joy-palette-neutral-200, #DFE4E5)',
                    softBg: 'var(--joy-palette-neutral-50, #F7F8F8)',
                    softHoverBg: 'var(--joy-palette-neutral-100, #EBEDEE)',
                    softActiveBg: 'var(--joy-palette-neutral-200, #DFE4E5)',
                    mainChannel: '99 107 115',
                },
                linen: {
                    50: '#FEFBF8',
                    100: '#FDF7F1',
                    200: '#FBF3EA',
                    300: '#F6EBDF',
                    400: '#F2E3D4',
                    500: '#EFDCCA',
                    600: '#E0CAB3',
                    700: '#D1B99C',
                    800: '#C2A885',
                    900: '#B3976E',
                },
                // danger
                success: {
                    50: '#F2FBF7',
                    100: '#DFF6ED',
                    200: '#C1EAD4',
                    300: '#A3DDC0',
                    400: '#86D1AD',
                    500: '#68C599',
                    600: '#4ABB86',
                    700: '#2CB072',
                    800: '#0FA55F',
                    900: '#008B4B',
                    softActiveColor: 'var(---joy-palette-success-800, #0FA55F)',
                    softBg: 'var(---joy-palette-success-50, #C1EAD4)',
                    solidColor: 'var(---joy-palette-neutral-900, #1C2729)',
                    outlinedColor: 'var(--joy-palette-neutral-900, #1C2729)',
                },
                warning: {
                    50: '#FFF5E5',
                    100: '#FFE4BF',
                    200: '#FFD399',
                    300: '#FFC273',
                    400: '#FFB14D',
                    500: '#F5A623',
                    600: '#CC871D',
                    700: '#A36817',
                    800: '#7A4A11',
                    900: '#522C0B',
                    solidBg: 'var(--joy-palette-warning-500, #F5A623)',
                    solidHoverBg: 'var(--joy-palette-warning-600, #CC871D)',
                    solidActiveBg: 'var(--joy-palette-warning-700, #A36817)',
                },
                common: {
                    black: '#182123',
                    white: '#FFF',
                    red: '#FF0000',
                },
                text: {
                    primary: 'var(--joy-palette-primary-800, #7B2329)',
                    icon: 'var(--joy-palette-primary-800, #7B2329)',
                    secondary: 'var(--joy-palette-neutral-700, #3F4F56)',
                    tertiary: 'var(--joy-palette-neutral-400, #A5AEB1)',
                    cocoa: 'var(--joy-palette-primary-900, #502131)',
                    success: 'var(---joy-palette-success-800, #0FA55F)',
                },
                background: {
                    body: 'var(--joy-palette-common-white, #FFF)',
                    cocoa: 'var(--joy-palette-primary-900, #502131)',
                    surface: 'var(--joy-palette-linen-50, #FEFBF8)',
                    level1: 'var(--joy-palette-linen-100, #FDF7F1)',
                    level2: 'var(--joy-palette-linen-200, #FBF3EA)',
                    level3: 'var(--joy-palette-linen-300, #F6EBDF)',
                    perfume: 'var(--joy-palette-tertiary-500, #C9B2F8)',
                    perfumeSlider: '#D8BEFF', // TODO: change to perfume after animation fixing
                    mint: 'var(--joy-palette-success-200, #C1EAD4)',
                    sienna: 'var(--joy-palette-secondary-500, #E7604D)',
                    backdrop: 'rgba(241, 244, 246, 0.1)',
                },
                roleColor: {
                    admin: 'var(--joy-palette-secondary-500, #E7604D)',
                    tech: 'var(--joy-palette-secondary-500, #E7604D)',
                    care: 'var(--joy-palette-tertiary-500, #C9B2F8)',
                    portage: 'var(--joy-palette-success-200, #C1EAD4)',
                },
                divider: 'rgba(var(--joy-palette-neutral-mainChannel, 99 107 115) / 0.15)',
            },
        },
        dark: {
            mode: 'dark',
            palette: {
                // TODO
            },
        },
    },
    fontFamily: {
        body: '"Avantt", var(--joy-fontFamily-fallback, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol")',
        display: '"Avantt", var(--joy-fontFamily-fallback, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol")',
        code: 'Source Code Pro,ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace',
        fallback: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
    fontSize: {
        xs: '0.75rem', // 12px
        sm: '0.875rem', // 14px
        md: '1rem', // 16px
        lg: '1.125rem', // 18px
        xl: '1.25rem', // 20px
        xxl: '1.5rem', // 24px
        xl2: '1.5rem', // 24px
        xxxl: '1.875rem', // 30px
        xl3: '1.875rem', // 30px
        xl4: '2.25rem', // 36px
        xl5: '3rem', // 48px
    },
    fontWeight: {
        sm: 300,
        md: 500,
        lg: 600,
        xl: 700,
    },
    lineHeight: {
        xs: '1.33334',
        sm: '1.42858',
        md: '1.5',
        lg: '1.55556',
        xl: '1.66667',
    },
    radius: {
        xs: '2px',
        sm: '6px',
        md: '8px',
        lg: '12px',
        xl: '16px',
        xxl: '32px',
        xl2: '32px',
    },
    typography: {
        'h1': {
            textTransform: 'uppercase',
            letterSpacing: '-0.72px',
            fontWeight: 700,
            lineHeight: '100%',
            fontStyle: 'italic',
        },
        'h2': {
            'lineHeight': '133%',
            'fontWeight': 700,
        },
        'h3': {
            lineHeight: '150%',
            fontWeight: 700,
            letterSpacing: 'normal',
        },
        'h4': {
            lineHeight: '155%',
            fontWeight: 600,
        },
        'title-lg': {
            textTransform: 'uppercase',
            lineHeight: '166%',
            fontWeight: 700,
            letterSpacing: '0.54px',
            fontStyle: 'italic',
        },
        'title-md': {
            textTransform: 'uppercase',
            lineHeight: '150%',
            fontWeight: 700,
            letterSpacing: '0.48px',
            fontStyle: 'italic',
        },
        'title-sm': {
            textTransform: 'uppercase',
            lineHeight: '142%',
            fontWeight: 700,
            letterSpacing: '0.42px',
            fontStyle: 'italic',
        },
        'body-lg': {
            color: 'var(--joy-palette-text-primary, #7B2329)',
            lineHeight: '155%',
            fontWeight: 500,
        },
        'body-md': {
            color: 'var(--joy-palette-text-primary, #7B2329)',
            lineHeight: '150%',
            fontWeight: 500,
        },
        'body-sm': {
            color: 'var(--joy-palette-text-primary, #7B2329)',
            lineHeight: '142%',
            fontWeight: 500,
        },
        'body-xs': {
            color: 'var(--joy-palette-text-primary, #7B2329)',
            lineHeight: '166%',
            fontWeight: 600,
            fontStyle: 'normal',
            textTransform: 'none',
        },
    },
    shadow: {
        xs: 'var(--joy-shadowRing, 0 0 #000), 0px 1px 2px 0px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08))',
        sm: 'var(--joy-shadowRing, 0 0 #000), 0px 1px 2px 0px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08)), 0px 2px 4px 0px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08))',
        md: 'var(--joy-shadowRing, 0 0 #000), 0px 2px 8px -2px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08)), 0px 6px 12px -2px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08))',
        lg: 'var(--joy-shadowRing, 0 0 #000), 0px 2px 8px -2px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08)), 0px 12px 16px -4px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08))',
        xl: 'var(--joy-shadowRing, 0 0 #000), 0px 2px 8px -2px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08)), 0px 20px 24px -4px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08))',
    },
    breakpoints: {
        values: {
            mobile: 0,
            desktop: 960,
            wide: 1280,
        },
    },
    zIndex: {
        badge: 1,
        table: 10,
        componentStickyHeader: 100,
        pageStickyHeader: 200,
        popup: 1000,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500,
    },
    components: {
        JoySelect: {
            styleOverrides: {
                root: ({ownerState}) => ({
                    ...(ownerState.size === 'sm' && {
                        height: '32px',
                    }),
                }),
            },
        },
        JoyButton: {
            defaultProps: {
                color: 'primary',
                variant: 'solid',
            },
            styleOverrides: {
                root: ({ownerState}) => ({
                    'textTransform': 'uppercase',
                    'fontStyle': 'italic',
                    '--Button-radius': 'var(--joy-radius-xxl, 32px)',
                    ...(ownerState.size === 'lg' && {
                        padding: '16px 40px',
                        height: '48px',
                        fontWeight: 700,
                        letterSpacing: '0.48px',
                    }),
                    ...(ownerState.size === 'md' && {
                        padding: '16px 32px',
                        height: '46px',
                        fontWeight: 700,
                        letterSpacing: '0.42px',
                    }),
                    ...(ownerState.size === 'sm' && {
                        padding: '2px 12px',
                        height: '32px',
                        fontWeight: 700,
                        letterSpacing: '0.42px',
                    }),
                }),
            },
        },
    },
});

export default JoyUiTheme;
