import LocationOnIcon from '@mui/icons-material/LocationOn';
import {Grid, Typography} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import parse from 'autosuggest-highlight/parse';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import useAddressPrediction from './addressPredictions';
import poweredByGoogle from '../../v1/assets/images/powered-by-google.png';


const getOptionLabel = option => (typeof option === 'string' ? option : option.description);
const filterOptions = option => {
    return option;
};

const FullAddressAutocomplete = ({
    address,
    onAddressChange,
    onPlaceDetailsChange,
    renderInput,
    componentRestrictions,
    ...rest
}) => {
    const {t} = useTranslation('setup');

    const [autocompleteAddress, setAutocompleteAddress] = useState('');

    const handleAutocompleteAddressChange = useCallback((event, value) => {
        setAutocompleteAddress(typeof value !== 'string' ? value.id : value);
    }, []);

    const {
        isLoading: isLoadingPredictions,
        predictions,
        getPlaceDetails,
    } = useAddressPrediction(autocompleteAddress, componentRestrictions);

    const handleAddressChange = useCallback((event, value) => {
        if (!value || !value.place_id) {
            return;
        }

        getPlaceDetails(value.place_id, onPlaceDetailsChange);

        onAddressChange(typeof value !== 'string' ? value.description : value);
    }, [getPlaceDetails, onAddressChange, onPlaceDetailsChange]);

    return (
        <Autocomplete
            id="address"
            onClose={(e, reason) => {
                if (reason === 'blur' || reason === 'escape' || reason === 'toggleInput') {
                    onAddressChange({formattedAddress: rest.defaultValue});
                }
            }}
            options={predictions}
            filterOptions={filterOptions}
            getOptionLabel={getOptionLabel}
            value={address}
            onChange={handleAddressChange}
            inputValue={autocompleteAddress}
            onInputChange={handleAutocompleteAddressChange}
            loading={isLoadingPredictions}
            noOptionsText={autocompleteAddress ? t('validations.addressNotFound') : t('validations.startTypingYourAddress')}
            isOptionEqualToValue={(option, selectedOption) => {
                if (typeof selectedOption === 'string') {
                    return option.description === selectedOption;
                }

                return option.place_id === selectedOption?.placeId;
            }}
            renderOption={(props, option) => {
                const matches = option.structured_formatting.main_text_matched_substrings ?? [];
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map(match => [match.offset, match.offset + match.length]),
                );

                // Not sure if option.structured_formatting will be available always
                // so leaving old system as backup bellow
                if (option?.structured_formatting?.secondary_text) {
                    return (
                        <li {...props} key={option.place_id}>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <Box
                                        component={LocationOnIcon}
                                        sx={{color: 'text.secondary', mr: 2}}
                                    />
                                </Grid>

                                <Grid item xs>
                                    {parts.map((part, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                fontWeight: part.highlight ? 700 : 400,
                                            }}
                                        >
                                            {part.text}
                                        </span>
                                    ))}

                                    <Typography variant="body2" color="text.secondary">
                                        {option?.structured_formatting.secondary_text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </li>
                    );
                }

                return (
                    <li {...props} key={option.place_id}>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Box
                                    component={LocationOnIcon}
                                    sx={{color: 'text.secondary', mr: 2}}
                                />
                            </Grid>

                            <Grid item xs>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            fontWeight: part.highlight ? 700 : 400,
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ))}

                                <Typography variant="body2" color="text.secondary">
                                    {option.terms[1].value}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
            renderInput={params => (
                <>
                    {renderInput(params)}
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 0.5}}>
                        <img src={poweredByGoogle} alt={t('poweredByGoogle')} />
                    </Box>
                </>
            )}
            {...rest}
        />
    );
};

FullAddressAutocomplete.propTypes = {
    onPlaceDetailsChange: PropTypes.func.isRequired,
    onAddressChange: PropTypes.func.isRequired,
    renderInput: PropTypes.func.isRequired,
    address: PropTypes.string.isRequired,
    componentRestrictions: PropTypes.object,
};

FullAddressAutocomplete.defaultProps = {
    componentRestrictions: {country: 'fr'},
};

export default FullAddressAutocomplete;
