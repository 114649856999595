import {faEye} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box} from '@mui/joy';
import Typography from '@mui/joy/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import JoyUIThemeProvider from '../joyui-theme-provider/JoyUiThemeProvider';

export const Section = ({title, icon, children, sx, bodyStyles}) => {
    return (
        <JoyUIThemeProvider>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 'xl',
                border: '1px solid var(--joy-palette-neutral-outlinedBorder)',
                overflow: 'hidden',
                ...sx,
            }}
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderTopLeftRadius: 'var(--joy-radius-xl)',
                    borderTopRightRadius: 'var(--joy-radius-xl)',
                    backgroundColor: 'common.black',
                    borderBottom: '1px solid var(--joy-palette-neutral-outlinedBorder)',
                    py: 1,
                    px: 2,
                    gap: 1,
                }}
                >
                    <Box color="neutral.solidColor" fontSize="md">
                        <FontAwesomeIcon icon={icon} />
                    </Box>
                    <Typography level="title-md" textColor="neutral.solidColor">
                        {title}
                    </Typography>
                </Box>
                <Box sx={{
                    backgroundColor: 'background.body',
                    p: 2,
                    ...bodyStyles,
                }}
                >
                    {children}
                </Box>
            </Box>
        </JoyUIThemeProvider>
    );
};

Section.propTypes = {
    title: PropTypes.node.isRequired,
    icon: PropTypes.any,
    sx: PropTypes.object,
    bodyStyles: PropTypes.object,
};

Section.defaultProps = {
    icon: faEye,
    sx: {},
    bodyStyles: {},
};

export default Section;
