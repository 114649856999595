import {
    faCheckToSlot, faCircleCheck,
    faCircleXmark,
    faClock,
    faEnvelopeCircleCheck,
    faHourglassHalf,
} from '@fortawesome/free-solid-svg-icons';

export const VatDeclarationStatus = {
    TODO: 'todo',
    SUBMITTED: 'submitted',
    PENDING_VERIFICATION: 'pendingVerification',
    OVERDUE: 'overdue',
    REFUSED: 'refused',
    VALIDATED: 'validated',
};

export const VatDeclarationStatusIcons = {
    [VatDeclarationStatus.TODO]: faHourglassHalf,
    [VatDeclarationStatus.SUBMITTED]: faEnvelopeCircleCheck,
    [VatDeclarationStatus.PENDING_VERIFICATION]: faCheckToSlot,
    [VatDeclarationStatus.OVERDUE]: faClock,
    [VatDeclarationStatus.REFUSED]: faCircleXmark,
    [VatDeclarationStatus.VALIDATED]: faCircleCheck,
};
