import {Alert} from '@mui/material';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

const WrappedAlert = props => {
    const {t} = useTranslation('notifications');

    return (
        <Alert variant={props.variant} onClose={props.closeToast} severity={props.severity}>
            {t(props.translationKey, props.translationValues)}
        </Alert>
    );
};

WrappedAlert.propTypes = {
    variant: PropTypes.oneOf(['filled', 'standard', 'outlined']).isRequired,
    closeToast: PropTypes.func.isRequired,
    severity: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
    translationKey: PropTypes.string.isRequired,
    translationValues: PropTypes.object,
};

WrappedAlert.defaultProps = {
    translationValues: {},
};

export const Toast = {
    success: (translationKey, {variant = 'filled', translationValues = {}} = {}, toastProps = {}) => {
        return toast(
            <WrappedAlert
                translationKey={translationKey}
                translationValues={translationValues}
                variant={variant}
                severity="success"
                closeToast={() => {}}
            />,
            toastProps,
        );
    },
    error: (translationKey, {variant = 'filled', translationValues = {}} = {}, toastProps = {}) => {
        return toast(
            <WrappedAlert
                translationKey={translationKey}
                variant={variant}
                translationValues={translationValues}
                severity="error"
                closeToast={() => {}}
            />,
            toastProps,
        );
    },
    warning: (translationKey, {variant = 'filled'} = {}, toastProps = {}) => {
        return toast(
            <WrappedAlert
                translationKey={translationKey}
                variant={variant}
                severity="warning"
                closeToast={() => {}}
            />,
            toastProps,
        );
    },
    info: (translationKey, {variant = 'filled'} = {}, toastProps = {}) => {
        return toast(
            <WrappedAlert
                translationKey={translationKey}
                variant={variant}
                severity="info"
                closeToast={() => {}}
            />,
            toastProps,
        );
    },
};
