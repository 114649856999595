import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Chip} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {ConditionalTooltip} from '../../../../../../../components/tooltip/ConditionalTooltip';
import {TransactionSide} from '../../../util/constants';
import {AccountBalanceHelperFunctions} from '../../../util/functions';
import {createIconFromName} from '../../../util/icon';

const Category = ({
    side,
    category,
    sourceOfRevenue,
    language,
    selectCategoriesObject,
    selectSourcesObject,
    isEditable,
}) => {
    const ref = useRef();

    if (!isEditable) {
        return (
            <Chip
                sx={{
                    'backgroundColor': 'v2.light.info.shades4',
                    'color': '#000',
                }}
                label="–"
            />
        );
    }

    // TODO
    const onCategorizeClick = () => {};

    if ((!category || category?.length === 0) && !sourceOfRevenue) {
        return (
            <Chip
                sx={{
                    'fontWeight': 500,
                    'fontSize': theme => theme.typography.pxToRem(12),
                    '&:hover': {
                        'backgroundColor': 'v2.light.secondary.main',
                        'color': '#fff',
                        'border': '1 px solid #fff',
                        'borderColor': 'v2.light.primary.shades30',
                        'cursor': 'pointer',
                        '.MuiSvgIcon-root': {
                            color: 'white !important',
                        },
                    },
                }}
                label="Catégoriser l’opération"
                variant="outlined"
                color="primary"
                deleteIcon={<ArrowForwardIcon />}
                onDelete={onCategorizeClick}
            />

        );
    }


    const translated = side === TransactionSide.CREDIT
        ? selectSourcesObject[sourceOfRevenue]?.[language] ?? sourceOfRevenue
        : category.map(item => {
            return selectCategoriesObject[item]?.[language] ?? item;
        }).join(', ');

    const hasTooltip = ref?.current?.offsetWidth < ref?.current?.scrollWidth;
    const {iconName} = AccountBalanceHelperFunctions.getColorAndIconName({
        selectCategoriesObject,
        selectSourcesObject,
        category,
        sourceOfRevenue,
    });

    return (
        <ConditionalTooltip tooltip={hasTooltip ? translated : null}>
            <Chip
                sx={{
                    'maxWidth': '200px',
                    'textWrap': 'nowrap',
                    'textOverflow': 'ellipsis',
                    'overflow': 'hidden',
                    'backgroundColor': 'v2.light.info.shades4',
                    'fontSize': theme => theme.typography.pxToRem(12),
                    'color': 'black',
                    '&:hover': {
                        backgroundColor: 'v2.light.warning.shades12',
                        cursor: 'pointer',
                    },
                    '.MuiSvgIcon-root': {
                        color: 'text_v2.secondary',
                    },
                }}
                label={translated}
                avatar={createIconFromName(iconName)}
            />
        </ConditionalTooltip>
    );
};

Category.propTypes = {
    side: PropTypes.string.isRequired,
    category: PropTypes.array.isRequired,
    sourceOfRevenue: PropTypes.string,
    language: PropTypes.string.isRequired,
    selectCategoriesObject: PropTypes.object.isRequired,
    selectSourcesObject: PropTypes.object.isRequired,
    isEditable: PropTypes.bool.isRequired,
};

Category.defaultProps = {
    sourceOfRevenue: '',
};

export default Category;
