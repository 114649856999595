import InfoIcon from '@mui/icons-material/Info';
import ListAltIcon from '@mui/icons-material/ListAlt';
import {LoadingButton} from '@mui/lab';
import {Alert, Typography, useMediaQuery} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {LoadingTypes, useLoadingState} from '../../../loading';
import {UiSelectors} from '../../../ui/store/ui.selector';
import {ModalsKeys} from '../../../ui/utils/constants';
import {BANK_TYPES} from '../../modules/bridge-api/utils/constants';
import {BankSelector} from '../../store/bank.selector';

const modalKey = ModalsKeys.CONFIRM_HIWAY_DELETE_MODAL;

const DeleteHiwayIntegrationModal = () => {
    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const data = useSelector(UiSelectors.createModalDataSelector(modalKey));
    const isDeleting = useLoadingState(LoadingTypes.DELETE_BANK);

    const integrations = useSelector(BankSelector.selectIntegrations);
    const archivedIntegrations = useSelector(BankSelector.selectIntegrationsWithArchived);

    return (
        <ModalWrapper
            modalKey={modalKey}
            title={(
                <Typography variant="h4">
                    {t('accountArchiving.deleteSuccessTitle')}
                </Typography>
            )}
            buttons={({handleClose}) => (
                <>
                    <LoadingButton
                        disableElevation
                        loading={isDeleting}
                        disabled={archivedIntegrations?.length === 0}
                        fullWidth={isMobileSize}
                        startIcon={<ListAltIcon />}
                        onClick={() => {
                            data?.setArchiveActive();
                            handleClose();
                        }}
                        variant="outlined"
                        color="primary"
                    >
                        {t('accountArchiving.deleteSuccessArchive')}
                    </LoadingButton>

                    <LoadingButton
                        loading={isDeleting}
                        fullWidth={isMobileSize}
                        loadingPosition="start"
                        onClick={() => {
                            const bridgeIntegration = integrations.find(
                                integration => integration?.type === BANK_TYPES.bridge,
                            );

                            // Set default tab value
                            if (bridgeIntegration?.id) {
                                data.setTabValue(bridgeIntegration.id);
                            } else if (archivedIntegrations?.length > 0) {
                                data.setTabValue(BANK_TYPES.archived);
                            }

                            handleClose();
                        }}
                        color="secondary"
                        variant="contained"
                    >
                        {t('accountArchiving.deleteSuccessConfirm')}
                    </LoadingButton>
                </>
            )}
        >
            <Typography sx={{mb: 1}}>
                {t('accountArchiving.deleteSuccessText')}
            </Typography>

            {data?.isArchive && (
                <Alert
                    color="info"
                    severity="info"
                    sx={{
                        'marginTop': '8px',
                        'borderRadius': '12px',
                        '.MuiAlert-icon': {
                            display: 'flex',
                            alignItems: 'center',
                        },
                    }}
                    icon={(<InfoIcon sx={{fontSize: 'inherit', color: 'v2.light.info.main'}} />)}
                >
                    {t('accountArchiving.deleteSuccessInfo')}
                </Alert>
            )}
        </ModalWrapper>
    );
};

export default DeleteHiwayIntegrationModal;
