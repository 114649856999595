import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import WarningIcon from '@mui/icons-material/Warning';
import {TabContext, TabList} from '@mui/lab';
import {
    Box, Button, Chip, IconButton, Tab,
    Typography, styled, useMediaQuery,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import MobileTabs from './MobileTabs';
import {ConditionalTooltip} from '../../../../../../components/tooltip/ConditionalTooltip';
import {USER_ROLES} from '../../../../../../utils/user-roles';
import {selectUser} from '../../../../../../v1/app/user/user.selectors';
import {LoggedInUserSelectors} from '../../../../../user/modules/logged-in-user';
import {BankActions} from '../../../../store/bank.action';
import {BridgeActions} from '../../../bridge-api/store/bridge-api.action';
import {BANK_TYPES, BankIntegrationStatus} from '../../../bridge-api/utils/constants';
import {TransactionsActions} from '../../store/transactions.action';

const borderStyles = palette => ({
    borderWidth: '1px',
    borderRadius: '8px 8px 0px 0px',
    borderColor: palette.v2.blueGray[100],
    borderStyle: 'solid',
    borderBottom: 'none',
});

const StyledTabList = styled(TabList)(() => ({
    '.MuiTabs-indicator': {
        display: 'none',
    },
    '.MuiTabs-flexContainer': {
        gap: '8px',
    },
    '.MuiTabs-scrollButtons': {
        'backgroundColor': '#fff',
        '&:first-of-type': {
            borderTopLeftRadius: '8px',
        },
        '&:last-of-type': {
            borderTopRightRadius: '8px',
        },
    },
}));

const StyledTab = styled(Tab)(({theme}) => ({
    'position': 'relative',
    'padding': '8px 16px',
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'flex-start',
    ...(borderStyles(theme.palette)),
    '&:not(:first-of-type)': borderStyles(theme.palette),
    '&:first-of-type': borderStyles(theme.palette),
    '&.Mui-selected': {
        'backgroundColor': '#fff',
        'border': 'none',
        'padding': '9px 17px',
    },
}));

const TransactionListTabs = ({
    tabValue,
    handleTabChange,
    bankNumber,
    fullBankAccountList,
    hasHiwayPro,
    combinedTotal,
    combinedUncategorized,
    setHasBankSidebarOpen,
    hasBankSidebarOpen,
    isAdminInterface,
    hasArchived,
    isTabValueAuto,
    children,
}) => {
    const dispatch = useDispatch();
    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const isAdmin = loggedInUser.role === USER_ROLES.ADMINISTRATOR;

    const [isMobileTabsOpen, setIsMobileTabsOpen] = useState(false);

    const user = useSelector(selectUser);

    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const hasBridgeAccess = loggedInUser.hasBridgeAccess;

    const onClickAdd = () => {
        if (hasBridgeAccess) {
            dispatch(BridgeActions.connectToBridge());
        }
    };

    const onClickHiwayAdd = () => {
        dispatch(BankActions.createBankAccount());
    };

    const onSettingsClick = account => {
        setHasBankSidebarOpen(account);
    };

    const getMobileTabsData = tabValue => {
        if (tabValue === BANK_TYPES.combined) {
            return {
                label: t('creationProPage.combined'),
                unategorizedCount: combinedUncategorized,
            };
        } else if (tabValue === BANK_TYPES.archived) {
            return {
                label: t('creationProPage.archived'),
                unategorizedCount: 0,
            };
        }

        const account = fullBankAccountList.find(({id}) => id === tabValue) ?? {};
        return {
            label: account.name,
            unategorizedCount: account.uncategorized ?? 0,
        };
    };
    const mobileTabsData = getMobileTabsData(tabValue);

    const ExpandIcon = isMobileTabsOpen ? ExpandLessIcon : ExpandMoreIcon;

    const handleChangeTab = (_, value) => {
        if (tabValue === value) {
            setIsMobileTabsOpen(false);
            return;
        }

        setIsMobileTabsOpen(false);
        // Clean transactions before switching tabs
        dispatch(TransactionsActions.storeTransactionList({
            count: 0,
            maxItems: 0,
            nonCategorizedCount: 0,
            ids: [],
            transactionsObject: {},
        }));

        handleTabChange(value);
        setHasBankSidebarOpen(false);
        if (!isAdminInterface && !isTabValueAuto) {
            dispatch(BankActions.getUserIntegrationData(user?.id ?? loggedInUser.id));
        }
    };

    const hasCombined = bankNumber > 1;
    const hasHiwayAdd = !hasHiwayPro && !isAdmin;
    const hasAdd = !isAdmin
        && ((hasHiwayPro && bankNumber < 5) || (!hasHiwayPro && bankNumber < 4))
        && hasBridgeAccess;

    return (
        <TabContext value={tabValue}>
            <Box sx={{
                p: isMobileSize ? 2 : 0,
                pb: 0,
                display: 'flex',
                flexDirection: 'column',
            }}
            >
                {isMobileSize ? (
                    <>
                        <Box
                            sx={{
                                mb: 1,
                                py: 1,
                                px: 2,
                                borderRadius: '4px',
                                backgroundColor: 'v2.light.primary.shades8',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setIsMobileTabsOpen(true);
                            }}
                        >
                            <Typography sx={{
                                color: 'text_v2.secondary',
                                fontSize: '14px',
                                fontWeight: '500',
                                lineHeight: '18px',
                                letterSpacing: '0.4px',
                                textTransform: 'uppercase',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5,
                            }}
                            >
                                {mobileTabsData.label}
                                {mobileTabsData.unategorizedCount !== 0 && (
                                    <Chip
                                        size="small"
                                        label={mobileTabsData.unategorizedCount}
                                        color="primary"
                                    />
                                )}
                            </Typography>
                            <IconButton size="small">
                                <ExpandIcon sx={{color: 'text_v2.secondary'}} />
                            </IconButton>
                        </Box>
                        <MobileTabs
                            isOpen={isMobileTabsOpen}
                            setIsOpen={setIsMobileTabsOpen}
                            fullBankAccountList={fullBankAccountList}
                            onChangeTab={handleChangeTab}
                            hasCombined={hasCombined}
                            combinedUncategorized={combinedUncategorized}
                            combinedTotal={combinedTotal}
                            hasHiwayAdd={hasHiwayAdd}
                            onClickHiwayAdd={onClickHiwayAdd}
                            hasAdd={hasAdd}
                            hasArchived={hasArchived}
                            onClickAdd={onClickAdd}
                        />
                    </>
                ) : (
                    <StyledTabList
                        value={tabValue}
                        onChange={handleChangeTab}
                        variant="scrollable"
                        allowScrollButtonsMobile
                        sx={{mb: -1, pb: 1}}
                    >
                        {hasCombined && (
                            <StyledTab
                                value={BANK_TYPES.combined}
                                label={(
                                    <>
                                        <TabTitle>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 0.5,
                                            }}
                                            >
                                                {t('creationProPage.combined')}

                                                {combinedUncategorized !== 0 ? (
                                                    <Chip
                                                        size="small"
                                                        label={combinedUncategorized}
                                                        color="primary"
                                                    />
                                                ) : (
                                                    <CheckCircleOutlineRoundedIcon
                                                        htmlColor="#4CAF50"
                                                        sx={{fontSize: 16}}
                                                    />
                                                )}
                                            </Box>
                                        </TabTitle>
                                        <TabSubTitle>{combinedTotal}</TabSubTitle>
                                        {tabValue === BANK_TYPES.combined && (
                                            <TabIndicator />
                                        )}
                                    </>
                                )}
                            />
                        )}

                        {fullBankAccountList.map(account => {
                            const hasError = account?.status === BankIntegrationStatus.COMPANY_VALIDATION_FORM_REQUIRED
                                || account?.status === BankIntegrationStatus.MULTI_FACTOR_AUTHENTICATION_REQUIRED
                                || account?.status === BankIntegrationStatus.USER_ACTION_REQUIRED
                                || account?.status === BankIntegrationStatus.ERROR
                                || account?.status === BankIntegrationStatus.IN_PROGRESS;

                            return (
                                <StyledTab
                                    key={account.id}
                                    value={account.id}
                                    label={(
                                        <>
                                            <TabTitle>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 0.5,
                                                    }}
                                                >
                                                    {hasError && (
                                                        <WarningIcon sx={{
                                                            color: 'warning_v2.main',
                                                        }}
                                                        />
                                                    )}

                                                    {account.name}

                                                    {tabValue === account.id && (
                                                        <IconButton
                                                            sx={hasBankSidebarOpen
                                                                ? {backgroundColor: 'v2.light.primary.shades30', ml: -0.25}
                                                                : {ml: -0.5}
                                                            }
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                if (tabValue === account.id) {
                                                                    onSettingsClick(account);
                                                                }
                                                            }}
                                                        >
                                                            <SettingsIcon
                                                                htmlColor={hasBankSidebarOpen ? '#3779BE' : '#757575'}
                                                                sx={{fontSize: 20}}
                                                            />
                                                        </IconButton>
                                                    )}
                                                    {tabValue !== account.id && account.uncategorized !== 0 && (
                                                        <Chip
                                                            size="small"
                                                            label={account.uncategorized}
                                                            color="primary"
                                                        />
                                                    )}
                                                    {tabValue !== account.id && account.uncategorized === 0 && (
                                                        <CheckCircleOutlineRoundedIcon
                                                            htmlColor="#4CAF50"
                                                            sx={{fontSize: 16}}
                                                        />
                                                    )}
                                                </Box>
                                            </TabTitle>
                                            {tabValue !== account.id ? (
                                                <TabSubTitle>{account.amount}</TabSubTitle>
                                            ) : (
                                                <TabIndicator
                                                    color={account?.color}
                                                />
                                            )}
                                        </>
                                    )}
                                />
                            );
                        })}

                        {hasArchived && (
                            <StyledTab
                                value={BANK_TYPES.archived}
                                label={(
                                    <>
                                        <TabTitle>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 0.5,
                                            }}
                                            >
                                                {t('creationProPage.archived')}

                                                <ConditionalTooltip
                                                    tooltip={t('accountArchiving.tabSubtitle')}
                                                >
                                                    <InfoOutlinedIcon sx={{
                                                        color: 'v2.blueGray.300',
                                                    }}
                                                    />
                                                </ConditionalTooltip>
                                            </Box>
                                        </TabTitle>
                                        {tabValue === BANK_TYPES.archived && (
                                            <TabIndicator />
                                        )}
                                    </>
                                )}
                            />
                        )}

                        {hasHiwayAdd && (
                            <Box sx={{
                                border: 'none !important',
                                padding: '8px !important',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            >
                                <Button
                                    onClick={onClickHiwayAdd}
                                    color="secondary"
                                    startIcon={<AddIcon />}
                                    variant="text"
                                    sx={{
                                        alignSelf: 'flex-start',
                                    }}
                                >
                                    {t(`creationProPage.addHiwayPro`)}
                                    <Chip
                                        sx={{
                                            textTransform: 'none',
                                            fontSize: '10px',
                                            lineHeight: '10px',
                                            ml: 1,
                                        }}
                                        size="small"
                                        label={t('connectProPage.recommended')}
                                        color="secondary"
                                    />
                                </Button>
                            </Box>
                        )}

                        {hasAdd && (
                            <Box sx={{
                                border: 'none !important',
                                padding: '8px !important',
                            }}
                            >
                                <Button
                                    onClick={onClickAdd}
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    variant="text"
                                    sx={{alignSelf: 'flex-start'}}
                                >
                                    {t(`creationProPage.addButtonTitle`)}
                                </Button>
                            </Box>
                        )}
                    </StyledTabList>
                )}

                {children}
            </Box>
        </TabContext>
    );
};

TransactionListTabs.propTypes = {
    tabValue: PropTypes.string,
    handleTabChange: PropTypes.func.isRequired,
    bankNumber: PropTypes.number.isRequired,
    combinedTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    combinedUncategorized: PropTypes.number.isRequired,
    fullBankAccountList: PropTypes.arrayOf(PropTypes.object).isRequired,
    // totalOverview: PropTypes.number.isRequired,
    hasHiwayPro: PropTypes.bool,
    setHasBankSidebarOpen: PropTypes.func.isRequired,
    hasBankSidebarOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
    isAdminInterface: PropTypes.bool.isRequired,
    hasArchived: PropTypes.bool,
    isTabValueAuto: PropTypes.bool.isRequired,
};

TransactionListTabs.defaultProps = {
    hasHiwayPro: false,
    tabValue: '',
    hasArchived: false,
};

const TabTitle = ({children}) => (
    <Typography
        sx={{
            textTransform: 'uppercase',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '18px',
            letterSpacing: '0.4px',
            color: 'text_v2.primary',
        }}
    >
        {children}
    </Typography>
);

const TabSubTitle = ({children}) => (
    <Typography
        sx={{
            fontWeight: '400',
            fontSize: '10px',
            lineHeight: '13px',
            letterSpacing: '0.4px',
            color: 'text_v2.primary',
            textTransform: 'none',
        }}
    >
        {children} €
    </Typography>
);

const TabIndicator = ({color}) => (
    <Box
        sx={{
            position: 'absolute',
            height: '3px',
            left: '16px',
            right: '16px',
            top: 0,
            backgroundColor: color ?? 'primary.main',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
        }}
    />
);

TabIndicator.propTypes = {
    color: PropTypes.string,
};

TabIndicator.defaultProps = {
    color: null,
};

export default TransactionListTabs;
