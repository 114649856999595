const BASE_COMPANY_PATH = '/freelancer/:freelancerId/companies/:companyId';

export const ROUTE_PATHS = {
    ACCESS_DENIED: '/access-denied', // D
    BASE_COMPANY_PATH: BASE_COMPANY_PATH, // D
    BUSINESS_KILOMETERS_ALLOWANCE: '/business-kilometers-allowance', // D
    COMPANIES: '/active-companies', // D
    COMPANY: `${BASE_COMPANY_PATH}`, // D
    COMPANY_WITH_TAB: `${BASE_COMPANY_PATH}/:tab/`, // D
    COMPANY_BACKOFFICE: `${BASE_COMPANY_PATH}/backoffice`, // D
    COMPANY_BUSINESS_KILOMETERS_ALLOWANCE: `${BASE_COMPANY_PATH}/business-kilometers-allowance`, // D
    COMPANY_DASHBOARD: `${BASE_COMPANY_PATH}/dashboard`, // D
    COMPANY_INVOICING: `${BASE_COMPANY_PATH}/invoicing`, // D
    COMPANY_PERSONAL_EXPENSES: `${BASE_COMPANY_PATH}/personal-expenses`, // D
    COMPANY_PROFESSIONAL_EXPENSES: `${BASE_COMPANY_PATH}/professional-expenses`, // D
    COMPANY_QUICKBOOKS: `${BASE_COMPANY_PATH}/quickbooks`, // D // TODO STUPAR: Do not remove this, should be renamed
    CREATE_USER: 'create', // D
    DASHBOARD: '/', // D
    DOCUMENTS: '/documents/:companyId', // D
    EMAIL_VERIFICATION: '/verify-email', // D
    GENERATE_DOCUMENTS: '/generate-documents/:companyId', // D
    HOME_OFFICE_TRAVEL: '/home-office-travel', // D
    INVOICING: '/invoicing', // D
    LOGIN: '/login', // D
    NEW_PASSWORD: '/new-password', // D
    NOT_FOUND: '/not-found', // D
    PERSONAL_EXPENSES: '/personal-expenses', // D
    PROFESSIONAL_EXPENSES: '/professional-expenses', // D
    PROFESSIONAL_TRAVEL: '/professional-travel', // D
    RESET_PASSWORD: '/reset-password', // D
    SETUP_COMPANY: '/setup-company/:companyId/:activeStep?', // D
    SIGN_CONTRACT: '/sign-contract', // D
    USER: '/users/:userId', // D
    USER_TAB: ':tab', // D
    EDIT_USER: ':tab/:isEdit', // D
    USERS: '/', // D
    SETTINGS: '/settings', // D
    CHANGE_PASSWORD: '/change-password', // D
    MY_COMPANIES: '/my-companies', // D
    MY_COMPANY: '/my-companies/:companyId', // D
    MY_PROFILE: '/profile', // D
    MY_PROFILE_EDIT: '/profile/edit', // D
    ADP: '/adp', // D
    CGVS: '/cgvs', // D
    COMPANY_DOCUMENTS: `${BASE_COMPANY_PATH}/documents`, // D
};
