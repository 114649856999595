import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import React from 'react';
import {Link as RouterLink} from 'react-router-dom';

const ReactRouterLink = React.forwardRef(({to, className, children, ...rest}, ref) => (
    <Link component={RouterLink} {...rest} to={to} className={className} ref={ref}>
        {children}
    </Link>
));

ReactRouterLink.propTypes = {
    to: PropTypes.string.isRequired,
    className: PropTypes.string,
};

ReactRouterLink.defaultProps = {
    className: '',
};

export default ReactRouterLink;
