import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import {LoadingButton} from '@mui/lab';
import {Alert, Button, Typography, useMediaQuery} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {LoadingTypes, useLoadingState} from '../../../loading';
import {UiSelectors} from '../../../ui/store/ui.selector';
import {ModalsKeys} from '../../../ui/utils/constants';
import {TransactionsSelector} from '../../modules/account-balance/store/transactions.selector';
import {getIntegrationInfo} from '../../modules/bridge-api/utils/bridge-util';
import {BankAccountStatus} from '../../modules/bridge-api/utils/constants';
import {BankActions} from '../../store/bank.action';

const modalKey = ModalsKeys.CLOSE_HIWAY_INTEGRATION_MODAL;

const CloseHiwayIntegrationModal = () => {
    const {t} = useTranslation('bank');
    const dispatch = useDispatch();
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const data = useSelector(UiSelectors.createModalDataSelector(modalKey));
    const isDeleting = useLoadingState(LoadingTypes.DELETE_BANK);
    const transactionIds = useSelector(TransactionsSelector.selectTransactionIds);

    const handleClick = () => {
        const integration = data?.integration;

        const {account} = getIntegrationInfo(integration);

        // If account is already closing or closed finish archiving/deleting
        if (
            account?.status === BankAccountStatus.CLOSED
            || account?.status === BankAccountStatus.CLOSING
        ) {
            // If there are transactions call for archiving, otherwise delete
            const shouldArchive = transactionIds?.length > 0 && parseFloat(account?.availableBalance) === 0;
            if (shouldArchive) {
                dispatch(BankActions.archiveIntegrationAccount({integration: integration}));
            } else {
                dispatch(BankActions.deleteIntegrationAccount({id: integration?.id}));
            }
        } else {
            dispatch(BankActions.closeBankAccount({integration: integration}));
        }
    };

    return (
        <ModalWrapper
            modalKey={modalKey}
            title={(
                <Typography variant="h4">
                    {t('accountArchiving.closeTitle')}
                </Typography>
            )}
            buttons={({handleClose}) => (
                <>
                    <Button
                        disableElevation
                        fullWidth={isMobileSize}
                        startIcon={<ArrowBackIcon />}
                        onClick={() => {
                            handleClose();
                        }}
                        variant="outlined"
                        color="primary"
                    >
                        {t('accountArchiving.backButton')}
                    </Button>

                    <LoadingButton
                        loading={isDeleting}
                        fullWidth={isMobileSize}
                        startIcon={<CancelIcon />}
                        loadingPosition="start"
                        onClick={handleClick}
                        color="secondary"
                        variant="contained"
                    >
                        {t('archivingActions.close')}
                    </LoadingButton>
                </>
            )}
        >
            <Typography sx={{mb: 1}}>
                {t('accountArchiving.closeInfoText1')}
            </Typography>

            <Typography sx={{mb: 3}}>
                {t('accountArchiving.closeInfoText2')}
            </Typography>

            <Alert
                color="info"
                severity="info"
                sx={{
                    'marginTop': '8px',
                    'borderRadius': '12px',
                    '.MuiAlert-icon': {
                        display: 'flex',
                        alignItems: 'center',
                    },
                }}
                icon={(<InfoIcon sx={{fontSize: 'inherit', color: 'v2.light.info.main'}} />)}
            >
                {t('accountArchiving.closeAlertText')}
            </Alert>
        </ModalWrapper>
    );
};

export default CloseHiwayIntegrationModal;
