import {Tooltip} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export const ConditionalTooltip = ({children, tooltip, placement, ...rest}) => {
    if (tooltip) {
        return (
            <Tooltip enterTouchDelay={0} title={tooltip} arrow placement={placement} {...rest}>
                {children}
            </Tooltip>
        );
    }

    return children;
};

ConditionalTooltip.propTypes = {
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    placement: PropTypes.oneOf([
        'bottom-end',
        'bottom-start',
        'bottom',
        'left-end',
        'left-start',
        'left',
        'right-end',
        'right-start',
        'right',
        'top-end',
        'top-start',
        'top',
    ]),
};

ConditionalTooltip.defaultProps = {
    tooltip: '',
    placement: 'top',
};
