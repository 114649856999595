import DateRangeIcon from '@mui/icons-material/DateRange';
import EditIcon from '@mui/icons-material/Edit';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SearchIcon from '@mui/icons-material/Search';
import {Badge, Box, Collapse, Paper, Skeleton, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {emptyFunction} from '../../util/constants';

const AccountBalanceSkeleton = () => {
    const {t} = useTranslation('bank');

    const tableSize = {
        firstSegment: '1 1 50%',
        secondSegment: '1 1 25%',
        thirdSegment: '36%',
        fourthSegment: '1 1 14%',
    };

    return (
        <Box>
            <Box sx={{
                mt: 1,
                display: 'flex',
                flexDirection: 'column',
            }}
            >
                <Paper sx={{pt: 2, px: 2, height: '90vh'}}>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>

                        <Box
                            sx={{display: 'flex', justifyContent: 'space-between'}}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    width: '100%',
                                }}
                            >

                                <Box sx={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                                >
                                    <Skeleton variant="rectangular" width={119} height={40} />
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize: theme => theme.typography.pxToRem(14),
                                        }}
                                    >
                                        <Box sx={{color: 'v2.light.text.disabled', mr: 2}}>
                                            {t('accountBalanceFilter.filter')}
                                        </Box>
                                        <Box>
                                            <Button
                                                id="dr"
                                                sx={{
                                                    height: '28px',
                                                }}
                                                startIcon={(
                                                    <Badge
                                                        color="secondary"
                                                        variant="dot"
                                                        invisible={true}
                                                        sx={{
                                                            '.MuiBadge-overlapRectangular': {
                                                                transform: 'scale(1) translate(-2px, 2px)',
                                                            },
                                                        }}
                                                    >
                                                        <DateRangeIcon sx={{color: 'rgba(25, 118, 210, 0.5)'}} />
                                                    </Badge>
                                                    )}
                                                onClick={emptyFunction}
                                            >
                                                <Typography sx={{
                                                    fontSize: theme => theme.typography.pxToRem(14),
                                                    color: 'text_v2.primary',
                                                    textTransform: 'capitalize',
                                                }}
                                                >
                                                    {t('accountBalanceFilter.dates')}
                                                </Typography>
                                            </Button>
                                        </Box>

                                        <Box>
                                            <Button
                                                id="supportingDocuments"
                                                sx={{
                                                    height: '28px',
                                                }}
                                                onClick={emptyFunction}
                                                startIcon={(
                                                    <Badge
                                                        color="secondary"
                                                        variant="dot"
                                                        invisible={true}
                                                        sx={{
                                                            '.MuiBadge-overlapRectangular': {
                                                                transform: 'scale(1) translate(-2px, 2px)',
                                                            },
                                                        }}
                                                    >
                                                        <EditIcon sx={{color: 'rgba(25, 118, 210, 0.5)'}} />
                                                    </Badge>
                                                    )}
                                            >
                                                <Typography sx={{
                                                    fontSize: theme => theme.typography.pxToRem(14),
                                                    color: 'text_v2.primary',
                                                    textTransform: 'capitalize',
                                                }}
                                                >
                                                    {t('accountBalanceFilter.documents')}
                                                </Typography>
                                            </Button>
                                        </Box>

                                        <Box>
                                            <Button
                                                id="type"
                                                sx={{
                                                    height: '28px',
                                                }}
                                                onClick={emptyFunction}
                                                startIcon={(
                                                    <Badge
                                                        color="secondary"
                                                        variant="dot"
                                                        invisible={true}
                                                        sx={{
                                                            '.MuiBadge-overlapRectangular': {
                                                                transform: 'scale(1) translate(-2px, 2px)',
                                                            },
                                                        }}
                                                    >
                                                        <ReceiptIcon sx={{color: 'rgba(25, 118, 210, 0.5)'}} />
                                                    </Badge>
                                                    )}
                                            >
                                                <Typography sx={{
                                                    fontSize: theme => theme.typography.pxToRem(14),
                                                    color: 'text_v2.primary',
                                                    textTransform: 'none',
                                                }}
                                                >
                                                    {t('accountBalanceFilter.types')}
                                                </Typography>
                                            </Button>
                                        </Box>
                                        <Box sx={{
                                            ml: 3,
                                        }}
                                        >
                                            <TextField
                                                size="small"
                                                defaultValue=""
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="outlined"
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{
                            'background': '#FAFAFA',
                            'mb': 0,
                            'mt': 2,
                            'height': '40px',
                        }}
                        >
                            <Box sx={{
                                fontWeight: 500,
                                fontSize: theme => theme.typography.pxToRem(14),
                                color: 'rgba(0, 0, 0, 0.38)',
                                alignItems: 'center',
                                display: 'flex',
                                px: 3,
                                height: '40px',
                            }}
                            >
                                <Box sx={{
                                    flex: tableSize.firstSegment,
                                }}
                                />

                                <Box sx={{
                                    flex: tableSize.secondSegment,
                                    transition: 'flex-basis 0.4s 0.1s ease-in-out',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                    mb: 1,
                                    justifyContent: 'flex-end',
                                }}
                                >
                                    {t('accountBalanceTableHeader.type')}
                                </Box>

                                <Collapse
                                    collapsedSize="0px"
                                    in={true}
                                    sx={{
                                        width: tableSize.thirdSegment,
                                        height: '40px',
                                        display: 'flex',
                                        mb: 1,
                                        transition: 'width 0.6s ease-in-out',
                                    }}
                                >
                                    <Box sx={{display: 'flex', width: '100%', height: '40px'}}>
                                        <Box sx={{
                                            flex: '1 1 60%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '100%',
                                            justifyContent: 'flex-end',
                                        }}
                                        >
                                            {t('accountBalanceTableHeader.category')}
                                        </Box>

                                        <Box sx={{
                                            flex: '1 1 40%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '100%',
                                            justifyContent: 'flex-end',
                                        }}
                                        >
                                            {t('accountBalanceTableHeader.vat')}
                                        </Box>
                                    </Box>
                                </Collapse>

                                <Box sx={{
                                    flex: tableSize.fourthSegment,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                    mb: 1,
                                    justifyContent: 'flex-end',
                                    textAlign: 'right',
                                }}
                                >
                                    {t('accountBalanceTableHeader.amount')}
                                </Box>
                            </Box>
                        </Box>

                        <Skeleton sx={{mt: 3}} variant="rectangular" width={80} height={16} />

                        <AccountBalanceSkeletonRow />
                        <AccountBalanceSkeletonRow />
                        <AccountBalanceSkeletonRow />

                        <Skeleton sx={{mt: 3}} variant="rectangular" width={80} height={16} />

                        <AccountBalanceSkeletonRow />
                        <AccountBalanceSkeletonRow />

                        <Skeleton sx={{mt: 3}} variant="rectangular" width={80} height={16} />

                        <AccountBalanceSkeletonRow />

                    </Box>
                </Paper>
            </Box>
        </Box>
    );
};

const AccountBalanceSkeletonRow = () => {
    return (
        <Box sx={{
            mt: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Skeleton sx={{mr: 2}} variant="rectangular" width={38} height={30} />

                <Skeleton sx={{mr: 2}} variant="circular" width={48} height={48} />

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
                >
                    <Skeleton sx={{mb: 1}} variant="rectangular" width={140} height={16} />
                    <Skeleton variant="rectangular" width={40} height={16} />
                </Box>
            </Box>

            <Skeleton sx={{mr: 2}} variant="rectangular" width={48} height={30} />

            <Skeleton sx={{mr: 2}} variant="rectangular" width={48} height={30} />

            <Skeleton sx={{pb: 1}} variant="rectangular" width={70} height={24} />
        </Box>
    );
};

export default AccountBalanceSkeleton;
