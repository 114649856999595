import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import {LoadingButton} from '@mui/lab';
import {Box, CircularProgress, Typography} from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ModalWrapper} from '../../../../../components/modal-wrapper/ModalWrapper';
import {useMemoizedCreateSelector} from '../../../../../hooks/use-memoized-create-selector';
import {push, selectRouterLocation} from '../../../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {ACCOUNT_STATUSES} from '../../../../company-profile/modules/accounting/utils/constants';
import {UiActions} from '../../../../ui/store/ui.action';
import {UiSelectors} from '../../../../ui/store/ui.selector';
import {ModalsKeys} from '../../../../ui/utils/constants';
import {BankActions} from '../../../store/bank.action';
import {BankSelector} from '../../../store/bank.selector';
import {TransactionsActions} from '../../account-balance/store/transactions.action';
import {HIWAY_PRO_NAME} from '../../account-balance/util/constants';
import {BridgeActions} from '../store/bridge-api.action';
import {getIntegrationInfo} from '../utils/bridge-util';
import {
    BANK_TYPES,
    BankAccountInitialTransactionSyncStatus,
    BankIntegrationStatus,
    MAX_BRIDGE_CONNECTIONS,
} from '../utils/constants';

const modalKey = ModalsKeys.PICK_ADDITIONAL_ACCOUNT_MODAL;
const PickAdditionalAccountModal = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation('bank');
    const isOpen = useMemoizedCreateSelector(
        UiSelectors.createIsModalActiveSelector, modalKey,
    );
    const data = useSelector(UiSelectors.createModalDataSelector(modalKey));
    const location = useSelector(selectRouterLocation);

    const [hasWaitLoader, setHasWaitLoader] = useState(false);

    const integrations = useSelector(BankSelector.selectIntegrations);
    const hasHiway = integrations.find(integration => integration.type === BANK_TYPES.hiway);

    const handleAddMore = () => {
        dispatch(BridgeActions.setChosenBankConnectionFlag());
        if (hasHiway) {
            dispatch(BridgeActions.connectToBridge());
        } else {
            dispatch(push(RoutePaths.BANK_PROMOTION));
        }
    };

    useEffect(() => {
        let syncInProgress = false;

        if (data?.integrationId) {
            const integration = integrations.find(integration => integration?.id === data.integrationId);

            const bankAccountHolder = integration?.bankAccountHolders.find(
                integration => integration?.type === 'COMPANY',
            );
            const bankAccount = bankAccountHolder?.bankAccounts?.[0];

            if (integration?.type !== BANK_TYPES.hiway
                && integration?.status !== BankIntegrationStatus.ERROR
                && (!bankAccount?.additionalData?.initialTransactionSyncStatus
                    || bankAccount?.additionalData?.initialTransactionSyncStatus
                    === BankAccountInitialTransactionSyncStatus.IN_PROGRESS
                )
            ) {
                syncInProgress = true;
            }
        }

        if (syncInProgress) {
            setHasWaitLoader(true);
        } else {
            setHasWaitLoader(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integrations]);

    if (!isOpen) {
        return null;
    }

    if (hasWaitLoader) {
        return (
            <WaitLoader />
        );
    }

    return (
        <ModalWrapper
            modalKey={modalKey}
            title={(
                <Typography variant="h5">
                    {t('bridgeAPI.pickAdditionalAccountModal.title')}
                </Typography>
            )}
            buttons={({handleClose}) => (
                <>
                    <Button
                        disabled={integrations?.length === MAX_BRIDGE_CONNECTIONS}
                        startIcon={<CloseIcon />}
                        fullWidth
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            handleAddMore();
                            dispatch(UiActions.setModalData(
                                ModalsKeys.PICK_ADDITIONAL_ACCOUNT_MODAL,
                                null,
                            ));
                            handleClose();
                        }}
                    >
                        {t('bridgeAPI.pickAdditionalAccountModal.cancel')}
                    </Button>

                    <LoadingButton
                        startIcon={<DoneIcon />}
                        fullWidth
                        color="error"
                        variant="contained"
                        onClick={() => {
                            dispatch(BridgeActions.setChosenBankConnectionFlag());
                            handleClose();

                            // TODO Add to selector
                            // Count integrations with account
                            let numberOfIntegrationsWithAccount = 0;
                            if (integrations && integrations?.length > 0) {
                                integrations.forEach(integration => {
                                    const {account} = getIntegrationInfo(integration);

                                    if (account?.id && account?.status === ACCOUNT_STATUSES.ACTIVE) {
                                        numberOfIntegrationsWithAccount += 1;
                                    }
                                });
                            }

                            if (numberOfIntegrationsWithAccount > 1) {
                                // If there are multiple integrations show modal for picking
                                dispatch(UiActions.setActiveModal(
                                    ModalsKeys.PICK_DEFAULT_BANK_MODAL,
                                    true,
                                ));
                            } else {
                                // If there is only single integration make it default
                                if (numberOfIntegrationsWithAccount === 1) {
                                    // Find that active integration
                                    const integration = integrations.find(integration => {
                                        const {account} = getIntegrationInfo(integration);

                                        return account?.id && account?.status === ACCOUNT_STATUSES.ACTIVE;
                                    });

                                    if (integration) {
                                        const bankAccountHolder = integration.bankAccountHolders?.find(holder => holder.type === 'COMPANY');
                                        const account = bankAccountHolder?.bankAccounts?.[0];

                                        dispatch(BridgeActions.setDefaultBank(
                                            {
                                                id: account?.id,
                                                bankAccountHolderId: bankAccountHolder?.id,
                                            },
                                        ));
                                    }
                                }

                                // If on transaction list get transactions
                                if (location?.pathname === RoutePaths.BANK_TRANSACTION_LIST) {
                                    dispatch(TransactionsActions.getCombinedTransactionList({}));
                                } else {
                                    dispatch(push(RoutePaths.BANK_TRANSACTION_LIST));
                                }
                            }
                        }}
                    >
                        {t('bridgeAPI.pickAdditionalAccountModal.confirm')}
                    </LoadingButton>
                </>
            )}
            containerSx={{
                width: '568px',
            }}
            buttonsSx={{
                flexDirection: 'column',
            }}
            hasShowClose={false}
        >
            <Alert sx={{my: 2}} severity="info">
                {t('bridgeAPI.pickAdditionalAccountModal.info')}
            </Alert>

            <Typography>
                {t('bridgeAPI.pickAdditionalAccountModal.list')}
            </Typography>

            {integrations.map(integration => {
                return (
                    <Typography
                        key={integration?.id}
                    >
                        {
                            integration?.type === BANK_TYPES.hiway
                                ? HIWAY_PRO_NAME
                                : integration?.bankAccountHolders?.[0]?.bankAccounts?.[0]?.name
                        }
                    </Typography>
                );
            })}
        </ModalWrapper>
    );
};

let interval;
const WaitLoader = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation('bank');

    useEffect(() => {
        interval = setInterval(() => {
            dispatch(BankActions.getUserIntegrationData());
        }, 1000);
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ModalWrapper
            modalKey={modalKey}
            containerSx={{
                width: '328px',
            }}
            hasShowClose={false}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
            }}
            >
                <CircularProgress />
                <Typography sx={{
                    fontWeight: 500,
                }}
                >
                    {t('bridgeAPI.pickAdditionalAccountModal.loaderTitle')}
                </Typography>
                <Typography sx={{
                    fontSize: theme => theme.typography.pxToRem(14),
                    textAlign: 'center',
                }}
                >
                    {t('bridgeAPI.pickAdditionalAccountModal.loaderText')}
                </Typography>
            </Box>
        </ModalWrapper>
    );
};

export default PickAdditionalAccountModal;
