import {Box, Paper} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import TransactionDetailsCloseSection
    from '../../features/bank/modules/account-balance/components/transaction-details/TransactionDetailsCloseSection';

let timeout;
const ExpandableSidebar = ({
    isMobileSize,
    onClose,
    isOpen,
    mt,
    hasClose,
    children,
    width,
    scrollHeader,
    closeText,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        timeout = setTimeout(() => {
            setIsExpanded(isOpen);
        }, 20);
        return () => {
            clearTimeout(timeout);
        };
    }, [isOpen]);

    return (
        <Box sx={{
            width: isExpanded ? (isMobileSize ? '100%' : width) : '1px',
            transition: 'width 0.4s ease-in-out',
            mt,
            position: !isMobileSize ? 'fixed' : 'inherit',
            right: 0,
            zIndex: 20,
        }}
        >
            <Box
                sx={isMobileSize ? {display: 'flex', flexDirection: 'column'}
                    : {
                        display: isOpen ? 'flex' : 'none',
                        flexDirection: 'column',
                        boxShadow: isOpen
                            ? '0px 11px 15px -7px rgba(11, 35, 51, 0.08), 0px 24px 38px 3px rgba(11, 35, 51, 0.08), 0px 9px 46px 8px rgba(11, 35, 51, 0.1);'
                            : 'none',
                    }}
            >
                {isExpanded && hasClose && (
                <TransactionDetailsCloseSection
                    isMobileSize={isMobileSize}
                    onClose={onClose}
                    isOpen={!!isOpen}
                    closeText={closeText}
                />
                )}

                {scrollHeader}

                <Paper
                    elevation={1}
                    sx={{
                        height: !isMobileSize ? '100vh' : '100%',
                        width: isMobileSize ? '100%' : width,
                    }}
                >
                    <Box
                        id="expandable-sidebar-details"
                        sx={{
                            overflowY: 'auto',
                            height: isMobileSize ? 'calc(100vh - 118px)' : '100vh',
                        }}
                    >
                        {children}
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
};

ExpandableSidebar.propTypes = {
    isMobileSize: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    hasClose: PropTypes.bool,
    mt: PropTypes.number,
    width: PropTypes.string,
    scrollHeader: PropTypes.node,
    closeText: PropTypes.string,
};

ExpandableSidebar.defaultProps = {
    hasClose: true,
    width: '400px',
    isOpen: false,
    scrollHeader: null,
    closeText: '',
    mt: 0,
};

export default ExpandableSidebar;
