import {FormLabel} from '@mui/joy';
import PropTypes from 'prop-types';

export const FormFieldLabel = ({label}) => {
    return (
        <FormLabel sx={{color: 'text.primary'}}>
            {label}
        </FormLabel>
    );
};

FormFieldLabel.propTypes = {
    label: PropTypes.string.isRequired,
};
