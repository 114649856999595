import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import {LoadingButton} from '@mui/lab';
import {Alert, Box, Divider, Grow, Paper} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link, generatePath} from 'react-router-dom';
import {ButtonAction} from '../../../../../components/buttons/ButtonAction';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../../animations/utils/constants';
import {UiActions} from '../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../ui/utils/constants';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';
import {DatabaseSelectors} from '../../database/store/database.selector';
import {SigningActions} from '../store/signing.action';
import {SigningSelectors} from '../store/signing.selector';

export const DocumentSigningMobileTraining = ({isLoadingNextStep}) => {
    const {t} = useTranslation('document');

    const dispatch = useDispatch();

    const user = useSelector(LoggedInUserSelectors.selectLoggedInUser);

    const isSigned = useSelector(SigningSelectors.selectCurrentDocumentIsSigned);

    const numberOfDocuments = useSelector(DatabaseSelectors.selectNumberOfSignableDocuments());
    const currentDocumentId = useSelector(SigningSelectors.selectCurrentDocumentId);
    const currentDocumentIndex = useSelector(DatabaseSelectors.selectDocumentIndex(currentDocumentId));

    const isSigning = useSelector(SigningSelectors.selectIsSigningCurrentDocument);

    const onNextClick = () => {
        dispatch(SigningActions.openNextTrainingDocument());
    };

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    return (
        <Paper radius={8} elevation={2} sx={{p: 2}}>
            <Alert
                severity={isSigned ? 'success' : 'warning'}
                sx={{mt: 1, borderRadius: 3, color: isSigned ? 'rgba(0, 0, 0, 0.6)' : 'warning.dark'}}
                icon={isSigned ? <CheckCircleIcon sx={{color: theme => theme.palette.buttonSuccess.main}} /> : <HourglassTopIcon sx={{color: 'warning.dark'}} />}
            >
                <b>{currentDocumentIndex} {t('signing.of')} {numberOfDocuments}: </b>
                {!isSigned ? t('signing.awaitingSignature') : t('signing.contractSigned')}
            </Alert>

            <LoadingButton
                sx={{borderRadius: 2, mt: 1, color: '#fff'}}
                startIcon={isSigned ? null : <EditIcon />}
                endIcon={isSigned ? <ArrowForwardIcon /> : null}
                variant="contained"
                color={isSigned ? 'buttonSuccess' : 'secondary'}
                size="small"
                loading={isSigning || isLoadingNextStep}
                loadingPosition="start"
                onClick={() => {
                    isSigned
                        ? onNextClick()
                        : dispatch(SigningActions.signCurrentDocument());
                }}
                fullWidth
            >
                {isSigned ? t('signing.next') : t('signing.signButton')}
            </LoadingButton>

            <Divider sx={{mt: 2, mb: 2}} />

            <Grow in={isAnimationActive} onExited={onEndedAnimation}>
                <Box sx={{display: 'flex'}}>
                    <ButtonAction
                        component={Link}
                        startIcon={<PublishedWithChangesIcon />}
                        size="medium"
                        to={generatePath(`${RoutePaths.DOCUMENTS}?documents-tabFilter=TRAINING`, {
                            companyId: user?.defaultCompanyId,
                        })}
                        fullWidth
                        variant="outlined"
                        sx={{mr: 0.5, py: 0.7, borderRadius: 2, fontSize: theme => theme.typography.pxToRem(14)}}
                    >
                        {t('signing.myDocuments')}
                    </ButtonAction>

                    <ButtonAction
                        startIcon={<PublishedWithChangesIcon />}
                        size="medium"
                        onClick={() => dispatch(UiActions.setActiveModal(ModalsKeys.UPLOAD_SIGNATURE, true))}
                        fullWidth
                        variant="outlined"
                        sx={{ml: 0.5, py: 0.7, borderRadius: 2, fontSize: theme => theme.typography.pxToRem(14)}}
                    >
                        {t('signing.mySignatureTitle')}
                    </ButtonAction>
                </Box>
            </Grow>
        </Paper>
    );
};

DocumentSigningMobileTraining.propTypes = {
    isLoadingNextStep: PropTypes.bool.isRequired,
};
