import {all, call, fork, put, select, takeEvery} from 'redux-saga/effects';
import {getDeclarations, storeDeclarations} from './vat-declaration.action';
import {GET_DECLARATIONS} from './vat-declaration.action-type';
import {VatDeclarationSelectors} from './vat-declaration.selector';
import {selectRouterLocation} from '../../../lib/router/connected-router-saga';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import hiwayLocalStorage from '../../../v1/config/storage';
import {BankApi} from '../../bank/api/bank.api';
import {LoadingActions, LoadingTypes} from '../../loading';
import {vatDeclarationApi} from '../api/vat-declaration.api';
import {VatDeclarationStatus} from '../constants';

export const vatDeclarationLoaderSaga = function* () {
    yield put(getDeclarations());
};

const mapSearchSortParam = param => {
    switch (param) {
        case 'company':
            return 'company.name';
    }
    return param;
};

const getDeclarationsFlow = function* () {
    const location = yield select(selectRouterLocation);
    const search = location?.query;

    if (!search?.['vatDeclaration-month'] || !search?.['vatDeclaration-year']) {
        return;
    }

    const storageKey = `vatDeclaration-savedRowsPerPage`;
    const limit = search?.['vatDeclaration-rowsPerPage']
        ?? (hiwayLocalStorage.has(storageKey) ? parseInt(hiwayLocalStorage.get(storageKey), 10) : 10);

    const statusFilter = search?.['vatDeclaration-statusFilter'];
    const findStatusKey = value => Object.keys(VatDeclarationStatus).find(key => value === VatDeclarationStatus[key]);

    const params = {
        limit: limit,
        offset: search?.['vatDeclaration-page'] ? parseInt(search['vatDeclaration-page'], 10) * limit : 0,
        sortBy: search?.['vatDeclaration-sortBy'] ? mapSearchSortParam(search['vatDeclaration-sortBy']) : undefined,
        sortOrder: search?.['vatDeclaration-sortBy']
            ? search?.['vatDeclaration-sortDirection']
                ? search?.['vatDeclaration-sortDirection'].toUpperCase()
                : 'DESC'
            : undefined,
        searchQuery: search?.['vatDeclaration-searchTerm'],
        filterQuery: {
            month: Number(search?.['vatDeclaration-month']),
            year: Number(search?.['vatDeclaration-year']),
            ...(statusFilter && {status: findStatusKey(statusFilter)}),
        },
    };

    yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATIONS, true));

    try {
        const declarations = yield call(vatDeclarationApi.getDeclarations, params);
        const freelancerIds = declarations.items.map(item => item.freelancer.id);
        if (freelancerIds.length > 0) {
            yield fork(connectBankIntegrationsWithDeclarations, freelancerIds);
        }
        yield put(storeDeclarations(declarations));
    } catch (error) {
        Debug.error('vat-declaration', error.message, {error});
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATIONS, false));
    }
};

const connectBankIntegrationsWithDeclarations = function* (freelancerIds) {
    yield put(LoadingActions.setLoading(LoadingTypes.CONNECT_BANK_INTEGRATIONS_WITH_DECLARATIONS, true));

    try {
        const bankDetails = yield call(BankApi.getBankDetails, freelancerIds);
        const declarations = yield select(VatDeclarationSelectors.selectDeclarations);

        const newDeclarationsData = yield declarations?.items?.map(item => {
            const {bankAccountOverview} = bankDetails?.find(bank => bank?.freelancerId === item?.freelancer?.id);
            return {
                ...item,
                bankIntegrations: bankAccountOverview?.overviewPerAccount,
                totalUncategorizedTransactions: bankAccountOverview?.totalUncategorizedTransactions,
            };
        });

        yield put(storeDeclarations({...declarations, items: newDeclarationsData}));
        yield put(LoadingActions.setLoading(LoadingTypes.CONNECT_BANK_INTEGRATIONS_WITH_DECLARATIONS, false));
    } catch (error) {
        Debug.error('vatDeclaration', 'Error: ', {error});
        Toast.error('genericError');
        yield put(LoadingActions.setLoading(LoadingTypes.CONNECT_BANK_INTEGRATIONS_WITH_DECLARATIONS, false));
    }
};

export const vatDeclarationsSaga = function* () {
    yield all([takeEvery(GET_DECLARATIONS, getDeclarationsFlow)]);
};
