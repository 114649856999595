import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import {Box, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ConditionalTooltip} from '../../../../../../components/tooltip/ConditionalTooltip';
import {splitNumber} from '../../../../../../utils/number';

export const AccountBalanceTotal = ({amount, currency, variant, mb}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const {t} = useTranslation('bank');

    const {integer, decimal} = splitNumber(amount);

    // TODO Add regional formatting for money? It is not simple with current design.
    return (
        <Box sx={{mb: isMobileSize ? 1 : mb, position: 'relative', textWrap: 'nowrap'}}>
            <Typography variant={variant} sx={{display: 'inline', textWrap: 'nowrap'}}>
                {integer}
            </Typography>
            <Typography sx={{
                fontWeight: '500',
                display: 'inline',
                fontSize: theme => theme.typography.pxToRem(24),
                textWrap: 'nowrap',
                mr: 0.6,
            }}
            >
                ,{decimal} {currency}
            </Typography>

            <ConditionalTooltip
                tooltip={t('accountBalance.tooltip')}
            >
                <HelpOutlineOutlinedIcon sx={{
                    color: 'v2.blueGray.300',
                    fontSize: '26px',
                }}
                />
            </ConditionalTooltip>
        </Box>
    );
};

AccountBalanceTotal.propTypes = {
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    currency: PropTypes.string,
    variant: PropTypes.string,
    mb: PropTypes.number,
};

AccountBalanceTotal.defaultProps = {
    currency: '€',
    variant: 'h3',
    mb: 2,
};

export const AccountDetailsTotal = ({amount, currency, isDebit, isRejected}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const {integer, decimal} = splitNumber(amount ?? 0);

    return (
        <Box sx={{
            mb: isMobileSize ? 1 : 2,
            color: isRejected ? 'v2.light.error.dark' : !isDebit ? 'success_v2.light' : 'elephant.main',
        }}
        >
            <Typography sx={{display: 'inline', fontSize: theme => theme.typography.pxToRem(60), fontWeight: 500}}>
                {amount !== 0 && (isDebit ? '-' : '+')}
                {integer}
            </Typography>
            <Typography sx={{display: 'inline', fontSize: theme => theme.typography.pxToRem(42), fontWeight: 500}}>
                ,{decimal} {currency}
            </Typography>
        </Box>
    );
};

AccountDetailsTotal.propTypes = {
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    currency: PropTypes.string,
    isDebit: PropTypes.bool.isRequired,
    isRejected: PropTypes.bool.isRequired,
};

AccountDetailsTotal.defaultProps = {
    currency: '€',
};


export const AccountTotalSmall = ({amount, currency, isDebit, isRejected}) => {
    const {integer, decimal} = splitNumber(amount);

    return (
        <Box sx={{
            color: isRejected ? 'v2.light.error.dark' : !isDebit ? 'success_v2.light' : 'elephant.main',
        }}
        >
            <Typography sx={{display: 'inline', fontSize: theme => theme.typography.pxToRem(24), fontWeight: 500}}>
                {isDebit && amount !== 0 && '-'}
                {integer}
            </Typography>
            <Typography sx={{display: 'inline', fontSize: theme => theme.typography.pxToRem(16), fontWeight: 500}}>
                ,{decimal} {currency}
            </Typography>
        </Box>
    );
};

AccountTotalSmall.propTypes = {
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    currency: PropTypes.string,
    isDebit: PropTypes.bool.isRequired,
    isRejected: PropTypes.bool.isRequired,
};

AccountTotalSmall.defaultProps = {
    currency: '€',
};
