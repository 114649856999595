import {Box, Drawer} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import CloseDrawerButton from '../buttons/CloseDrawerButton';

// TODO: Implement this component in all possible places instead of Drawer

const DrawerWrapper = ({
    isOpen,
    containerSx,
    onClose,
    children,
}) => {
    return (
        <Drawer
            anchor="bottom"
            open={isOpen}
            onClose={onClose}
            sx={{
                '.MuiDrawer-paperAnchorBottom': {
                    borderTopRightRadius: '32px',
                    borderTopLeftRadius: '32px',
                },
            }}
        >
            <Box sx={{
                position: 'relative',
                minHeight: '300px',
                display: 'flex',
                flexDirection: 'column',
                px: 3,
                pt: 5,
                pb: 3,
                ...containerSx,
            }}
            >
                {onClose && (
                    <CloseDrawerButton onClose={onClose} />
                )}
                {children}
            </Box>
        </Drawer>
    );
};

DrawerWrapper.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    containerSx: PropTypes.object,
    onClose: PropTypes.func,
};

DrawerWrapper.defaultProps = {
    containerSx: {},
    onClose: null,
};

export default DrawerWrapper;
