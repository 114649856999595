import {combineReducers} from 'redux';
import {AccountingActionTypes} from './accounting.action-type';
import {LOCATION_CHANGED} from '../../../../../lib/router/connected-router-saga';
import {createReducer} from '../../../../../v1/app/createReducer';

export const accountingReducer = combineReducers({
    fileLoader: (state = [], action) => {
        if ([
            AccountingActionTypes.DOWNLOAD_FEC_FILE_LOADER,
            AccountingActionTypes.UPLOAD_FINAL_FEC_FILE_LOADER,
        ].includes(action.type)) {
            const eventId = action.payload.eventId;

            const hasElement = state.findIndex(element => element.eventId === eventId);

            if (hasElement !== -1) {
                const newState = [...state];
                newState.splice(hasElement, 1, action.payload);
                return newState;
            }

            return [
                ...state,
                action.payload,
            ];
        } else if (action.type === LOCATION_CHANGED) {
            return [];
        }

        return state;
    },
    journalAccounts: createReducer([], AccountingActionTypes.STORE_JOURNAL_ACCOUNTS, true),
    annualAccounts: (state = {}, action) => {
        if (action.type === AccountingActionTypes.STORE_ANNUAL_ACCOUNTS) {
            return {
                ...state,
                ...action.payload.reduce((accounts, annualAccount) => ({
                    ...accounts,
                    [annualAccount.year]: annualAccount,
                }), {}),
            };
        } else if (action.type === AccountingActionTypes.STORE_ANNUAL_ACCOUNT_FOR_YEAR) {
            return {
                ...state,
                [action.payload.year]: action.payload,
            };
        } else if (action.type === LOCATION_CHANGED) {
            return {};
        }

        return state;
    },
    balanceSheetDetails: createReducer({}, AccountingActionTypes.STORE_BALANCE_SHEET_DETAILS, true),
    allDocumentsGenerationProgress: createReducer(
        false,
        AccountingActionTypes.STORE_ALL_DOCUMENTS_GENERATING_PROGRESS,
        true,
    ),
    documentGenerationProgress: (state = {}, action) => {
        if (action.type === AccountingActionTypes.STORE_DOCUMENT_GENERATING_PROGRESS) {
            const docStatus = action.payload;

            return {
                ...state,
                ...docStatus,
            };
        } else if (action.type === LOCATION_CHANGED) {
            return {};
        }

        return state;
    },
    inpiSubmissionDetails: (state = {}, action) => {
        if (action.type !== AccountingActionTypes.STORE_INPI_SUBMISSION_DETAILS) {
            return state;
        }

        return {
            ...state,
            ...action.payload,
        };
    },
    fecDocuments: (state = {}, action) => {
        if (action.type === AccountingActionTypes.STORE_FEC_DOCUMENTS) {
            return {
                ...action.payload.reduce((allFECDocs, fec) => ({...allFECDocs, [fec.year]: fec}), {}),
            };
        } else if (action.type === LOCATION_CHANGED) {
            return {};
        }

        return state;
    },
});
