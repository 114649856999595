import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import BankDetails from './BankDetails';
import ExpandableSidebar from '../../../../../components/expandable-sidebar/ExpandableSidebar';

const BankSidebar = ({onClose, isMobileSize, integration}) => {
    const {t} = useTranslation('bank');

    // TODO Check what this is for, it seems unneeded
    // useEffect(() => {
    //     dispatch(
    //         sendEvent(EVENT_TYPES.ROUTE_CHANGE,
    //             {path: '/bank-transactions'}),
    //     );
    // }, [dispatch]);

    if (!integration) {
        return null;
    }

    return (
        <Box sx={isMobileSize ? {
            position: 'fixed',
            right: 0,
            top: '70px',
            left: 0,
            bottom: 0,
            zIndex: 222,
            backgroundColor: '#f2f4f6',
        } : {
            position: 'fixed',
            right: 0,
            top: 0,
            zIndex: 222,
        }}
        >
            <ExpandableSidebar
                isOpen
                onClose={onClose}
                isMobileSize={isMobileSize}
                closeText={t('accountSettingsSidebar.back')}
            >
                <BankDetails
                    isMobileSize={isMobileSize}
                    integration={integration}
                />
            </ExpandableSidebar>
        </Box>
    );
};

BankSidebar.propTypes = {
    onClose: PropTypes.func.isRequired,
    isMobileSize: PropTypes.bool.isRequired,
    integration: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
};

export default BankSidebar;
