import CheckIcon from '@mui/icons-material/Check';
import {LoadingButton} from '@mui/lab';
import {Alert, Box, FormControlLabel, Radio, RadioGroup, Typography} from '@mui/material';
import Chip from '@mui/material/Chip';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ModalWrapper} from '../../../../../components/modal-wrapper/ModalWrapper';
import {push, selectRouterLocation} from '../../../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {ACCOUNT_STATUSES} from '../../../../company-profile/modules/accounting/utils/constants';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import {ModalsKeys} from '../../../../ui/utils/constants';
import {BankSelector} from '../../../store/bank.selector';
import {TransactionsActions} from '../../account-balance/store/transactions.action';
import {BridgeActions} from '../store/bridge-api.action';
import {getIntegrationInfo} from '../utils/bridge-util';

const modalKey = ModalsKeys.PICK_DEFAULT_BANK_MODAL;
const PickDefaultBankModal = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.SET_DEFAULT_BANK),
    );

    const integrations = useSelector(BankSelector.selectIntegrations);
    const location = useSelector(selectRouterLocation);

    const [value, setValue] = React.useState();
    const chosenValue = value ? integrations.find(integration => integration?.id === value) : undefined;

    const bankAccountHolder = chosenValue?.bankAccountHolders.find(holder => holder.type === 'COMPANY');
    const account = bankAccountHolder?.bankAccounts?.[0];

    const handleChange = event => {
        setValue(event.target.value);
    };

    const onSubmit = () => {
        // If on transaction list get transactions
        if (location?.pathname === RoutePaths.BANK_TRANSACTION_LIST) {
            dispatch(TransactionsActions.getCombinedTransactionList({}));
        } else {
            dispatch(push(RoutePaths.BANK_TRANSACTION_LIST));
        }

        dispatch(BridgeActions.setDefaultBank(
            {
                id: account?.id,
                bankAccountHolderId: bankAccountHolder?.id,
            },
        ));
    };

    return (
        <ModalWrapper
            modalKey={modalKey}
            title={(
                <Typography variant="h4">
                    {t('bridgeAPI.pickDefaultBankModal.title')}
                </Typography>
            )}
            buttons={() => (
                <LoadingButton
                    disabled={!value}
                    fullWidth={isMobileSize}
                    startIcon={<CheckIcon />}
                    loading={isLoading}
                    color="error"
                    variant="contained"
                    onClick={onSubmit}
                >
                    {t('bridgeAPI.pickDefaultBankModal.confirm')}
                </LoadingButton>
            )}
            containerSx={{
                width: '568px',
            }}
            hasShowClose={false}
        >
            <>
                <Alert
                    sx={{
                        mt: 2.5,
                    }}
                    severity="info"
                >
                    {t('bridgeAPI.pickDefaultBankModal.alertPart1')}
                    <ul style={{paddingLeft: '16px'}}>
                        <li>
                            {t('bridgeAPI.pickDefaultBankModal.alertList1')}
                        </li>
                        <li>
                            {t('bridgeAPI.pickDefaultBankModal.alertList2')}
                        </li>
                    </ul>
                    {t('bridgeAPI.pickDefaultBankModal.alertPart2')}
                </Alert>

                <RadioGroup
                    row
                    name="account-integration"
                    value={value ? chosenValue?.id : ''}
                    onChange={handleChange}
                >
                    {integrations.map(integration => {
                        const isChosen = value === integration.id;
                        const {account: chosenAccount} = getIntegrationInfo(integration);

                        if (!chosenAccount?.id || chosenAccount?.status !== ACCOUNT_STATUSES.ACTIVE) {
                            return null;
                        }

                        return (
                            <Box
                                key={integration.id}
                                sx={{
                                    'border': isChosen
                                        ? '1px solid #3779BE'
                                        : '1px solid rgba(0, 0, 0, 0.12)',
                                    'borderRadius': '16px',
                                    'p': 2,
                                    'pl': 3,
                                    'mt': 1,
                                    'pr': 0,
                                    'width': '100%',
                                    'cursor': 'pointer',
                                    '& .MuiFormControlLabel-root': {
                                        width: '100%',
                                        mr: 0,
                                    },
                                    '& .MuiFormControlLabel-label': {
                                        width: '100%',
                                    },
                                }}
                            >
                                <FormControlLabel
                                    value={integration.id}
                                    control={(
                                        <Radio sx={{
                                            alignItems: 'center',
                                        }}
                                        />
                                    )}
                                    label={(
                                        <Box>
                                            <Box sx={{
                                                fontWeight: 600,
                                                mb: 0.5,
                                            }}
                                            >
                                                <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 1,
                                                }}
                                                >
                                                    {chosenAccount?.name}
                                                    {chosenAccount?.isDefault && (
                                                        <Chip
                                                            sx={{
                                                                textTransform: 'none',
                                                                fontSize: '12px',
                                                                lineHeight: '12px',
                                                                fontWeight: '400',
                                                                height: '20px',
                                                            }}
                                                            size="small"
                                                            label={t('bridgeAPI.pickDefaultBankModal.default')}
                                                            color="secondary"
                                                        />
                                                    )}
                                                </Box>
                                            </Box>

                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                gap: 1,
                                            }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        overflowWrap: 'anywhere',
                                                    }}
                                                >
                                                    {chosenAccount?.iban}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        color: 'v2.light.text.disabled',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {chosenAccount?.availableBalance} €
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                                    labelPlacement="end"
                                />
                            </Box>
                        );
                    })}
                </RadioGroup>
            </>
        </ModalWrapper>
    );
};

export default PickDefaultBankModal;
