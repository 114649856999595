import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {TransactionsActions} from '../../../store/transactions.action';
import {TRANSACTION_CATEGORIZATION_SCREENS, TVA_ENUM} from '../../../util/constants';

const Vat = ({data, isEditable}) => {
    const {t} = useTranslation('bank');

    const dispatch = useDispatch();

    const vatList = data.vat;

    const isSingle = vatList.length === 1 && vatList[0] !== TVA_ENUM.NO_VAT;

    const translated = vatList.map(vat => {
        if (vat === TVA_ENUM.TVA_CUSTOM) {
            return t(`vat.TVA_CUSTOM_IN_TABLE`);
        }
        return t(`vat.${vat}`);
    }).join(' • ');

    if (!translated) {
        return null;
    }

    const onVATClick = e => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(TransactionsActions.setCategorizationScreenInfo({
            screen: TRANSACTION_CATEGORIZATION_SCREENS.TVA,
            transactionId: data?.id,
        }));

        dispatch(TransactionsActions.addToCategorisationList({
            transactionId: data?.id,
            hasSpecialHandlingIfCategorised: true,
        }));
    };

    return (
        <Box
            onClick={onVATClick}
            sx={{
                'display': 'flex',
                'alignItems': 'center',
                'px': 0.5,
                'fontSize': theme => theme.typography.pxToRem(12),
                '&:hover': {
                    background: 'white',
                    color: 'primary_v2.main',
                    border: '1px solid #3779BE',
                    borderColor: 'primary_v2.main',
                    borderRadius: '16px',
                },
            }}
        >
            TVA :
            <Box
                sx={{
                    'flex': '1 1 40%',
                    'ml': 0.5,
                    'display': 'flex',
                    'flexDirection': 'column',
                    'height': '100%',
                    'justifyContent': 'flex-end',
                    '&:hover': isSingle && isEditable ? {
                        fontWeight: '600',
                    } : {},
                }}
            >
                <Box sx={{
                    flex: '1 1 40%',
                    fontSize: theme => theme.typography.pxToRem(12),
                    letterSpacing: '0.4px',
                    color: 'lightTextSecondary',
                    justifyContent: 'center',
                }}
                >
                    {translated}
                </Box>
            </Box>
        </Box>
    );
};

Vat.propTypes = {
    data: PropTypes.object.isRequired,
    isEditable: PropTypes.bool.isRequired,
};

export default Vat;
