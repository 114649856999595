import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Alert, Box, Button, Skeleton, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';

export const AccountBalanceAlert = ({
    count,
    onSwitchChange,
    transactionNonCategorizedCount,
}) => {
    const {t} = useTranslation('bank');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isLoading = useSelector(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.BANK_TRANSACTIONS_FILTERS,
    ));

    if (isLoading) {
        return (
            <Box sx={{
                height: '50px',
            }}
            >
                <Skeleton
                    sx={{
                        borderRadius: '12px',
                    }}
                    variant="rectangular"
                    width="100%"
                    height={50}
                />
            </Box>
        );
    }

    return (
        <Box sx={{mb: 2, mx: isMobileSize ? 2 : 0}}>
            {transactionNonCategorizedCount > 0 ? (
                <Alert
                    color="info"
                    severity="info"
                    fullWidth
                    sx={{
                        'borderRadius': '12px',
                        '.MuiAlert-icon': {
                            display: 'flex',
                            alignItems: 'center',
                        },
                        '.MuiAlert-action': {
                            display: 'flex',
                            alignItems: 'center',
                        },
                        '.MuiAlert-message': isMobileSize ? {
                            width: '100%',
                            pb: 0,
                            mb: -1,
                        } : {},
                    }}
                    icon={
                        !isMobileSize && (
                        <Box sx={{
                            height: '24px',
                            width: '24px',
                            borderRadius: '50%',
                            color: 'white',
                            backgroundColor: 'primary.light',
                            fontSize: theme => theme.typography.pxToRem(12),
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        >
                            {count}
                        </Box>
                        )
                    }
                    action={!isMobileSize && (
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                        }}
                        >
                            {/* Disabled for first version of categorisation */}
                            {/* <Button */}
                            {/*    variant="text" */}
                            {/*    size="small" */}
                            {/*    sx={{height: '30px'}} */}
                            {/*    startIcon={<TuneIcon />} */}
                            {/* > */}
                            {/*    {t('accountBalance.automationRules')} */}
                            {/* </Button> */}
                            <Button
                                color="inherit"
                                size="small"
                                sx={{height: '30px'}}
                                onClick={() => {
                                    onSwitchChange(true);
                                }}
                                endIcon={<ArrowForwardIcon />}
                            >
                                {t('accountBalance.see')}
                            </Button>
                        </Box>
                    )}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    >
                        {isMobileSize && (
                            <Box
                                component="span"
                                sx={{
                                    height: '24px',
                                    width: '24px',
                                    borderRadius: '50%',
                                    color: 'white',
                                    backgroundColor: 'primary.light',
                                    fontSize: theme => theme.typography.pxToRem(12),
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mr: 1,
                                }}
                            >
                                {count}
                            </Box>
                        )}
                        {t('accountBalance.transactionAlert')}
                    </Box>


                    {isMobileSize && (
                        <Box sx={{
                            width: '100%',
                        }}
                        >
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 1,
                            }}
                            >
                                {/* Disabled for first version of categorisation */}
                                {/* <Button */}
                                {/*    sx={{ */}
                                {/*        fontSize: theme => theme.typography.pxToRem(13), */}
                                {/*    }} */}
                                {/*    variant="text" */}
                                {/*    size="small" */}
                                {/*    startIcon={<TuneIcon />} */}
                                {/* > */}
                                {/*    {t('accountBalance.automationRulesMobile')} */}
                                {/* </Button> */}
                                <Button
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        onSwitchChange(true);
                                    }}
                                    endIcon={<ArrowForwardIcon />}
                                >
                                    {t('accountBalance.see')}
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Alert>
            ) : (
                <Alert
                    variant="filled"
                    severity="success"
                    sx={{
                        mb: 3,
                        borderRadius: '16px',
                        backgroundColor: '#4CAF50',
                    }}
                >
                    {t('accountBalance.allCategorizedText')}
                </Alert>
            )}
        </Box>
    );
};

AccountBalanceAlert.propTypes = {
    count: PropTypes.number,
    onSwitchChange: PropTypes.func.isRequired,
    transactionNonCategorizedCount: PropTypes.number.isRequired,
};

AccountBalanceAlert.defaultProps = {
    count: 0,
};
