import ArticleIcon from '@mui/icons-material/Article';
import CloseIcon from '@mui/icons-material/Close';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EditIcon from '@mui/icons-material/Edit';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SearchIcon from '@mui/icons-material/Search';
import {LoadingButton} from '@mui/lab';
import {Badge, Box, Grow, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {push} from '../../../../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../../../../lib/router/route-paths';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {BANK_TYPES} from '../../../bridge-api/utils/constants';

export const AccountBalanceFilter = ({
    filter,
    setFilter,
    setAnchorEl,
    isInHeader,
    integration,
}) => {
    const searchRef = useRef();
    const {t} = useTranslation('bank');
    const dispatch = useDispatch();

    const is1200Breakpoint = useMediaQuery(`(max-width:12000px)`);

    const [hasSearchOpened, setHasSearchOpened] = useState(true);
    const [hasFilterOpened, setHasFilterOpened] = useState(false);

    const isHiway = integration?.type === BANK_TYPES.hiway;

    const isLoading = useSelector(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.DOWNLOAD_DOCUMENT,
    ));

    const downloadRib = () => {
        dispatch(push(RoutePaths.BANK_RIB_AND_DOCUMENTS));
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const onSearchTextChange = text => {
        setFilter(filter => {
            return {
                ...filter,
                search: text,
            };
        });
    };

    useEffect(() => {
        if (filter.search === '' && searchRef?.current) {
            searchRef.current.value = '';
        }

        if (searchRef?.current && filter?.search !== searchRef?.current?.value) {
            searchRef.current.value = filter.search;
        }
    }, [filter.search]);

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: isInHeader ? 0 : -4,
            // mb: 2,
        }}
        >
            <Grow
                in={hasFilterOpened}
                mountOnEnter
                unmountOnExit
                timeout={20}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: theme => theme.typography.pxToRem(14),
                    }}
                >
                    <Box sx={{color: 'v2.light.text.disabled', mr: 2}}>
                        {t('accountBalanceFilter.filter')}
                    </Box>
                    <Box>
                        <Button
                            id={`dr${isInHeader ? '-inHeader' : ''}`}
                            sx={{
                                height: '28px',
                            }}
                            startIcon={(
                                <Badge
                                    color="secondary"
                                    variant="dot"
                                    invisible={!(filter.startDate || filter.endDate)}
                                    sx={{
                                        '.MuiBadge-overlapRectangular': {
                                            transform: 'scale(1) translate(-2px, 2px)',
                                        },
                                    }}
                                >
                                    <DateRangeIcon sx={{color: 'rgba(25, 118, 210, 0.5)'}} />
                                </Badge>
                                )}
                            onClick={handleClick}
                        >
                            <Typography sx={{
                                fontSize: theme => theme.typography.pxToRem(14),
                                color: 'text_v2.primary',
                                textTransform: 'capitalize',
                            }}
                            >
                                {t('accountBalanceFilter.dates')}
                            </Typography>
                        </Button>
                    </Box>

                    <Box>
                        <Button
                            id={`supportingDocuments${isInHeader ? '-inHeader' : ''}`}
                            sx={{
                                height: '28px',
                            }}
                            onClick={handleClick}
                            startIcon={(
                                <Badge
                                    color="secondary"
                                    variant="dot"
                                    invisible={!(filter.isNotCategorized)}
                                    sx={{
                                        '.MuiBadge-overlapRectangular': {
                                            transform: 'scale(1) translate(-2px, 2px)',
                                        },
                                    }}
                                >
                                    <EditIcon sx={{color: 'rgba(25, 118, 210, 0.5)'}} />
                                </Badge>
                                )}
                        >
                            <Typography sx={{
                                fontSize: theme => theme.typography.pxToRem(14),
                                color: 'text_v2.primary',
                                textTransform: 'capitalize',
                            }}
                            >
                                {t('accountBalanceFilter.documents')}
                            </Typography>
                        </Button>
                    </Box>

                    <Box>
                        <Button
                            id={`type${isInHeader ? '-inHeader' : ''}`}
                            sx={{
                                height: '28px',
                            }}
                            onClick={handleClick}
                            startIcon={(
                                <Badge
                                    color="secondary"
                                    variant="dot"
                                    invisible={(filter?.transactionTypes?.length === 0)}
                                    sx={{
                                        '.MuiBadge-overlapRectangular': {
                                            transform: 'scale(1) translate(-2px, 2px)',
                                        },
                                    }}
                                >
                                    <ReceiptIcon sx={{color: 'rgba(25, 118, 210, 0.5)'}} />
                                </Badge>
                                )}
                        >
                            <Typography sx={{
                                fontSize: theme => theme.typography.pxToRem(14),
                                color: 'text_v2.primary',
                                textTransform: 'none',
                            }}
                            >
                                {t('accountBalanceFilter.types')}
                            </Typography>
                        </Button>
                    </Box>

                    <CloseIcon
                        sx={{
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setHasFilterOpened(false);
                            setTimeout(() => {
                                setHasSearchOpened(true);
                            }, 50);
                        }}
                    />
                </Box>
            </Grow>

            <Grow
                in={hasSearchOpened}
                mountOnEnter
                unmountOnExit
                timeout={20}
            >

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                }}
                >
                    <Badge
                        color="secondary"
                        variant="dot"
                        invisible={
                            (filter?.transactionTypes?.length === 0)
                            && !(filter.isNotCategorized)
                            && !(filter.startDate || filter.endDate)
                        }
                        sx={{
                            '.MuiBadge-overlapRectangular': {
                                transform: 'scale(1) translate(-2px, 2px)',
                            },
                        }}
                    >
                        <FilterAltIcon
                            sx={{
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setHasSearchOpened(false);
                                setTimeout(() => {
                                    setHasFilterOpened(true);
                                }, 50);
                            }}
                        />
                    </Badge>
                    <TextField
                        sx={{
                            width: '278px',
                            mt: is1200Breakpoint ? 0.5 : 0,
                            background: '#F5F5F5',
                            borderRadius: 1,
                        }}
                        inputRef={searchRef}
                        size="small"
                        defaultValue={filter.search}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        onKeyUp={event => {
                            onSearchTextChange(event.target.value);
                        }}
                    />
                    {isHiway && (

                        <LoadingButton
                            sx={{
                                height: '38px',
                                width: '82px',
                                borderRadius: '8px',
                                mt: is1200Breakpoint ? 0.5 : 0,
                            }}
                            onClick={downloadRib}
                            variant="outlined"
                            startIcon={<ArticleIcon />}
                            loading={isLoading}
                            loadingPosition="start"
                        >
                            RIB
                        </LoadingButton>
                    )}
                </Box>
            </Grow>

        </Box>
    );
};

AccountBalanceFilter.propTypes = {
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
    setAnchorEl: PropTypes.func.isRequired,
    isInHeader: PropTypes.bool.isRequired,
    integration: PropTypes.object,
};

AccountBalanceFilter.defaultProps = {
    integration: null,
};
