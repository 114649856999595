import {
    faArrowLeft,
    faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Typography} from '@mui/joy';
import React from 'react';
import {useTranslation} from 'react-i18next';
import withJoyUI from '../../../../components/joy-ui/joyui-theme-provider/JoyUiThemePRoviderWrapper';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {ModalsKeys} from '../../../ui/utils/constants';

const modalKey = ModalsKeys.CLOSE_HIWAY_INTEGRATION_UNAVAILABLE_MODAL;

const CloseHiwayIntegrationUnavailableModal = () => {
    const {t} = useTranslation('bank');

    const loading = false;
    const disabled = true;

    return (
        <ModalWrapper
            isV3
            containerSx={{width: '640px'}}
            modalKey={modalKey}
            title={(
                <Typography level="h1">
                    {t('accountArchiving.closeTitle')}
                </Typography>
            )}
            buttons={({handleClose}) => (
                <>
                    <Button
                        fullWidth={true}
                        startDecorator={(
                            <FontAwesomeIcon icon={faArrowLeft} />
                        )}
                        onClick={() => {
                            handleClose();
                        }}
                        variant="outlined"
                        size="lg"
                        color="neutral"
                    >
                        {t('accountArchiving.backButton')}
                    </Button>

                    <Button
                        loading={loading}
                        disabled={disabled}
                        fullWidth={true}
                        startDecorator={(
                            <FontAwesomeIcon icon={faCircleXmark} />
                        )}
                        color="danger"
                        size="lg"
                        variant="soft"
                    >
                        {t('archivingActions.close')}
                    </Button>
                </>
            )}
        >
            <Typography level="body-lg" sx={{color: 'text.secondary'}}>
                {t('accountArchiving.closeErrorText')}
            </Typography>
        </ModalWrapper>
    );
};

export default withJoyUI(CloseHiwayIntegrationUnavailableModal);
