export const JobTypes = {
    DEVELOPER: 'DEVELOPER',
    LEAD_DEVELOPER: 'LEAD_DEVELOPER',
    SOFTWARE: 'SOFTWARE_ARCHITECT',
    DATA_ENGINEER: 'DATA_ENGINEER',
    DEVOPS: 'DEVOPS',
    PHARMACOVIGILANCE: 'PHARMACOVIGILANCE',
    PRODUCT_OWNER: 'PRODUCT_OWNER',
    PRODUCT_MANAGER: 'PRODUCT_MANAGER',
    PROJECT_MANAGER: 'PROJECT_MANAGER',
    SCRUM_MASTER: 'SCRUM_MASTER',
    COACH_AGILE: 'COACH_AGILE',
    CTO: 'CTO',
    INFRA_PROD: 'INFRA_PROD',
    PRODUCT_DESIGNER: 'PRODUCT_DESIGNER',
    BUSINESS_INTELLIGENCE: 'BUSINESS_INTELLIGENCE',
    BUSINESS_ANALYST: 'BUSINESS_ANALYST',
    ARCHITECT: 'ARCHITECT',
    UX_UI_DESIGNER: 'UX_UI_DESIGNER',
    UX_WRITER: 'UX_WRITER',
    ARTISTIC_DIRECTION: 'ARTISTIC_DIRECTION',
    NETWORK_TELECOM: 'NETWORK_TELECOM',
    CLOUD: 'CLOUD',
    CPO: 'CPO',
    OTHER: 'OTHER',
    PMO: 'PMO',
};

export const CareJobTypes = {
    CAREGIVER: 'CAREGIVER',
    // AMBULANCE_DRIVER: 'AMBULANCE_DRIVER', // Removed tmp as per HPD-5359
    DENTAL_ASSISTANT: 'DENTAL_ASSISTANT',
    HEARING_AID_SPECIALIST: 'HEARING_AID_SPECIALIST',
    // CHILDCARE_ASSISTANT: 'CHILDCARE_ASSISTANT', // Removed tmp as per HPD-5359
    MEDICAL_BIOLOGIST: 'MEDICAL_BIOLOGIST',
    CARDIOLOGIST: 'CARDIOLOGIST',
    CHIROPRACTOR: 'CHIROPRACTOR',
    SURGEON: 'SURGEON',
    GENETIC_COUNSELOR: 'GENETIC_COUNSELOR',
    DIETITIAN: 'DIETITIAN',
    DOCTOR_OF_MEDICINE: 'DOCTOR_OF_MEDICINE',
    DOSIMETRIST: 'DOSIMETRIST',
    OCCUPATIONAL_THERAPIST: 'OCCUPATIONAL_THERAPIST',
    EPITHETIST: 'EPITHETIST',
    GERIATRICIAN: 'GERIATRICIAN',
    GENERAL_CARE_NURSES: 'GENERAL_CARE_NURSES',
    STATE_CERTIFIED_NURSE_ANESTHETIST: 'STATE_CERTIFIED_NURSE_ANESTHETIST',
    STATE_CERTIFIED_OPERATING_ROOM_NURSE: 'STATE_CERTIFIED_OPERATING_ROOM_NURSE',
    NURSE_CHILDCARE_SPECIALIZED: 'NURSE_CHILDCARE_SPECIALIZED',
    ADVANCED_PRACTICE_NURSE: 'ADVANCED_PRACTICE_NURSE',
    MEDICAL_ELECTROLOGY_MANIPULATOR: 'MEDICAL_ELECTROLOGY_MANIPULATOR',
    MASSEUR_PHYSIOTHERAPIST: 'MASSEUR_PHYSIOTHERAPIST',
    OCULARIST: 'OCULARIST',
    OPTICAL_EYEWEAR_MAKER: 'OPTICAL_EYEWEAR_MAKER',
    SPEECH_THERAPIST: 'SPEECH_THERAPIST',
    ORTHOPTIST: 'ORTHOPTIST',
    ORTHOPEDIST_ORTHETIST: 'ORTHOPEDIST_ORTHETIST',
    ORTHOPROSTHETIST: 'ORTHOPROSTHETIST',
    OSTEOPATH: 'OSTEOPATH',
    CHRIPODIST_PODIATRIST: 'CHRIPODIST_PODIATRIST',
    PHARMACIST: 'PHARMACIST',
    MEDICAL_PHYSICIST: 'MEDICAL_PHYSICIST',
    PODORTHOTIST: 'PODORTHOTIST',
    BASIC_DENTAL_PRACTITIONER_WITH_SPECIALITY: 'BASIC_DENTAL_PRACTITIONER_WITH_SPECIALITY', // DentalPractitionerWithBasicTrainingAndWithSpecialty = ""
    PARHMACY_TECHNICIAN: 'PARHMACY_TECHNICIAN',
    HOSPITAL_PHARMACY_TECHNICIAN: 'HOSPITAL_PHARMACY_TECHNICIAN',
    PSYCHOMOTOR_THERAPIST: 'PSYCHOMOTOR_THERAPIST',
    PSYCHOTHERAPIST: 'PSYCHOTHERAPIST',
    MIDWIFE: 'MIDWIFE',
    STOMATOLOGIST: 'STOMATOLOGIST',
    MEDICAL_LABORATORY_TECHNICIAN: 'MEDICAL_LABORATORY_TECHNICIAN',
    // PSYCHOLOGIST: 'PSYCHOLOGIST', // Removed tmp as per HPD-5359
    // VETERINARIAN: 'VETERINARIAN', // Removed tmp as per HPD-5359
};

export const ContactSource = {
    RECOMMENDATION: 'RECOMMENDATION',
    WEBSITE_LEAD: 'WEBSITE_LEAD',
    LINKEDIN: 'LINKEDIN',
    FACEBOOK: 'FACEBOOK',
    INSTAGRAM: 'INSTAGRAM',
    MEETUP: 'MEETUP',
    WEBINAR: 'WEBINAR',
    CONFERENCE: 'CONFERENCE',
    LEAD: 'LEAD',
    OTHER: 'OTHER',
    // WALK_IN: 'WALK_IN',
};

export const StartContractType = {
    DIRECT_START_WITHOUT_PE: 'DIRECT_START_WITHOUT_PE',
    DIRECT_START_WITH_PE: 'DIRECT_START_WITH_PE',
    RDD_1_MONTH: 'RDD_1_MONTH',
    RDD_4_MONTHS: 'RDD_4_MONTHS',
};

export const StartContractTypeCare = {
    DIRECT_START_WITHOUT_PE: 'DIRECT_START_WITHOUT_PE',
    DIRECT_START_WITH_PE: 'DIRECT_START_WITH_PE',
};

export const ContractStatus = {
    IN_RESEARCH: 'IN_RESEARCH',
    CONTRACT_FOUND: 'CONTRACT_FOUND',
};

export const UserStatus = {
    INVITED: 'INVITED',
    CONTRACT_PENDING: 'CONTRACT_PENDING',
    COMPANY_PENDING: 'COMPANY_PENDING',
    ACTIVE: 'ACTIVE',
    DEACTIVATED: 'DEACTIVATED',
    WORKSHOPS_SCHEDULED: 'WORKSHOPS_SCHEDULED',
    CONTRACT_SIGNED: 'CONTRACT_SIGNED',
};
