import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {ConditionalTooltip} from '../../../../../../../components/tooltip/ConditionalTooltip';

const AccountBalanceTableDataRowDate = ({
    data,
    isNewDate,
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                alignItems: 'center',
                color: 'blueGray.400',
                fontFamily: 'Barlow',
                minWidth: '35px',
                height: '100%',
                minHeight: '80px',
                backgroundColor: 'white !important',
                pl: 2,
            }}
        >
            {isNewDate ? (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    alignItems: 'center',
                    minWidth: '35px',
                    minHeight: '80px',
                    justifyContent: 'center',
                    color: 'v2.blueGray.400',
                }}
                >
                    <Box sx={{
                        fontSize: theme => theme.typography.pxToRem(28),
                        fontWeight: 500,
                        lineHeight: '31px',
                    }}
                    >
                        {data.date.format('D')}
                    </Box>
                    <Box sx={{
                        fontSize: theme => theme.typography.pxToRem(10),
                        fontWeight: 500,
                        textTransform: 'uppercase',
                    }}
                    >
                        {data.date.format('MMM')}
                    </Box>
                </Box>
            ) : (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    alignItems: 'center',
                    minWidth: '35px',
                    height: '100%',
                    minHeight: '80px',
                    position: 'relative',
                }}
                >
                    <Box sx={{
                        borderRight: '1px solid #333',
                        // borderStyle: 'solid',
                        borderColor: 'v2.blueGray.200',
                        display: 'flex',
                        flexGrow: 1,
                        zIndex: 4,
                    }}
                    />
                    <ConditionalTooltip
                        tooltip={
                            data.date.format('DD MMMM YYYY')
                        }
                        placement="left"
                    >
                        <Box sx={{
                            backgroundColor: 'v2.blueGray.200',
                            position: 'absolute',
                            top: `calc(50% - 7px)`,
                            left: `calc(50% - 7px)`,
                            borderRadius: '50%',
                            border: '4px solid white',
                            padding: '3px',
                            zIndex: 5,
                        }}
                        />
                    </ConditionalTooltip>
                </Box>
            )}
        </Box>
    );
};

AccountBalanceTableDataRowDate.propTypes = {
    data: PropTypes.object.isRequired,
    isNewDate: PropTypes.bool.isRequired,
};

export default AccountBalanceTableDataRowDate;
