import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {CompanyRegistrationFileList} from './CompanyRegistrationFileList';
import {IbanBicForm} from './IbanBicForm';
import {InitialStep} from './InitialStep';
import {PageHeader} from '../../../../../components/page-header/PageHeader';
import {isUserCare} from '../../../../../utils/user-roles';
import {BankSelector} from '../../../../bank/store/bank.selector';
import {DocumentSigningWrapper} from '../../../../document/modules/signing/components/DocumentSigningWrapper';
import {UploadSignatureModal} from '../../../../signature/components/UploadSignatureModal';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';
import {CompaniesSelectors} from '../../companies';
import {DataValidatedScreen} from '../../company-setup/components/DataValidatedScreen';
import {OnboardingSelectors} from '../../onboarding/store/onboarding.selectors';
import {OnboardingSteps} from '../../onboarding/utils/onboadingStepsConstant';
import {CompanyRegistrationInternalSubSteps} from '../utils/constants';

export const CompanyRegistration = () => {
    const {t} = useTranslation('freelancerOnboarding');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const internalSubStep = useSelector(OnboardingSelectors.selectInternalSubStep);
    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const company = useSelector(CompaniesSelectors.createCompanyByIdSelector(loggedInUser.defaultCompanyId));

    const integrations = useSelector(BankSelector.selectIntegrations);
    const hasIntegration = !!integrations?.[0];

    const hiwayBankInfo = company?.companyInfo?.hiway_bank_info;
    const isUserCareRole = isUserCare(loggedInUser);

    let stepComponent = (
        <InitialStep />
    );

    if (internalSubStep === CompanyRegistrationInternalSubSteps.IBAN_BIC_FORM) {
        if (hasIntegration && hiwayBankInfo && isUserCareRole) {
            return <DataValidatedScreen isCareIbanBicReplacementScreen={true} />;
        }
        stepComponent = <IbanBicForm hasIntegration={hasIntegration} />;
    }

    if (internalSubStep === CompanyRegistrationInternalSubSteps.FILE_LIST && !hasIntegration) {
        stepComponent = <CompanyRegistrationFileList />;
    }

    if (internalSubStep === CompanyRegistrationInternalSubSteps.DOCUMENT_SIGNING && !hasIntegration) {
        stepComponent = <DocumentSigningWrapper step={OnboardingSteps.COMPANY_REGISTRATION} />;
    }

    return (
        <Box display="grid" gridTemplateRows="min-content 1fr">
            {!isMobileSize && internalSubStep === CompanyRegistrationInternalSubSteps.IBAN_BIC_FORM && (
                <PageHeader>
                    {t('companyRegistrationStep.headline')}
                </PageHeader>
            )}

            {stepComponent}

            <UploadSignatureModal />
        </Box>
    );
};
