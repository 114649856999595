import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {LoadingButton} from '@mui/lab';
import {
    Box, Button, FormControlLabel,
    Radio, RadioGroup, Typography, useMediaQuery,
} from '@mui/material';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {LoadingTypes, useLoadingState} from '../../../loading';
import {UiSelectors} from '../../../ui/store/ui.selector';
import {ModalsKeys} from '../../../ui/utils/constants';
import {BankActions} from '../../store/bank.action';

const modalKey = ModalsKeys.DELETE_INTEGRATION_MODAL;

const DeleteIntegrationModal = () => {
    const dispatch = useDispatch();

    const data = useSelector(UiSelectors.createModalDataSelector(modalKey));
    const isDeleting = useLoadingState(LoadingTypes.DELETE_BANK);

    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const [radioValue, setRadioValue] = useState(null);

    const handleClick = () => {
        dispatch(BankActions.deleteIntegrationAccount({id: data?.integration?.id}));
    };

    return (
        <ModalWrapper
            modalKey={modalKey}
            title={(
                <Typography variant="h4">
                    {t('accountArchiving.deleteTitle')}
                </Typography>
            )}
            buttons={({handleClose}) => (
                <>
                    <Button
                        disableElevation
                        fullWidth={isMobileSize}
                        onClick={() => {
                            handleClose();
                        }}
                        variant="contained"
                        color="blueGray"
                    >
                        {t('accountArchiving.cancelButton')}
                    </Button>

                    <LoadingButton
                        loading={isDeleting}
                        disabled={!radioValue}
                        fullWidth={isMobileSize}
                        startIcon={<DeleteForeverIcon />}
                        loadingPosition="start"
                        onClick={handleClick}
                        color="secondary"
                        variant="contained"
                    >
                        {t('archivingActions.delete')}
                    </LoadingButton>
                </>
            )}
        >
            <Typography sx={{mb: 1}}>
                {t('accountArchiving.deleteText1')}
            </Typography>

            <Box component="ul" sx={{my: 1}}>
                <Box component="li">{t('accountArchiving.deleteItem1')}</Box>
                <Box component="li">{t('accountArchiving.deleteItem2')}</Box>
            </Box>

            <Typography sx={{mb: 1}}>
                {t('accountArchiving.deleteText2')}
            </Typography>

            <RadioGroup
                row
                name="position"
                value={radioValue}
            >
                <Box
                    sx={{
                        border: radioValue === 1 ? '1px solid #3779BE' : '1px solid rgba(0, 0, 0, 0.12)',
                        borderRadius: '16px',
                        px: 2,
                        py: 1,
                        mt: 2,
                        width: '100%',
                        cursor: 'pointer',
                    }}
                    onClick={() => setRadioValue(1)}
                >
                    <FormControlLabel
                        value={1}
                        control={(
                            <Radio sx={{
                                alignItems: 'center',
                            }}
                            />
                        )}
                        label={(
                            <Box>
                                <Box sx={{
                                    fontWeight: 600,
                                    mb: 0.5,
                                }}
                                >
                                    {t('accountArchiving.deleteQuestion')}
                                </Box>

                                <Box>
                                    {t('accountArchiving.deleteAnswer1')}
                                </Box>
                            </Box>
                        )}
                        labelPlacement="end"
                    />
                </Box>

                <Box
                    sx={{
                        border: radioValue === 2 ? '1px solid #3779BE' : '1px solid rgba(0, 0, 0, 0.12)',
                        borderRadius: '16px',
                        px: 2,
                        py: 1,
                        mt: 1,
                        width: '100%',
                        cursor: 'pointer',
                    }}
                    onClick={() => setRadioValue(2)}
                >
                    <FormControlLabel
                        value={2}
                        control={(
                            <Radio sx={{
                                alignItems: 'center',
                            }}
                            />
                        )}
                        label={(
                            <Box>
                                <Box sx={{
                                    fontWeight: 600,
                                    mb: 0.5,
                                }}
                                >
                                    {t('accountArchiving.deleteQuestion')}
                                </Box>

                                <Box>
                                    {t('accountArchiving.deleteAnswer2')}
                                </Box>
                            </Box>
                        )}
                        labelPlacement="end"
                    />
                </Box>
            </RadioGroup>
        </ModalWrapper>
    );
};

export default DeleteIntegrationModal;
