import {Box, MenuItem, MenuList} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {DateRangePicker} from './DateRange';
import * as i18next from '../../../../lib/i18next';

i18next.importLocaleBundle('backoffice');

const today = moment();
const startOfMonth = today.clone().startOf('month');
const endOfMonth = today.clone().endOf('month');
const startOfPrevMonth = today.clone().subtract(1, 'month').startOf('month');
const endOfPrevMonth = today.clone().subtract(1, 'month').endOf('month');

// TODO Add memoization
const getMenuItemSelection = (startDate, endDate) => {
    if (!startDate || !endDate) {
        return {
            isThisMonth: false,
            isPrevMonth: false,
            isCustom: false,
            isNothingSelected: true,
        };
    }

    const isThisMonth = startDate.isSame(startOfMonth, 'day')
        && endDate.isSame(endOfMonth, 'day');

    const isPrevMonth = startDate.isSame(startOfPrevMonth, 'day')
        && endDate.isSame(endOfPrevMonth, 'day');

    const isCustom = !isThisMonth
        && !isPrevMonth;

    return {
        isThisMonth,
        isPrevMonth,
        isCustom,
        isNothingSelected: false,
    };
};

export const DateRangeWithPredefinedOptions = ({
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
    leftMenuAdditionalStyle,
}) => {
    // TODO:Low Menu list and all options could be dynamic
    const menuItemSelect = getMenuItemSelection(startDate, endDate);

    const {t} = useTranslation('backoffice');

    const onThisMonthClick = () => {
        onStartDateChange(startOfMonth);
        onEndDateChange(endOfMonth);
    };

    const onPreviousMonthClick = () => {
        onStartDateChange(startOfPrevMonth);
        onEndDateChange(endOfPrevMonth);
    };

    const onTodayClick = () => {
        onStartDateChange(today);
        onEndDateChange(today);
    };

    const onClearClick = () => {
        onStartDateChange(null);
        onEndDateChange(null);
    };

    return (
        <Box sx={{display: 'flex'}}>
            <MenuList sx={{background: '#FAFAFA', ...leftMenuAdditionalStyle}}>
                <MenuItem
                    selected={menuItemSelect.isThisMonth}
                    onClick={onThisMonthClick}
                >
                    {t('datepicker.this_month')}
                </MenuItem>
                <MenuItem
                    selected={menuItemSelect.isPrevMonth}
                    onClick={onPreviousMonthClick}
                >
                    {t('datepicker.prev_month')}
                </MenuItem>
                <MenuItem
                    selected={menuItemSelect.isCustom}
                    onClick={onTodayClick}
                >
                    {t('datepicker.custom_range')}
                </MenuItem>
                <MenuItem
                    selected={menuItemSelect.isNothingSelected}
                    onClick={onClearClick}
                >
                    {t('datepicker.clear')}
                </MenuItem>
            </MenuList>

            <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={onStartDateChange}
                onEndDateChange={onEndDateChange}
            />
        </Box>
    );
};

DateRangeWithPredefinedOptions.propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    onStartDateChange: PropTypes.func.isRequired,
    onEndDateChange: PropTypes.func.isRequired,
    leftMenuAdditionalStyle: PropTypes.object,
};

DateRangeWithPredefinedOptions.defaultProps = {
    startDate: null,
    endDate: null,
    leftMenuAdditionalStyle: {},
};
