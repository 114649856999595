import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {LoadingButton, LocalizationProvider} from '@mui/lab';
import DatePicker from '@mui/lab/DatePicker';
import {
    Alert, Box, Button, FormControlLabel,
    Radio, RadioGroup, Typography, useMediaQuery,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Checkbox} from '../../../../components/checkbox/Checkbox';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import Yup from '../../../../lib/yup/yup';
import {BE_DATE_FORMAT} from '../../../../utils/constants';
import DropZone from '../../../../v1/components/ui-kit/DropZone';
import {LoadingTypes, useLoadingState} from '../../../loading';
import {UiActions} from '../../../ui/store/ui.action';
import {UiSelectors} from '../../../ui/store/ui.selector';
import {ModalsKeys} from '../../../ui/utils/constants';
import {BankActions} from '../../store/bank.action';

const modalKey = ModalsKeys.ARCHIVE_INTEGRATION_MODAL;

const ARCHIVE_MODAL_VALUES = {
    1: 'ARCHIVE',
    2: 'DELETE',
};

const formElements = {
    selectedAccountAction: 'selectedAccountAction',
    archivingDate: 'archivingDate',
    bankStatements: 'bankStatements',
    userConsent: 'userConsent',
};

const defaultValues = {
    [formElements.selectedAccountAction]: '',
    [formElements.archivingDate]: null,
    [formElements.bankStatements]: [],
    [formElements.userConsent]: false,
};

const createValidationSchema = () => Yup.object().shape({
    selectedAccountAction: Yup.string().equals([ARCHIVE_MODAL_VALUES['1']]).required(),
    archivingDate: Yup.date().required(),
    bankStatements: Yup.array().min(1).max(12),
    userConsent: Yup.boolean().equals([true]).required(),
});

const ArchiveIntegrationModal = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const {
        control,
        handleSubmit: createSubmitHandler,
        formState: {isValid, errors},
        watch,
        reset,
    } = useForm({
        resolver: yupResolver(createValidationSchema(t)),
        defaultValues: defaultValues,
        mode: 'onChange',
    });

    const selectedAccountAction = watch(formElements.selectedAccountAction);

    const resetDataState = () => {
        reset();
    };

    const isArchiving = useLoadingState(LoadingTypes.DELETE_BANK);
    const data = useSelector(UiSelectors.createModalDataSelector(modalKey));

    const handleClick = createSubmitHandler(submitData => {
        dispatch(BankActions.archiveIntegrationAccount({
            integration: data?.integration,
            archivingDate: moment(submitData.archivingDate).format(BE_DATE_FORMAT),
            bankStatements: submitData.bankStatements,
        }));
    });

    const handleDelete = () => {
        resetDataState();
        dispatch(UiActions.setModalData(modalKey, null));
        dispatch(UiActions.setModalData(ModalsKeys.DELETE_INTEGRATION_MODAL, {integration: data?.integration}));

        dispatch(UiActions.setActiveModal(modalKey, false));
        dispatch(UiActions.setActiveModal(ModalsKeys.DELETE_INTEGRATION_MODAL, true));
    };

    const showAdditionalFields = selectedAccountAction === ARCHIVE_MODAL_VALUES['1'];
    const hasAlert = selectedAccountAction === ARCHIVE_MODAL_VALUES['2'];

    return (
        <ModalWrapper
            modalKey={modalKey}
            title={(
                <Typography variant="h4">
                    {t('accountArchiving.archiveTitle')}
                </Typography>
            )}
            handleOpenCB={() => resetDataState()}
            buttons={({handleClose}) => (
                <>
                    <Button
                        disableElevation
                        fullWidth={isMobileSize}
                        startIcon={<ArrowBackIcon />}
                        onClick={() => {
                            handleClose();
                        }}
                        variant="outlined"
                        color="primary"
                    >
                        {t('accountArchiving.backButton')}
                    </Button>

                    <LoadingButton
                        loading={isArchiving}
                        disabled={!isValid}
                        fullWidth={isMobileSize}
                        startIcon={<CancelIcon />}
                        loadingPosition="start"
                        onClick={handleClick}
                        color="secondary"
                        variant="contained"
                    >
                        {t('archivingActions.yesArchive')}
                    </LoadingButton>
                </>
            )}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Typography>
                    {t('accountArchiving.archiveText')}
                </Typography>

                <Alert
                    severity="info"
                    sx={{
                        'color': 'text_v2.secondary',
                        'borderRadius': '12px',
                        '.MuiAlert-icon': {
                            display: 'flex',
                            alignItems: 'center',
                        },
                    }}
                    icon={(
                        <InfoIcon
                            sx={{
                                fontSize: 'inherit',
                                color: 'text_v2.secondary',
                            }}
                        />
                    )}
                >
                    {t('accountArchiving.archiveAlertText')}
                </Alert>
                <Controller
                    control={control}
                    name={formElements.selectedAccountAction}
                    render={({field}) => (
                        <RadioGroup
                            row
                            name={formElements.selectedAccountAction}
                            {...field}
                        >
                            <Box
                                sx={{
                                    borderStyle: 'solid',
                                    borderWidth: '1px',
                                    borderColor: selectedAccountAction === ARCHIVE_MODAL_VALUES['1']
                                        ? 'primary_v2.main'
                                        : 'other_v2.divider',
                                    borderRadius: '16px',
                                    px: 2,
                                    py: 1,
                                    width: '100%',
                                    cursor: 'pointer',
                                }}
                            >
                                <FormControlLabel
                                    value={ARCHIVE_MODAL_VALUES['1']}
                                    control={(
                                        <Radio sx={{
                                            alignItems: 'center',
                                        }}
                                        />
                                    )}
                                    label={(
                                        <Box>
                                            <Box sx={{
                                                fontWeight: 600,
                                                mb: 0.5,
                                            }}
                                            >
                                                {t('accountArchiving.archiveQuestion')}
                                            </Box>

                                            <Box>
                                                {t('accountArchiving.archiveAnswer1')}
                                            </Box>
                                        </Box>
                                    )}
                                    labelPlacement="end"
                                />
                                {
                                    showAdditionalFields
                                    && (
                                        <Box sx={{ml: 4, mt: 1.5}}>
                                            <Controller
                                                control={control}
                                                name={formElements.archivingDate}
                                                render={({field}) => (
                                                    <ArchiveAccountDatepicker
                                                        t={t}
                                                        field={field}
                                                        errors={errors}
                                                    />
                                                )}
                                            />
                                            <Controller
                                                control={control}
                                                name={formElements.bankStatements}
                                                render={({field}) => (
                                                    <ArchiveAccountDropzone
                                                        field={field}
                                                        isMobileSize={isMobileSize}
                                                        t={t}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    )
                                }
                            </Box>
                            <Box
                                sx={{
                                    borderStyle: 'solid',
                                    borderWidth: '1px',
                                    borderColor: selectedAccountAction === ARCHIVE_MODAL_VALUES['2']
                                        ? 'primary_v2.main'
                                        : 'other_v2.divider',
                                    borderRadius: '16px',
                                    px: 2,
                                    py: 1,
                                    mt: 2,
                                    width: '100%',
                                    cursor: 'pointer',
                                }}
                            >
                                <FormControlLabel
                                    value={ARCHIVE_MODAL_VALUES['2']}
                                    control={(
                                        <Radio sx={{
                                            alignItems: 'center',
                                        }}
                                        />
                                    )}
                                    label={(
                                        <Box>
                                            <Box sx={{
                                                fontWeight: 600,
                                                mb: 0.5,
                                            }}
                                            >
                                                {t('accountArchiving.archiveQuestion')}
                                            </Box>

                                            <Box>
                                                {t('accountArchiving.archiveAnswer2')}
                                            </Box>
                                        </Box>
                                    )}
                                    labelPlacement="end"
                                />
                            </Box>
                        </RadioGroup>
                    )}
                />

                {hasAlert && <DeleteAccountAlert handleDelete={handleDelete} t={t} />}

                <Controller
                    control={control}
                    name={formElements.userConsent}
                    render={({field}) => (
                        <FormControlLabel
                            {...field}
                            disabled={selectedAccountAction === ARCHIVE_MODAL_VALUES['2']}
                            control={(
                                <Checkbox />
                            )}
                            label={t('accountArchiving.archiveCheckboxConsent')}
                        />
                    )}
                />
            </Box>
        </ModalWrapper>
    );
};


const DeleteAccountAlert = ({handleDelete, t}) => {
    return (
        <Alert
            sx={{
                backgroundColor: 'warning_v2.main',
                borderRadius: '12px',
            }}
            icon={(
                <WarningAmberIcon sx={{
                    fontSize: 'inherit',
                }}
                />
            )}
            severity="warning"
            variant="filled"
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5,
            }}
            >
                <Box sx={{fontWeight: 600}}>
                    {t('accountArchiving.warningTitle')}
                </Box>
                <Box sx={{
                    fontSize: theme => theme.typography.pxToRem(14),
                    fontWeight: 400,
                }}
                >
                    {t('accountArchiving.warningText')}
                </Box>
                <Button
                    color="inherit"
                    variant="outlined"
                    size="small"
                    sx={{
                        height: '30px',
                        px: 2,
                        mt: 1,
                        alignSelf: 'flex-start',
                    }}
                    startIcon={<CancelIcon />}
                    onClick={handleDelete}
                >
                    {t('archivingActions.delete')}
                </Button>
            </Box>
        </Alert>
    );
};

DeleteAccountAlert.propTypes = {
    handleDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const ArchiveAccountDatepicker = ({t, field, errors}) => {
    return (
        <LocalizationProvider dateLibInstance={moment} dateAdapter={AdapterMoment}>
            <DatePicker
                {...field}
                openTo="day"
                format={moment.localeData().longDateFormat('L')}
                views={['year', 'month', 'day']}
                label={t('accountArchiving.archiveDate')}
                renderInput={params => (
                    <TextField
                        sx={{
                            '& .MuiInputBase-root': {
                                backgroundColor: '#fff',
                            },
                        }}
                        required
                        variant="outlined"
                        fullWidth
                        {...params}
                        error={!!errors?.[formElements.archivingDate]}
                        helperText={errors?.[formElements.archivingDate]?.message}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

ArchiveAccountDatepicker.propTypes = {
    t: PropTypes.func.isRequired,
    field: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const ArchiveAccountDropzone = ({field, isMobileSize, t}) => {
    return (
        <Box
            sx={{
                bgcolor: 'gray_v2.50',
                px: 2,
                pb: 2,
                pt: 1,
                mt: 2,
                borderRadius: 4,
            }}
        >
            <Typography variant="subtitle2_v2">
                {t('accountArchiving.archiveDropZoneTitle')}
            </Typography>
            <Typography variant="body2" color="text.disabled" sx={{mb: 2}}>
                {t('accountArchiving.archiveDropZoneText')}
            </Typography>
            <DropZone
                name="archive_account_bank_statements"
                isFileRemovalDisabled={true}
                files={field.value}
                onDropAccepted={field.onChange}
                onDeleteFile={() => {}}
                buttonText={t('common:upload')}
                setIsOpen={true}
                hasChips={true}
                fileLimit={12}
                isMultiple={true}
                acceptFiles="application/pdf"
                readableFileExtension="PDF"
                isInfoTextVisible={isMobileSize}
                dropZoneCustomText={(
                    <Typography
                        variant="body2"
                        color="text.disabled"
                        sx={{
                            letterSpacing: '3px',
                            mt: 0.2,
                        }}
                    >
                        {t('accountArchiving.archiveDropZoneMaxFileText')}
                    </Typography>
                )}
                isMaxFileMessageDisabled={true}
            />
        </Box>
    );
};

ArchiveAccountDropzone.propTypes = {
    field: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    isMobileSize: PropTypes.bool.isRequired,
};

export default ArchiveIntegrationModal;
