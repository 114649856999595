import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadIcon from '@mui/icons-material/Download';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import {LoadingButton} from '@mui/lab';
import {Alert, Paper} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import DocumentSigningToolbar from './DocumentSigningToolbar';
import {ButtonAction} from '../../../../../components/buttons/ButtonAction';
import {ContractSelectors} from '../../../../contract/store/contract.selector';
import {LoadingTypes, useLoadingState} from '../../../../loading';
import {SignatureSelectors} from '../../../../signature/store/signature.selector';
import {UiActions} from '../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../ui/utils/constants';
import {DocumentActions} from '../../../store/document.action';
import {SigningSelectors} from '../store/signing.selector';
import {DOCUMENT_SIGNING_TYPES} from '../utils/constants';

export const DocumentSigningMobile = ({
    MainActionComponent,
    type,
    areAllDocumentsSigned,
    isLoadingNextStep,
    onNextClick,
    allDocumentsSignedCallbacks,
    isSigningAdditionalDocuments,
}) => {
    const {t} = useTranslation('document');

    const dispatch = useDispatch();

    const contractData = useSelector(ContractSelectors.selectData);
    const isSigned = useSelector(SigningSelectors.selectCurrentDocumentIsSigned);

    const signatureUrl = useSelector(SignatureSelectors.selectUrl);

    const isSigning = useSelector(SigningSelectors.selectIsSigningCurrentDocument);

    const isDownloadingContract = useLoadingState(LoadingTypes.DOWNLOAD_DOCUMENT);

    const documentId = useSelector(SigningSelectors.selectCurrentDocumentId);

    return (
        <Paper radius={8} elevation={2} sx={{p: 2}}>
            <Alert
                severity={isSigned ? 'success' : 'warning'}
                sx={{mt: 1, borderRadius: 3, color: isSigned ? 'rgba(0, 0, 0, 0.6)' : 'warning.dark'}}
                icon={isSigned ? <CheckCircleIcon sx={{color: theme => theme.palette.buttonSuccess.main}} /> : <HourglassTopIcon sx={{color: 'warning.dark'}} />}
            >
                {!isSigned ? t('signing.awaitingSignature') : t('signing.contractSigned')}
            </Alert>

            <DocumentSigningToolbar
                MainActionComponent={MainActionComponent}
                type={type}
                areAllDocumentsSigned={areAllDocumentsSigned}
                isSigningAdditionalDocuments={isSigningAdditionalDocuments}
                isSigning={isSigning}
                isLoadingNextStep={isLoadingNextStep}
                documentId={documentId}
                isSigned={isSigned}
                onNextClick={onNextClick}
                allDocumentsSignedCallbacks={allDocumentsSignedCallbacks}
            />

            {isSigned && (
                <LoadingButton
                    sx={{py: 0.75, borderRadius: 2, mt: 1}}
                    startIcon={<DownloadIcon />}
                    variant="outlined"
                    size="small"
                    loading={isDownloadingContract}
                    disabled={!MainActionComponent || !isSigned || !signatureUrl}
                    loadingPosition="start"
                    onClick={() => {
                        if (!MainActionComponent || !isSigned || !signatureUrl) {
                            return;
                        }
                        dispatch(DocumentActions.getDocument(contractData.documentId, true));
                    }}
                    fullWidth
                >
                    {t('signing.downloadPDFMobile')}
                </LoadingButton>
            )}

            {isSigned && (
                <ButtonAction
                    startIcon={<PublishedWithChangesIcon />}
                    size="medium"
                    onClick={() => dispatch(UiActions.setActiveModal(ModalsKeys.UPLOAD_SIGNATURE, true))}
                    fullWidth
                    variant="outlined"
                    sx={{mt: 1, py: 0.7, borderRadius: 2, fontSize: theme => theme.typography.pxToRem(14)}}
                >
                    {t('signing.mySignatureTitle')}
                </ButtonAction>
            )}
        </Paper>
    );
};

DocumentSigningMobile.propTypes = {
    MainActionComponent: PropTypes.any,
    areAllDocumentsSigned: PropTypes.bool,
    isLoadingNextStep: PropTypes.bool,
    onNextClick: PropTypes.func.isRequired,
    type: PropTypes.oneOf(Object.values(DOCUMENT_SIGNING_TYPES)).isRequired,
    allDocumentsSignedCallbacks: PropTypes.objectOf(PropTypes.func).isRequired,
    isSigningAdditionalDocuments: PropTypes.bool.isRequired,
};

DocumentSigningMobile.defaultProps = {
    MainActionComponent: null,
    areAllDocumentsSigned: false,
    isLoadingNextStep: false,
};
