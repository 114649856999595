import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {LoadingButton} from '@mui/lab';
import {Fab, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {DocumentSigning} from './DocumentSigning';
import Alert from '../../../../../v1/components/ui-kit/Alert/Alert';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {AccountingSelector} from '../../../../company-profile/modules/accounting/store/accounting.selector';
import {DEPOSIT_OF_ACCOUNTS_STATUS} from '../../../../company-profile/modules/formalities/utils/constants';
import {CompanyRegistrationInternalSubSteps} from '../../../../freelancer/modules/company-registration/utils/constants';
import {CompanySetupSubSteps} from '../../../../freelancer/modules/company-setup/utils/constants';
import {OnboardingActions} from '../../../../freelancer/modules/onboarding/store/onboarding.action';
import {OnboardingSteps} from '../../../../freelancer/modules/onboarding/utils/onboadingStepsConstant';
import {UploadSignatureModal} from '../../../../signature/components/UploadSignatureModal';
import {DatabaseSelectors} from '../../database/store/database.selector';
import {SigningActions} from '../store/signing.action';
import {SigningSelectors} from '../store/signing.selector';

export const DocumentSigningWrapper = ({step, type, isBackButtonVisible}) => {
    const dispatch = useDispatch();

    // Params
    const [searchParams] = useSearchParams();
    const year = useMemo(() => searchParams.get('year'), [searchParams]);

    const currentDocumentId = useSelector(SigningSelectors.selectCurrentDocumentId);
    const documents = useSelector(DatabaseSelectors.selectDocuments);
    const numberOfDocuments = useSelector(DatabaseSelectors.selectNumberOfDocuments());
    const currentDocumentIndex = useSelector(DatabaseSelectors.selectDocumentIndex(currentDocumentId));

    const annualAccounts = useSelector(AccountingSelector.selectAnnualAccounts);
    const currentAnnualAccount = annualAccounts[year];

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const {t} = useTranslation('document');
    const {t: dt} = useTranslation('documents');

    const isSigningAdditionalDocuments = useSelector(SigningSelectors.selectIsSigningAdditionalDocuments);
    const goToDocumentsClick = () => {
        if (step !== OnboardingSteps.COMPANY_REGISTRATION) {
            dispatch(OnboardingActions.setInternalSubStep(CompanySetupSubSteps.DOCUMENT_GENERATION));
            dispatch(OnboardingActions.setSubStep(CompanySetupSubSteps.DOCUMENT_GENERATION));
        } else {
            dispatch(OnboardingActions.setInternalSubStep(CompanyRegistrationInternalSubSteps.FILE_LIST));
            dispatch(OnboardingActions.setSubStep(CompanyRegistrationInternalSubSteps.FILE_LIST));
        }
    };

    const onPreviousDocumentClick = () => {
        if (!currentDocumentIndex || currentDocumentIndex - 2 < 0) {
            return;
        }
        const document = Object.values(documents).sort((previous, next) => {
            const previousType = previous.type.toUpperCase();
            const nextType = next.type.toUpperCase();

            return (previousType < nextType) ? -1 : (previousType > nextType) ? 1 : 0;
        })[currentDocumentIndex - 2];

        dispatch(AnimationActions.storeNextDispatch(
            SigningActions.setCurrentCompanyDocument(document.id),
        ));
        dispatch(AnimationActions.setIsAnimationActive(false));
    };

    const onNextDocumentClick = () => {
        if (!currentDocumentIndex || currentDocumentIndex >= numberOfDocuments) {
            return;
        }
        const document = Object.values(documents).sort((previous, next) => {
            const previousType = previous.type.toUpperCase();
            const nextType = next.type.toUpperCase();

            return (previousType < nextType) ? -1 : (previousType > nextType) ? 1 : 0;
        })[currentDocumentIndex];

        dispatch(AnimationActions.storeNextDispatch(
            SigningActions.setCurrentCompanyDocument(document.id),
        ));
        dispatch(AnimationActions.setIsAnimationActive(false));
    };

    useEffect(() => {
        if (!currentDocumentId && documents && Object.values(documents).length > 0) {
            dispatch(SigningActions.openNextDocument());
        }
    }, [currentDocumentId, dispatch, documents]);

    return (
        <Box display="grid" gridTemplateRows="min-content 1fr">
            {currentAnnualAccount?.status === DEPOSIT_OF_ACCOUNTS_STATUS.AWAITING_PAYMENT && (
                <Alert
                    severity="info"
                    title={t('companies:formalities.depositOfAccounts.notifications.paymentAlertTitle')}
                    message={(
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <span>
                                {t('companies:formalities.depositOfAccounts.notifications.paymentAlertMessage')}
                            </span>
                            <span style={{fontWeight: 500}}>
                                {t('companies:formalities.depositOfAccounts.notifications.paymentAlertAmountMessage', {amount: currentAnnualAccount?.payLinkInfo?.amount})}
                            </span>
                        </Box>
                    )}
                    elevation={0}
                    sx={{
                        mt: 1,
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: 'v2.light.info.main',
                        borderRadius: '12px',
                    }}
                />
            )}

            <Typography
                variant="h4"
                sx={{mb: 1}}
            >
                {t('signing.title')}
            </Typography>

            <Box
                display="grid"
                gridTemplateColumns={!isMobileSize ? '1fr 238px' : '1fr 1fr'}
                gap={2}
                sx={isMobileSize ? {mx: 2} : {}}
            >
                <Typography
                    color="text.disabled"
                    variant="subtitle2"
                    sx={{fontWeight: 'fontWeightMedium', mb: 2}}
                >
                    {(!isSigningAdditionalDocuments && isBackButtonVisible) && (
                        <LoadingButton
                            variant="outlined"
                            startIcon={<ArrowBackIcon />}
                            onClick={goToDocumentsClick}
                            color="secondary"
                            size="small"
                            sx={{mr: 2, py: '3px', borderRadius: '6px'}}
                        >
                            {t('signing.subTextButton')}
                        </LoadingButton>
                    )}
                    {!isMobileSize && documents[currentDocumentId] && (
                        <Box component="span" sx={{lineHeight: '30.75px'}}>
                            {dt(`documentsDatabaseNames.${documents[currentDocumentId].type}`)}
                        </Box>
                    )}
                </Typography>

                <Box sx={!isMobileSize
                    ? {display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, mt: -1}
                    : {display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, mt: -2}}
                >
                    <Fab
                        size="medium"
                        color="default"
                        disabled={currentDocumentIndex < 2}
                        aria-label="previous-document"
                        onClick={onPreviousDocumentClick}
                        sx={{
                            background: '#fff',
                            boxShadow: '0px 3px 1px -2px rgba(11, 35, 51, 0.05), 0px 2px 2px rgba(11, 35, 51, 0.07), 0px 1px 5px rgba(11, 35, 51, 0.09);',
                        }}
                    >
                        <ChevronLeftIcon />
                    </Fab>

                    <Typography variant="h5">{currentDocumentIndex} {t('signing.of')} {numberOfDocuments}</Typography>

                    <Fab
                        size="medium"
                        color="default"
                        aria-label="next-document"
                        disabled={currentDocumentIndex === numberOfDocuments}
                        onClick={onNextDocumentClick}
                        sx={{
                            background: '#fff',
                            boxShadow: '0px 3px 1px -2px rgba(11, 35, 51, 0.05), 0px 2px 2px rgba(11, 35, 51, 0.07), 0px 1px 5px rgba(11, 35, 51, 0.09);',
                        }}
                    >
                        <ChevronRightIcon />
                    </Fab>
                </Box>
            </Box>

            <DocumentSigning type={type} annualAccount={currentAnnualAccount} />

            <UploadSignatureModal />
        </Box>
    );
};

DocumentSigningWrapper.propTypes = {
    step: PropTypes.string,
    type: PropTypes.string,
    isBackButtonVisible: PropTypes.bool,
};
DocumentSigningWrapper.defaultProps = {
    step: OnboardingSteps.COMPANY_SETUP,
    type: undefined,
    isBackButtonVisible: true,
};
