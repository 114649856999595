import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import DownloadIcon from '@mui/icons-material/Download';
import {Button, CircularProgress, Slide, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {useEffect} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link, generatePath} from 'react-router-dom';
import {TermsAndConditionsForm} from './TermsAndConditionsForm';
import IllustrationRocket from '../../../../../assets/jpg/illustration-rocket.png';
import writeEmoji from '../../../../../assets/png/write-emoji.png';
import {FreelancerOnboardingContract} from '../../../../../assets/wrapped-svg/freelancer-onboarding-contract';
import {FreelancerOnboardingContractMobile} from '../../../../../assets/wrapped-svg/freelancer-onboarding-contract-mobile';
import {ButtonAction} from '../../../../../components/buttons/ButtonAction';
import {PageHeader} from '../../../../../components/page-header/PageHeader';
import TextEmoji from '../../../../../components/text-emoji/TextEmoji';
import Banner from '../../../../../layout/ContainerWithBigImage/Banner';
import {ContainerHeaderStep1} from '../../../../../layout/ContainerWithBigImage/modules/ContainerHeaderStep1';
import {importLocaleBundle} from '../../../../../lib/i18next';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {getMyCompany} from '../../../../../v1/app/my-companies/myCompanies.actions';
import {selectMyCompany} from '../../../../../v1/app/my-companies/myCompanies.selectors';
import {ContactInformationForm} from '../../../../../v1/components/setup-company/ContactInformationForm';
import {getIsRegistrationWithExistingCompany} from '../../../../../v1/utils';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../../animations/utils/constants';
import {ContractActions} from '../../../../contract/store/contract.action';
import {ContractSelectors} from '../../../../contract/store/contract.selector';
import {DocumentSigning} from '../../../../document/modules/signing/components/DocumentSigning';
import {DocumentActions} from '../../../../document/store/document.action';
import {LoadingTypes, useLoadingState} from '../../../../loading';
import {UploadSignatureModal} from '../../../../signature/components/UploadSignatureModal';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';
import {FreelancerSelectors} from '../../../store/freelancer.selector';
import {OnboardingActions} from '../../onboarding/store/onboarding.action';
import {OnboardingSelectors} from '../../onboarding/store/onboarding.selectors';
import {OnboardingSteps} from '../../onboarding/utils/onboadingStepsConstant';
import {WorkshopsActions} from '../../workshops/store/workshops.action';
import {ContractSigningActions} from '../store/contract-signing.actions';
import {ContractSigningInternalSubSteps, ContractSigningSubSteps} from '../utils/constants';

importLocaleBundle('freelancerOnboarding');

export const ContractSigning = () => {
    const internalSubStep = useSelector(OnboardingSelectors.selectInternalSubStep);
    const subStep = useSelector(OnboardingSelectors.selectSubStep);

    let stepComponent = (
        <InitialStep />
    );

    if (
        internalSubStep === ContractSigningInternalSubSteps.PERSONAL_ADDRESS
        || subStep === ContractSigningSubSteps.ENTER_PERSONAL_ADDRESS
    ) {
        stepComponent = (
            <ContactInformationForm
                hasSubmitModificationsButton={false}
                currentStep={OnboardingSteps.CONTRACT_SIGNING}
            />
        );
    }

    if (
        internalSubStep === ContractSigningInternalSubSteps.DOCUMENT_SIGNING
        || subStep === ContractSigningSubSteps.SIGN_CONTRACT
    ) {
        stepComponent = (
            <DocumentSigning
                MainActionComponent={TermsAndConditionsForm}
            />
        );
    }

    if (internalSubStep === ContractSigningInternalSubSteps.FINAL_SCREEN) {
        stepComponent = (
            <FinalStep />
        );
    }

    return (
        <Box display="grid" gridTemplateRows="min-content 1fr">


            {stepComponent}

            <UploadSignatureModal />
        </Box>
    );
};


const InitialStep = () => {
    const dispatch = useDispatch();

    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const {t} = useTranslation('freelancerOnboarding');

    const user = useSelector(LoggedInUserSelectors.selectLoggedInUser);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const subStepInitialScreenNextClick = () => {
        dispatch(AnimationActions.storeNextDispatch(
            dispatch(OnboardingActions.setSubStep(ContractSigningSubSteps.ENTER_PERSONAL_ADDRESS)),
        ));
        dispatch(AnimationActions.setIsSubAnimationActive(false));
        dispatch(OnboardingActions.setInternalSubStep(ContractSigningInternalSubSteps.PERSONAL_ADDRESS));
    };

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    return (
        <>
            {!isMobileSize && (
                <PageHeader>
                    {t('contractSigning.pageTitle')}
                </PageHeader>
            )}
            <Slide in={isAnimationActive} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
                <div>
                    <Banner
                        headline={t('contractSigning.Headline')}
                        header={(
                            <ContainerHeaderStep1
                                mainText={t('contractSigning.HeaderBlack')}
                                colorText={user.firstName}
                            />
                        )}
                        text={t('contractSigning.Text')}
                        Illustration={(
                            <Box
                                component="div"
                                sx={!isMobileSize ? {
                                    mr: 17,
                                    ml: 10,
                                    mb: -0.8,
                                } : {
                                    position: 'absolute',
                                    right: '20px',
                                }}
                            >
                                <img src={IllustrationRocket} alt="Big Icon" width={isMobileSize ? '95%' : '291px'} />
                            </Box>
                        )}
                        nextButtonText={t('contractSigning.NextButtonText')}
                        onNextClick={subStepInitialScreenNextClick}
                    />
                </div>
            </Slide>
        </>
    );
};

const FinalStep = () => {
    const dispatch = useDispatch();

    const {t} = useTranslation('freelancerOnboarding');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const contractData = useSelector(ContractSelectors.selectData);

    const isLoading = useLoadingState(LoadingTypes.DOWNLOAD_DOCUMENT);
    const isGoingToWorkshopStep = useLoadingState(LoadingTypes.GO_TO_WORKSHOP_STEP);
    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const freelancer = useSelector(FreelancerSelectors.selectAccount);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    const progress = useSelector(OnboardingSelectors.selectProgress);
    useEffect(() => {
        dispatch(OnboardingActions.setProgress({
            ...progress,
            [OnboardingSteps.CONTRACT_SIGNING]: {
                ...progress[OnboardingSteps.CONTRACT_SIGNING],
                isCompleted: true,
            },
        }));
        dispatch(OnboardingActions.setSubStep(null));

        if (!contractData) {
            dispatch(ContractActions.getContractData());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const company = useSelector(selectMyCompany);
    useEffect(() => {
        if (!company && freelancer?.defaultCompanyId) {
            dispatch(getMyCompany(freelancer.defaultCompanyId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [freelancer]);
    const registrationType = company?.enterpriseInformation?.registrationType;
    const registrationWithExistingCompany = getIsRegistrationWithExistingCompany(registrationType);
    const subStepFinalScreenNextClick = () => {
        dispatch(AnimationActions.storeNextDispatch(
            registrationWithExistingCompany
                ? WorkshopsActions.goToCompanySetup()
                : ContractSigningActions.goToWorkshopStep(),
        ));
        dispatch(AnimationActions.setIsSubAnimationActive(false));
    };

    const onDownloadContractClick = () => {
        dispatch(DocumentActions.getDocument(contractData.documentId, true));
    };

    return (
        <Slide in={isAnimationActive} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
            <div>
                <Banner
                    header={(
                        <>
                            <Trans t={t} i18nKey="contractSigning.finalHeadline" components={{bold: <Box component="span" sx={{color: 'rgba(76, 175, 80, 1)'}} />}} />
                            <TextEmoji src={writeEmoji} alt="write emoji" />
                        </>
                    )}
                    text={t('contractSigning.finalSubtext')}
                    Illustration={<FinalStepBannerImage />}
                    nextText={t('contractSigning.finalNextText')}
                    nextButtonText={t('contractSigning.finalNext')}
                    onNextClick={subStepFinalScreenNextClick}
                    NextButtonProps={{
                        isLoading: isGoingToWorkshopStep,
                        loadingPosition: 'end',
                    }}
                    content={!isMobileSize ? (
                        <Box sx={{mt: 3}}>
                            <ButtonAction
                                startIcon={isLoading ? <CircularProgress size={20} /> : <DownloadIcon />}
                                onClick={onDownloadContractClick}
                                isLoading={isLoading}
                                loadingPosition="start"
                            >
                                {t('contractSigning.finalDownload')}
                            </ButtonAction>

                            <Button
                                startIcon={<DocumentScannerIcon />}
                                variant="outlined"
                                component={Link}
                                size="large"
                                sx={{
                                    py: theme => theme.spacing(1.9),
                                    fontSize: theme => theme.typography.pxToRem(15),
                                    ml: 2,
                                }}
                                to={generatePath(`${RoutePaths.DOCUMENTS}`, {
                                    freelancerId: loggedInUser.id,
                                    companyId: freelancer.defaultCompanyId,
                                })}
                            >
                                {t('contractSigning.finalDocuments')}
                            </Button>
                        </Box>
                    ) : (
                        <Box sx={{mt: 2, display: 'flex'}}>
                            <ButtonAction
                                startIcon={isLoading
                                    ? <CircularProgress size={20} />
                                    : <DownloadIcon />}
                                onClick={onDownloadContractClick}
                                sx={{py: 0.7, width: '95%'}}
                            >
                                {t('contractSigning.finalDownloadMobile')}
                            </ButtonAction>
                            <Button
                                component={Link}
                                startIcon={<DocumentScannerIcon />}
                                variant="outlined"
                                sx={{ml: 0.5, py: 0.7, width: '95%'}}
                                to={generatePath(`${RoutePaths.DOCUMENTS}`, {
                                    freelancerId: loggedInUser.id,
                                    companyId: freelancer.defaultCompanyId,
                                })}
                            >
                                {t('contractSigning.finalDocumentsMobile')}
                            </Button>
                        </Box>
                    )}
                    cardSx={!isMobileSize ? {
                        background: 'linear-gradient(270deg, #F1F8E9 0%, #FFFFFF 55.73%)',
                    } : {
                        background: 'linear-gradient(360deg, #F1F8E9 0%, #FFFFFF 100%)',
                    }}
                />
            </div>
        </Slide>
    );
};

const FinalStepBannerImage = () => {
    const {t} = useTranslation('freelancerOnboarding');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const user = useSelector(LoggedInUserSelectors.selectLoggedInUser);

    if (isMobileSize) {
        return (
            <Box sx={{position: 'relative'}}>
                <Box
                    sx={{
                        height: '91px',
                        background: '#FFF59D',
                        minWidth: '138px',
                        position: 'absolute',
                        bottom: '60px',
                        transform: 'rotate(-4deg)',
                        boxShadow: '4px 5px 5px -4px rgba(0, 0, 0, 0.3)',
                        px: 1.5,
                        py: 1,
                        color: '#763601',
                    }}
                >
                    <Box>
                        <Typography sx={{fontSize: '13px'}}>
                            {t('signContract')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h5" sx={{fontSize: '17px'}}>
                            {user.firstName} <br />
                            {user.lastName}
                        </Typography>
                    </Box>
                </Box>
                <FreelancerOnboardingContractMobile sx={{width: '252px', height: '248px'}} />
            </Box>
        );
    }

    return (
        <Box sx={{position: 'relative'}}>
            <Box
                sx={{
                    height: '110px',
                    background: '#FFF59D',
                    minWidth: '166px',
                    position: 'absolute',
                    bottom: '20px',
                    transform: 'rotate(4deg)',
                    boxShadow: '4px 5px 5px -4px rgba(0, 0, 0, 0.3)',
                    px: 2.5,
                    py: 2,
                    color: '#763601',
                }}
            >
                <Box>
                    {t('signContract')}
                </Box>
                <Box>
                    <Typography variant="h5">
                        {user.firstName} <br />
                        {user.lastName}
                    </Typography>
                </Box>
            </Box>
            <FreelancerOnboardingContract
                sx={{width: '415px', height: '432px'}}
            />
        </Box>
    );
};
