import {all, call, delay, fork, put, select, takeLatest} from 'redux-saga/effects';

import * as actions from './companyDocuments.actions';
import {createCompanyDocumentsSelector} from './companyDocuments.selectors';
import {watchCurrentDocumentSagas} from './currentDocument/currentDocument.sagas';
import {watchUploadDocumentsSaga} from './uploadDocuments/uploadDocuments.sagas';
import {push} from '../../../../lib/router/connected-router-saga';
import {DOCUMENT_CATEGORIES, DOCUMENT_CONTEXTS, DOCUMENT_STATUSES} from '../../../config/constants/documentConstants';
import {ROUTE_PATHS} from '../../../navigation/routePaths';
import {
    deleteDocumentRequest,
    generateCompanyDocumentsRequest,
    getCompanyDocumentsRequest,
    getHiwayDocuments,
} from '../../api/providers/company/company.provider';
import {loadCompaniesForCurrentUser, loadFreelancerAccountSaga} from '../../freelancer/freelancer.sagas';
import {createFreelancerCompanyByIdSelector} from '../../freelancer/freelancer.selectors';
import {safe} from '../../safeSaga';
import {getCompanyId} from '../../utils/get-company-id';
import {getFreelancerId} from '../../utils/get-freelancer-id';
import {COMPANY_STATUSES} from '../setupCompany/setupCompany.constants';

export const getCompanyDocumentsSaga = function* (freelancerId, companyId, documentContext) {
    yield put(actions.setIsCompanyDocumentsLoading(true));

    const data = yield call(getCompanyDocumentsRequest, freelancerId, companyId, documentContext);

    yield put(actions.storeCompanyDocuments(data));

    const atLastOnePayCategoryDocumentExists = !data
        ? false
        : !!(Object.values(data).find(document => DOCUMENT_CATEGORIES.PAY === document.category));
    yield put(actions.storeAtLastOnePayCategoryDocumentExists(atLastOnePayCategoryDocumentExists));

    yield put(actions.setIsCompanyDocumentsLoading(false));

    const documents = yield select(createCompanyDocumentsSelector());

    const isDocumentGenerationInProgress = documents?.some(({status}) => status === DOCUMENT_STATUSES.GENERATING);

    if (isDocumentGenerationInProgress) {
        yield fork(pollGeneratedDocuments);
    }

    return documents;
};

const getCompanyDocumentsWorkerSaga = function* ({payload}) {
    const {freelancerId, companyId, documentContext} = payload;

    yield call(getCompanyDocumentsSaga, freelancerId, companyId, documentContext);
};

const getHiwayDocumentsSaga = function* () {
    const data = yield call(getHiwayDocuments);

    yield put(actions.storeHiwayDocuments(data));
};

const generateCompanyDocumentsSaga = function* () {
    yield put(actions.setIsCompanyDocumentsLoading(true));

    const freelancerId = yield call(getFreelancerId);
    const companyId = yield call(getCompanyId);

    yield call(generateCompanyDocumentsRequest, freelancerId, companyId);
};

const regenerateCompanyDocumentsSaga = function* () {
    const freelancerId = yield call(getFreelancerId);
    const companyId = yield call(getCompanyId);

    yield call(generateCompanyDocumentsSaga);
    yield call(getCompanyDocumentsSaga, freelancerId, companyId, DOCUMENT_CONTEXTS.SIGNABLE);
};

const POLL_INTERVAL = 600; // milliseconds
const POLL_MAX_DURATION = 60000; // milliseconds
export const pollGeneratedDocuments = function* () {
    let pollDuration = 0;

    yield put(actions.setHasDocumentGenerationError(false));
    yield put(actions.setIsCompanyDocumentsLoading(true));

    const freelancerId = yield call(getFreelancerId);
    const companyId = yield call(getCompanyId);

    while (true) {
        const data = yield call(getCompanyDocumentsRequest, freelancerId, companyId, DOCUMENT_CONTEXTS.SIGNABLE);

        yield put(actions.storeCompanyDocuments(data));

        if (!pollDuration) {
            yield put(actions.setIsCompanyDocumentsLoading(false));
        }

        const documents = yield select(createCompanyDocumentsSelector());

        const isDocumentGenerationInProgress = documents?.some(({status}) => status === DOCUMENT_STATUSES.GENERATING);

        if (pollDuration === POLL_MAX_DURATION) {
            yield put(actions.setHasDocumentGenerationError(true));
            break;
        }

        if (!isDocumentGenerationInProgress) {
            break;
        }

        pollDuration += POLL_INTERVAL;
        yield delay(POLL_INTERVAL);
    }
};

const deleteDocumentSaga = function* (freelancerId, companyId, documentId) {
    yield put(actions.setIsCompanyDocumentsLoading(true));

    yield call(deleteDocumentRequest, freelancerId, companyId, documentId);

    yield call(getCompanyDocumentsSaga, freelancerId, companyId, DOCUMENT_CONTEXTS.DATABASE);

    yield put(actions.setIsCompanyDocumentsLoading(false));
};

const deleteDocumentWorkerSaga = function* ({payload: {freelancerId, companyId, documentId}}) {
    if (!freelancerId) {
        freelancerId = yield call(getFreelancerId);
    }

    if (!companyId) {
        companyId = yield call(getCompanyId);
    }

    yield call(deleteDocumentSaga, freelancerId, companyId, documentId);
};

/**
 * For administrators - it uses parameters from URL; for freelancers - it uses Redux as source of truth.
 * @returns {void}
 */
export const companyDocumentsLoaderSaga = function* ({payload}) {
    let {params: {companyId, freelancerId}, documentContext} = payload || {};

    if (!freelancerId) {
        freelancerId = yield call(getFreelancerId);
    }

    if (!companyId) {
        companyId = yield call(getCompanyId);
    }

    yield call(loadFreelancerAccountSaga, freelancerId);

    yield call(getCompanyDocumentsSaga, freelancerId, companyId, documentContext);
};

export const generateDocumentsAccessControl = function* ({payload}) {
    const {params: {companyId}} = payload || {};

    yield call(loadCompaniesForCurrentUser);

    const company = yield select(createFreelancerCompanyByIdSelector(companyId));

    if (!company) {
        yield put(push(ROUTE_PATHS.DASHBOARD));

        return;
    }

    const {status} = company;

    if (status !== COMPANY_STATUSES.PENDING_INIT_SIGS && status !== COMPANY_STATUSES.PENDING_FINAL_SIGS) {
        yield put(push(ROUTE_PATHS.DASHBOARD));
    }
};

export const watchCompanyDocumentsSagas = function* () {
    yield all([
        fork(watchCurrentDocumentSagas),
        fork(watchUploadDocumentsSaga),
        takeLatest(actions.GET_COMPANY_DOCUMENTS, safe(getCompanyDocumentsWorkerSaga)),
        takeLatest(actions.REGENERATE_COMPANY_DOCUMENTS, safe(regenerateCompanyDocumentsSaga)),
        takeLatest(actions.DELETE_DOCUMENT, safe(deleteDocumentWorkerSaga)),
        takeLatest(actions.GET_HIWAY_DOCUMENTS, getHiwayDocumentsSaga),
    ]);
};
